import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export const useTimer = (targetTimeEpoch) => {
  const [timeLeft, setTimeLeft] = useState(null); // Time left in milliseconds
  const currentTimeStamp = useSelector((state) => state.lobby.currentTimeStamp); // 13-digit timestamp from Redux store
  const happyHoursConfiguredTime = useSelector(
    (state) => state.lobby.happyHoursConfiguredTime
  ); // Configured time in minutes
  const timerInterval = useRef(null);

  useEffect(() => {
    if (currentTimeStamp) {
      clearInterval(timerInterval.current);
      const remainingTime = targetTimeEpoch - currentTimeStamp;
      setTimeLeft(remainingTime > 0 ? remainingTime : 0); // Ensure non-negative time
    }
  }, [currentTimeStamp, targetTimeEpoch]);

  useEffect(() => {
    if (timeLeft <= 0) {
      setTimeLeft(null); // Timer is over
      return;
    }

    timerInterval.current = setInterval(() => {
      setTimeLeft((prev) => {
        const newTimeLeft = prev - 1000; // Decrease by 1000ms (1 second)
        if (newTimeLeft <= 0) {
          clearInterval(timerInterval.current);
          return null; // Set to null when timer is over
        }
        return newTimeLeft;
      });
    }, 1000);

    return () => clearInterval(timerInterval.current); // Clean up interval on unmount
  }, [timeLeft]);

  if (timeLeft === null || targetTimeEpoch === null) return null;

  if (
    happyHoursConfiguredTime &&
    happyHoursConfiguredTime * 60 * 1000 > timeLeft
  ) {
    return formatTime(timeLeft);
  }
};

// Helper function to format milliseconds into HH:MM:SS
const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((totalSeconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (totalSeconds % 60).toString().padStart(2, "0");
  return hours !== "00"
    ? `${hours}:${minutes}:${seconds}`
    : `${minutes}:${seconds}`;
};
