import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  TabbarContainer,
  DefaultTabItemWrapper,
  DefaultTabItemText,
  InfiniteTabbarContainer,
  InfiniteTabbarItemWrapper,
  InfiniteScrollTabItemText,
  RedDotIcon,
} from "./styledComponents";
import { useSelector } from "react-redux";
import { messageToNativeClient } from "../../utils/platformCommunicationUtil";
import {
  ACTIVE_TOURNEYS,
  COMPLETED_TOURNEYS,
  MY_TOURNEYS,
  TOURNEYS_VARIANT_TAB_BAR,
} from "../../../Poker/data/Constants";
import { IP_TourneyTab_Click } from "../../../Poker/data/ClevertapConstants";
import CleverTapUtil from "../../utils/CleverTapUtil";

function Tabbar(props) {
  const { type, isInfiniteTabbar, tabItems, selectedTab ,isTourneySubTab} = props;
  const [activeTab, setActiveTab] = useState(selectedTab);
  const playerData = useSelector((state) => state.lobby.player);
  const channel = useSelector((state) => state.lobby.channel);
  const userBalance = useSelector((state) => state.lobby.balance);
  const swiperRef = useRef(null);

  useEffect(() => {
    //IM-2400 issue fix -- need to test it with deep links too (just in case @Yashwanth @Aishwarya);
    if (type === TOURNEYS_VARIANT_TAB_BAR && activeTab !== selectedTab) {
      for (let index = 0; index < tabItems.length; index++) {
        let eachTabItem = tabItems[index];
        if (eachTabItem.value === selectedTab) {
          handleTabChange(eachTabItem.value, index);
          break;
        }
      }
    }
  }, [selectedTab]);

  const handleTabChange = (value, index) => {
    if (
      tabItems.length > 0 &&
      tabItems.filter(
        (t) =>
          t.value === MY_TOURNEYS ||
          t.value === COMPLETED_TOURNEYS ||
          t.value === ACTIVE_TOURNEYS
      ).length > 0
    ) {
      let temp = new Date();
      let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
      timeStamp = timeStamp.toISOString();
      messageToNativeClient({
        type: "plotlineEvents",
        eventName: "PR_TourneyTab_Click",
        eventProperties: {
          userName: playerData.userName,
          channel,
          currentCashBalance: userBalance.totalBalance,
          timeStamp,
          tabType: value,
        },
      });

      CleverTapUtil.getInstance().logEvent(IP_TourneyTab_Click, {
        Username: playerData.userName,
        Channel: channel,
        "Wallet balance": userBalance?.totalBalance,
        Timestamp: timeStamp,
        "tab Type": value,
      });
    }
    setActiveTab(value);
    if (isInfiniteTabbar) {
      swiperRef?.current?.swiper?.slideTo(index);
    }
    props.onTabChange && props.onTabChange(value);
  };

  if (isInfiniteTabbar) {
    return (
      <InfiniteTabbarContainer className={"tab-item-container"}>
        <Swiper
          ref={swiperRef}
          spaceBetween={0}
          slidesPerView={props.slidesPerView ? props.slidesPerView : 3}
          simulateTouch={true}
          loop={false}
          initialSlide={0}
          className={isTourneySubTab ? "sub-tab-wrapper" : ""}
        >
          {tabItems.map((eachTabItem, index) => {
            const isActive = activeTab === eachTabItem.value;
            return (
              <SwiperSlide
                key={`infinite-tab-item-${index}-${eachTabItem.value}`}
              >
                <InfiniteTabbarItemWrapper
                  className={"tab-item"}
                  isActive={isActive}
                  onClick={() => handleTabChange(eachTabItem.value, index)}
                >
                  <InfiniteScrollTabItemText isActive={isActive}>
                    {eachTabItem.label}
                  </InfiniteScrollTabItemText>
                </InfiniteTabbarItemWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </InfiniteTabbarContainer>
    );
  } else {
    return (
      <TabbarContainer className={"tab-item-container"}>
        {tabItems.map((eachTabItem, index) => {
          const isActive = activeTab === eachTabItem.value;
          return (
            <DefaultTabItemWrapper
              className={"default-tab-item"}
              id={eachTabItem.value.toLowerCase()}
              key={eachTabItem.value}
              isActive={isActive}
              onTouchStart={() => handleTabChange(eachTabItem.value, index)}
            >
              <DefaultTabItemText isActive={isActive}>
                {eachTabItem.label}
              </DefaultTabItemText>
              {eachTabItem?.showRedDot && <RedDotIcon />}
            </DefaultTabItemWrapper>
          );
        })}
      </TabbarContainer>
    );
  }
}

export default Tabbar;
