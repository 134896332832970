import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";

import GameConnections from "../../io/GameConnections";
import {
  CALL_ACTION,
  CHECK_ACTION,
  CHECK_GAME_API,
  CHECK_GAME_API_READY_TO_REQUEST,
  CHECK_GAME_API_RESPONSE_SUCCESS,
  EXIT_TABLE_ACK_SUCCESS,
  GAME_JOIN_API_READY_TO_REQUEST,
  FOLD_ACTION,
  GAME_STAGE_FLOP,
  GAME_STAGE_PRE_FLOP,
  GAME_STAGE_RIVER,
  GAME_STAGE_TURN,
  MATCH_MAKING_API,
  MATCH_MAKING_API_READY_TO_REQUEST,
  MATCH_MAKING_API_REQUEST_REJECTED,
  RAISE_ACTION,
  GAME_JOIN_API_RESPONSE_FAILURE,
  SEATED_OUT,
  GAME_STAGE_SHOWDOWN,
  FOLD_SOUND,
  CHECK_SOUND,
  CALL_SOUND,
  RAISE_SOUND,
  TURN_SOUND,
  WINNER_SOUND,
  BUTTON_SOUND,
  VIBRATE_LIGHT,
  VIBRATE_HEAVY,
  ACTIVE,
  WAITING_FOR_BB,
  PLAYER_DEFAULT_TIMER,
  PLAYER_EXTRA_TIMER,
  IS_SOUND_ENABLED,
  IS_HAPTICS_EBABLED,
  REBUY_INSUFFICIENT_FUNDS,
  REBUY_OTHER,
  ALL_IN,
  GAME_CONNECTION_PROTOCOL_SUCCESS,
  MATCH_MAKING_API_RESPONSE_SUCCESS,
  CHECK_GAME_API_REQUEST_REJECTED,
  GAME_SOCKET_TIMEOUT,
  RESPONSE_KEY_NAME,
  REJECTED_KEY_NAME,
  RESPONSE_CODE_KEY_NAME,
  IN_ACTIVE,
  AUTO_CALL_ACTION,
  AUTO_CALL_ANY_ACTION,
  AUTO_CHECK_ACTION,
  AUTO_CHECK_OR_FOLD_ACTION,
  AUTO_FOLD_ACTION,
  INITIATE_LACK_GAME_CONNECTION,
  TOAST_WARNING,
  TOAST_SUCCESS,
  TOAST_ERROR,
  TOAST_INFO,
  TEXAS_COMMUNITY_CARDS_NUMBER,
  PORTRAIT,
  LANDSCAPE,
  DISCONNECTION,
  INITIATE_TOURNEY_GAME_CONNECTION,
  INITIATE_TOURNEY_GAME_RECONNECTION,
  TOURNEY_GAME_RECONNECTION_STATE,
  TOURNEY_GAME_CONNECTION_STATE,
  LEAVE_SEAT_ACK_PROCESSING,
  LEAVE_SEAT_ACK_SUCCESS,
  LEAVE_SEAT_ACK_REQUEST_ALREADY_SENT,
  OMAHA,
  OMAHA5,
  BUY_IN,
  MAX_CARD_WIDTH,
  INSUFFICIENT_BALANCE,
  TOURNEY_BANNER_BLINDS_UP,
  TOURNEY_BANNER_BREAK_TIME,
  TOURNEY_BANNER_BREAK_WAITING_FOR_OTHER_TABLES,
  GAME_LOOSER_MODAL,
  TOURNEY_GAME_WINNER_MODAL,
  DISCONNECTION_TIMER,
  PLAYER_STATS,
  LB_PLAY,
  INSUFFICIENT_FUN_CHIPS_BALANCE,
  TOURNEY_REBUYIN_WITH_DOUBLE_REBUY,
  TOURNEY_BANNER_WAITING_FOR_REBUYS,
  CARD_SIZE_MEDIUM,
  AUTO_CHECK,
  AUTO_FOLD,
  MATCH_MAKING_BET_DISABLED,
  TOURNEY_ADDON,
  LB_REAL,
  TOURNEY_BANNER_MERGED_WITH_ANOTHER_TABLE,
  TOURNEY_BANNER_NODE_SWITCH,
  TOURNEY_BANNER_IN_WINNING_POSITIONS,
  DELETED_PLAYER,
  TO_BE_DELETED_PLAYER,
  DELETED_PLAYER_LOGOUT,
  ACCOUNT_ISSUE_GAME_JOIN,
  WALLET_BALANCE_ISSUE_GAME_JOIN,
  DATA_LOST_ISSUE_GAME_JOIN,
  RESTRICTED_LOCATION_GAME_EXIT,
  TOURNEY_BANNER_MERGE_WAIT_MESSAGE,
  TOURNEY_TABLE_MERGE_BANNER_DELAY,
  TOURNEY_BANNER_ADDON_BREAK_WAITING_FOR_OTHER_TABLES,
  DEAL_SOUND,
  JOIN_FAILURE_ACCOUNT_DELETED,
  RELOGIN_FAILURE,
} from "../../data/Constants";
import MessageToServer from "../services/MessageToServerCalls";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  doesExist,
  getHandType,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import GameAudio from "../../audio/GameAudio";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../../../Common/utils/StorageUtils";
import { getCardWidthAndHeight } from "../../../Common/utils/ResponsiveStyle";
import GameListenerN from "../../io/GameListenerN";
import GameConnectionsN from "../../io/GameConnectionsN";
import TableUtils from "../../utils/TableUtils";
import {
  getMLTLogFormat,
  sendCancelSitOutProtocol,
  sendNextGameProtocol,
  sendRefreshProtocol,
  sendSitInProtocol,
  sendSitOutProtocol,
} from "../../utils/ProtocolUtils";
import { sendFaroLog } from "../../../Common/utils/FaroUtil";
import TimerObserver from "../../../Common/utils/TablesObserver";
import LobbyConnection from "../../io/LobbyConnection";
import { returnGameTypeText } from "../../../Common/utils/PokerUtils";

export const fetchMatchMakingDetails = createAsyncThunk(
  "/gameTableSlice/matchMakingDetails",
  async (requestDetails, { rejectWithValue }) => {
    console.log("In Fetch Making call");
    console.log("match making API URL: ", requestDetails.apiMatchMakingURL);
    console.log("Data object: ", requestDetails.dataObject);
    console.log(requestDetails);
    sendFaroLog({
      requestType: "MATCH_MAKING_INITIATE",
      requestPayload: requestDetails,
    });
    try {
      let response = await axios.post(
        requestDetails.apiMatchMakingURL,
        requestDetails.dataObject
      );
      response.tempTableId = requestDetails.tempTableId;
      return response;
    } catch (error) {
      return rejectWithValue(requestDetails.tempTableId, error);
    }
  }
);

export const fetchCheckGameDetails = createAsyncThunk(
  "/gameTableSlice/checkGameDetails",
  async (requestDetails, { rejectWithValue }) => {
    console.log("match making API URL: ", requestDetails.apiMatchMakingURL);
    console.log("Data object: ", requestDetails.dataObject);
    sendFaroLog({
      requestType: "CHECK_GAME_INITIATE",
      requestPayload: requestDetails,
    });
    try {
      let response = await axios.post(
        requestDetails.apiCheckGameURL,
        requestDetails.dataObject
      );
      response.tempTableId = requestDetails.tempTableId;
      console.log("Response from check game server: ", response);
      return response;
    } catch (error) {
      return rejectWithValue(requestDetails.tempTableId, error);
    }
  }
);

function checkIfIsPlayerTurnOnAnyTable(state) {
  // Below is the code to flag if the user is active on ANY table. This flag can be used in lobby to know if he is active on ATLEAST 1 table.
  let tempTableIds = Object.keys(state.games);
  for (let i = 0; i < tempTableIds.length; i++) {
    let tempTableId = tempTableIds[i];
    if (
      state.games[tempTableId].playerTurn ===
      state.games[tempTableId].playerSeat
    ) {
      state.isPlayerTurnOnAnyTable = true;
      break;
    } else {
      state.isPlayerTurnOnAnyTable = false;
    }
  }
}

function processCreateTableData(state, action) {
  const {
    tempTableId,
    gameDefinition,
    lackGameId,
    lackTableId,
    awsalbcookie,
    gameEndpoint,
    isTourneyGame,
    isTourneyLackGame,
    watchPlayer,
  } = action.payload;
  console.log("Game Definition: ", gameDefinition);
  console.log("Type of max players: ", typeof gameDefinition.maxPlayers);

  let isGameAlreadyExistWithId = false;

  //temporary check
  Object.keys(state.games).forEach((eachTempTableId) => {
    if (!isGameAlreadyExistWithId) {
      let loopTableId = state.games[eachTempTableId].tableId;
      if (isTourneyGame) {
        isGameAlreadyExistWithId =
          state.games[eachTempTableId].gameDefinition.tourneyId ===
            gameDefinition.tourneyId && loopTableId === lackTableId;
      } else if (loopTableId && loopTableId === lackTableId) {
        console.log("TABLE ID MATCHED: ", loopTableId, lackTableId);
        isGameAlreadyExistWithId = true;
      }
    }
  });

  console.log(
    "isGameAlreadyExistWithId ",
    isGameAlreadyExistWithId,
    "temp table id: ",
    tempTableId
  );

  if (!isGameAlreadyExistWithId) {
    let newGameTable = {
      gameId: lackGameId,
      tableId: lackTableId ? lackTableId : null,
      tempTableId: tempTableId,
      gameDefinition: gameDefinition,
      //below should be little fine tuned with respect to tourney @yashwanth
      gameAPIState: isTourneyGame
        ? TOURNEY_GAME_CONNECTION_STATE
        : lackGameId
        ? CHECK_GAME_API
        : MATCH_MAKING_API,
      gameConnectionStatus: isTourneyGame
        ? INITIATE_TOURNEY_GAME_CONNECTION
        : lackGameId
        ? INITIATE_LACK_GAME_CONNECTION
        : MATCH_MAKING_API_READY_TO_REQUEST,
      apiMatchMakingURL: process.env.REACT_APP_MATCH_MAKING_API_URL,
      apiMatchMakingTimeLogsArray: [],
      apiCheckGameURL: process.env.REACT_APP_CHECK_GAME_API_URL,
      apiCheckGameTimeLogsArray: [],
      gameEndpoint: gameEndpoint ? gameEndpoint : undefined,
      isConnectionAckReceivedOnce: false,
      isLackGame: isTourneyGame ? isTourneyLackGame : lackGameId ? true : false,
      awsalbcookie: lackTableId ? awsalbcookie : "",
      isDisconnected: false,
      isReconnected: false,
      totalPlayersConfig: isTourneyGame
        ? gameDefinition.playersPerTable
        : gameDefinition.maxPlayers,
      watchPlayer: watchPlayer,
      playerCards: [],
      gameStage: GAME_STAGE_PRE_FLOP,
      communityCards: [],
      multiPotAmounts: [],
      tableNumber: 0,
      playerData: [],
      playerMovedToCenter: false,
      setBuyInValueForPlayerPosition: undefined,
      playerSeat: undefined,
      playerStatus: undefined,
      playerTurn: undefined,
      autoAction: undefined,
      sitOutOption: false,
      playerSitOutTimer: false,
      openBuyInModal: false,
      openRebuyInModal: false,
      openInSufficientFundsModal: false,
      openTopupModal: false,
      buyInTimeInSeconds: 0,
      playerTimer: -1,
      gameTableDimensions: {},
      positionsArranged: false,
      playerPositions: [],
      totalPotAmt: undefined,
      close: false,
      toastMessages: [],
      showAutoActions: false,
      showDefaultActions: false,
      disableDefaultActions: false,
      disableAutoActions: false,
      minRaiseAmount: 0,
      maxRaiseAmount: 0,
      autoCall: false,
      autoCallAny: false,
      autoCheckOrFold: false,
      autoCheck: false,
      fold: false,
      communityCardPositon: [],
      restrictUserFromLeavingTable: false,
      restrictUserFromSitOut: false,
      clearCommunityCardsOnTable: false,
      isWaitingForBigBlind: false,
      joinFailureFlag: false,
      topUpPending: false,
      startWinnerPotAnimation: false,
      showReleaseToFoldAlert: false,
      processingDReserve: false,
      processingReserve: { status: false, position: undefined },
      tableStats: [],
      showRabbitHuntingCards: false,
      showStartGameTimer: false,
      doCommunityCardsExist: false,
      sitOutButtonDisabled: false,
      disableOrientationChange: false,
      revealCards: false,
      playerSeatBeforeLeaveSeat: undefined,

      gameModal: {
        type: null,
        config: null,
        visible: false,
      },
      isTourneyGame: isTourneyGame ? isTourneyGame : false,
      tourneyAutoPlay: false,
      isTourneyNodeSwitch: false,
      isFunGame: isTourneyGame
        ? false
        : gameDefinition?.flavourType?.toLowerCase() === LB_PLAY,
      tourneyBlindsUpDetails: null,
      tourneyGameBreakBroadcast: null,
      tourneyNextLevelUpDetails: null,
      activeTourneyBannerType: null,
      tourneyPrizeDetails: null,
      tourneyRebuyInDetails: null,
      isTourneyRebuyRequestLoading: false,
      tourneyAddOnDetails: null,
      isTourneyAddOnRequestInProgress: false,
      isTourneyRankingLeaderboardRequestInProgress: true,
      tourneyRankingLeaderboardDetails: null,
      tourneyPlayerRankDetails: null,
      tourneyGameCompleted: false,
      showHappyHoursToast: false,
      antiBanking: {
        antiBankAmount: 0,
        isAntiBank: false,
      },
    };
    sendFaroLog({
      type: "CREATING_A_NEW_GAME_TABLE",
      tempTableId: tempTableId,
      gameData: newGameTable,
      receivedPayloadToCreateGame: action.payload,
    });

    state.games[tempTableId] = newGameTable;
    state.activeGame = tempTableId;
    state.gamesAvailable = true;
    state.showGameTable = true;
  } else if (isGameAlreadyExistWithId) {
    sendFaroLog({
      type: "FAILED_AT_CREATING_A_NEW_GAME_TABLE",
      tempTableId: tempTableId,
      receivedPayloadToCreateGame: action.payload,
      activeGameTempTableId: state.activeGame,
    });
  }
}

function processResetTableData(state, action) {
  const { tempTableId, isTourneyGame } = action.payload;
  let gameEndpoint = action.payload.gameEndpoint;
  let tableData = state.games[tempTableId];

  if (tableData) {
    if (isTourneyGame) {
      gameEndpoint = tableData.gameEndpoint;
    }
    tableData = {
      ...tableData,
      isReset: true,
      gameId: null,
      gameAPIState: isTourneyGame
        ? TOURNEY_GAME_CONNECTION_STATE
        : MATCH_MAKING_API,
      gameConnectionStatus: isTourneyGame
        ? INITIATE_TOURNEY_GAME_CONNECTION
        : MATCH_MAKING_API_READY_TO_REQUEST,
      gameEndpoint: gameEndpoint ? gameEndpoint : undefined,
      isConnectionAckReceivedOnce: false,
      isLackGame: false,
      awsalbcookie: "",
      isDisconnected: false,
      isReconnected: false,
      watchPlayer: true,
      gameModal: {
        type: null,
        config: null,
        visible: false,
      },
    };
  } else {
    showNoTableAvailableWarning(action);
  }
}

function showNoTableAvailableWarning(action) {
  //write a log to the lobby socket
  const logPayload = {
    type: "table not available",
    actionData: action,
  };

  let debugProtocol = `DB#${JSON.stringify(logPayload)}`;
  LobbyConnection.getInstance().writeMessage(debugProtocol);

  sendFaroLog(
    getMLTLogFormat({
      type: "table not available",
      stage: "showNoTableAvailableWarning",
      logLocation: "Game",
      tempTableId: action.payload.tempTableId,
      comments: action,
    })
  );
}

function processCloseGameWindow(state, action) {
  const { tempTableId, removeTable } = action.payload;
  let tableData = state.games[tempTableId];
  if (tableData) {
    if (removeTable) {
      delete state.games[tempTableId];
    }
  } else {
    showNoTableAvailableWarning(action);
  }
}

function processCleanTable(
  state,
  tempTableId,
  cleanGameId = true,
  tourneyGameAndCheckExitTable = false
) {
  let tableData = state.games[tempTableId];
  if (tableData) {
    if (cleanGameId) {
      tableData.gameId = "";
    }
    tableData.playerCards = [];
    tableData.gameStage = "";
    tableData.communityCards = [];
    tableData.multiPotAmounts = [];
    tableData.totalPotAmt = undefined;
    tableData.isWinnerDeclared = false;
    tableData.winningPotDisplay = undefined;
    tableData.setBuyInValueForPlayerPosition = tableData.playerWaitingPosition
      ? tableData.setBuyInValueForPlayerPosition
      : undefined;
    tableData.showFold = false;
    tableData.showCall = false;
    tableData.showAllIn = false;
    tableData.showCheck = false;
    tableData.showRaise = false;
    tableData.autoCheckOrFold = false;
    tableData.autoCall = false;
    tableData.fold = false;
    tableData.autoCheck = false;
    tableData.isTableCleaned = true;
    tableData.playerTurn = undefined;
    tableData.autoAction = undefined;
    tableData.playerTimer = -1;
    tableData.showAutoActions = false;
    tableData.showStartGameTimer = false;
    tableData.doCommunityCardsExist = false;
    tableData.startGameTimerValue = 0;
    tableData.showDefaultActions = false;
    tableData.disableDefaultActions = false;
    tableData.disableAutoActions = false;
    tableData.autoCall = false;
    tableData.autoCallAny = false;
    tableData.autoCheckOrFold = false;
    tableData.autoCheck = false;
    tableData.fold = false;
    tableData.shouldPlayCardAnimation = false;
    tableData.isGameStarted = false;
    tableData.winningHand = [];
    tableData.rankDetails = undefined;
    tableData.potAmounts = [];
    tableData.handType = "";
    tableData.restrictUserFromLeavingTable = false;
    tableData.showReleaseToFoldAlert = false;
    if (!tableData.openInSufficientFundsModal) {
      //NOTE: If we have autoRebuyinf scenatio we will receive NGC , but we still need to open insufficient modal so for that we added this change
      tableData.openInSufficientFundsModal = false;
    }
    tableData.openTopupModal = false;
    // if the waitlist player is joining the table , then buy-in modal should not be closed
    // tableData.openBuyInModal = tableData.playerWaitingPosition ? true : false;
    tableData.openBuyInModal = tableData.openBuyInModal
      ? tableData.openBuyInModal
      : false;
    tableData.disableOrientationChange = false;
    tableData.revealCards = false;
    tableData.numberOfSidePots = 0;
    tableData.totalPotAmt = undefined;
    tableData.topUpPending = false;

    let updatedPlayer = tableData.playerData.map((player) => {
      player.winningHand = undefined;
      player.handType = undefined;
      player.cards = undefined;
      player.allIn = false;
      player.winningPotAmount = undefined;
      player.revealCard = false;
      player.showBlindIcon = true;
      player.handType = undefined;
      player.lastAction = undefined;
      player.winningAmount = undefined;
      player.winningPotAmount = [];
      player.muckCards = false;
      return player;
    });

    tableData.playerData = updatedPlayer;

    if (tourneyGameAndCheckExitTable) {
      tableData.restrictUserFromLeavingTable = tableData.watchPlayer
        ? false
        : true;
    }
  }
}

const gameTableSlice = createSlice({
  name: "gameTable",
  initialState: {
    playerAvatar : null,
    activeGame: null,
    applicationVisibility: true,
    showGameTable: false,
    tableBlur: false,
    canUserSwipe: true,
    swipeDirection: undefined,
    tableOrientation: "PORTRAIT",
    shouldTableSwitchAfterTurn: false,
    playerComponentSize: 55,
    isPlayerTurnOnAnyTable: false,
    tableSettings: {
      showHandStrength: true,
      autoTableSwitch: false,
      autoMuck: true,
      smartGestures: true,
      BBDisplay: false,
      smartGestureOptions: {
        smartFold: true,
        smartCheck: true,
      },
      autoRebuy: false,
      autoTopupConfig: {
        enabled: false,
        fallsBelowAmount: 0,
        topUpAmount: 0,
        fallBelowMinAmount: 0,
        fallsBelowMaxAmount: 100,
        topUpMinAmount: 0,
        topUpMaxAmount: 100,
      },
      bettingOptions: {
        defaultPreflopPresetValue: 3,
        defaultPostflopPresetValue: 2,
        preflopPresetValues: {
          0: 2,
          1: 2.5,
          2: 3,
          3: 4,
        },
        postflopPresetValues: {
          0: 0.25,
          1: 0.5,
          2: 1,
          3: 2,
        },
      },
      sound:
        getFromLocalStorage(IS_SOUND_ENABLED) === null
          ? true
          : getFromLocalStorage(IS_SOUND_ENABLED),
      haptics:
        getFromLocalStorage(IS_HAPTICS_EBABLED) === null
          ? true
          : getFromLocalStorage(IS_HAPTICS_EBABLED),
    },
    themeData: {
      table: localStorage.getItem("tableTheme")
        ? localStorage.getItem("tableTheme")
        : "Table1",
      background: localStorage.getItem("tableBackground")
        ? localStorage.getItem("tableBackground")
        : "BG1",
      cardDeck: localStorage.getItem("cardDeck")
        ? localStorage.getItem("cardDeck")
        : "Default",
      cardBack: localStorage.getItem("cardBack")
        ? localStorage.getItem("cardBack")
        : "CB1",
      cardSize: localStorage.getItem("cardSize")
        ? localStorage.getItem("cardSize")
        : CARD_SIZE_MEDIUM,
    },
    lobbyMultiGameTableDrawer: false,
    gameTableData: {
      tables: [],
      activeTempTableId: "",
    },
    gameJoinCheckObject: null,
    games: {},
    sequentialConnectionArray: [],
    userDetails: {
      userName: "",
      subscriptionType: "",
      state: "",
      level: "",
      isAllowLocation: "",
      avatar: "",
      IP: "",
      deviceId: "",
      latitude: "",
      longitude: "",
      userId: "",
      deepLink: {
        isAllowLocation: "true",
      },
    },
    handHistory: {
      activeHandIndex: undefined,
      details: {},
    },
    handHistoryLoading: true,
    happyHoursInfoLoading: true,
    happyHoursInfoFailure: false,
    showHappyHoursButton: false,
    happyHourSavings: null,
    happyHoursInfo: {
      playerTotalSavings: null,
      playerTableSavings: null,
      totalTableSavings: null,
    },
    autoTableSwitchQueue: [],
    visibleGameTables: [],
  },

  reducers: {
    requestReserveSeat: (state, action) => {
      const { tempTableId, position } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].processingReserve = {
          status: true,
          position: position,
        };
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleReserveSeatBroadcast: (state, action) => {
      const { playerName, position, tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let reservePlayerData = {
          position: position,
          userName: playerName,
          avatar: "",
          userId: "",
          atTableAmount: 0,
          acelevel: "",
          isReservedSeat: true,
          isTakenSeat: false,
          isFolded: false,
          isActive: true,
          isWinner: false,
          allIn: false,
        };

        state.games[tempTableId].playerData.push(reservePlayerData);
        state.games[tempTableId].processingReserve = {
          status: false,
          position: position,
        };
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRSeatBroadcast: (state, action) => {
      const { playerList, tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let reservePlayersData = [];
        playerList.forEach((player) => {
          let reservePlayerData = {
            position: player.position,
            userName: player.playerName,
            avatar: "",
            userId: "",
            atTableAmount: 0,
            acelevel: "",
            isReservedSeat: true,
            isTakenSeat: false,
            isFolded: false,
            isActive: true,
            isWinner: false,
            allIn: false,
          };
          reservePlayersData.push(reservePlayerData);
        });

        state.games[tempTableId].playerData = [
          ...state.games[tempTableId].playerData,
          ...reservePlayersData,
        ];
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRemoveReserveSeatBroadcast: (state, action) => {
      const { position, tempTableId, playerName } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].playerData = state.games[
          tempTableId
        ].playerData.filter(
          (eachPlayer) =>
            eachPlayer.position !== position &&
            eachPlayer.userName !== playerName
        );
        if (state.games[tempTableId].processingReserve.position === position) {
          state.games[tempTableId].processingDReserve = false;
          state.games[tempTableId].processingReserve = {
            status: false,
            position: undefined,
          };
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateDisableGameActions: (state, action) => {
      const { tempTableId, disabled } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].showDefaultActions = false;
        state.games[tempTableId].disableAutoActions = disabled;
        state.games[tempTableId].disableDefaultActions = disabled;
        let playerData = state.games[tempTableId].playerData.find(
          (player) => player.position === state.games[tempTableId]?.playerSeat
        );
        if (playerData) {
          playerData.tableTimer = 0;
          playerData.startTableTimer = false;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    requestRemoveReserveSeat: (state, action) => {
      let { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (state.games[tempTableId].openBuyInModal) {
          // We can use activeGame here since there is no chance of activeGame changes while buyinModal is open
          let position =
            state.games[tempTableId].setBuyInValueForPlayerPosition;
          state.games[tempTableId].processingDReserve = true;

          let gameConnections = GameConnections.getInstance();
          let activeGameConnection =
            gameConnections.getGameConnection(tempTableId);

          if (activeGameConnection && activeGameConnection.messageHandler) {
            let activeGameTable = state.games[tempTableId];
            if (activeGameTable) {
              activeGameConnection.gameClientListener.requestRemoveReserveSeat({
                playerName: state.userDetails.userName,
                position: `${position}`,
                tableId: state.games[tempTableId].tableId,
              });
            }
          }
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    setRemoveReserveSeatForPlayer: (state, action) => {
      const { tempTableId, position, playerName } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].openBuyInModal = false;
        state.games[tempTableId].setBuyInValueForPlayerPosition = null;
        state.games[tempTableId].playerData = state.games[
          tempTableId
        ].playerData.filter(
          (eachPlayer) =>
            eachPlayer.position !== position &&
            eachPlayer.userName !== playerName
        );
        state.games[tempTableId].processingDReserve = false;
        state.games[tempTableId].watchPlayer = true;
        state.games[tempTableId].processingReserve = {
          status: false,
          position: undefined,
        };
        state.games[tempTableId].antiBanking.antiBankAmount = 0;
        state.games[tempTableId].antiBanking.isAntiBank = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    setPreflopPresetValues: (state, action) => {
      state.tableSettings.bettingOptions.preflopPresetValues = action.payload;
    },

    setPostflopPresetValues: (state, action) => {
      state.tableSettings.bettingOptions.postflopPresetValues = action.payload;
    },

    setDefaultPreflopPresetValue: (state, action) => {
      state.tableSettings.bettingOptions.defaultPreflopPresetValue =
        action.payload;
    },

    setDefaultPostflopPresetValue: (state, action) => {
      state.tableSettings.bettingOptions.defaultPostflopPresetValue =
        action.payload;
    },

    processReserveFailure: (state, action) => {
      const { tempTableId, position } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].processingReserve = {
          status: false,
          position: position,
        };
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    // set the position of the player who clicked on sit here and buys in
    setBuyInValueForPlayerPosition: (state, action) => {
      const { tempTableId, endTime, position, playerName } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let antiBankAmount = action?.payload?.antiBankAmount;
        let isAntiBank = action?.payload?.isAntiBank;

        state.games[tempTableId].setBuyInValueForPlayerPosition = position;
        state.games[tempTableId].openBuyInModal = true;
        state.games[tempTableId].openRebuyInModal = false;
        state.games[tempTableId].openTopupModal = false;
        state.games[tempTableId].buyInTimeInSeconds = endTime;
        let reservePlayerData = {
          position: position,
          avatar: "",
          userId: "",
          atTableAmount: 0,
          acelevel: "",
          isReservedSeat: true,
          isTakenSeat: false,
          isFolded: false,
          isActive: true,
          isWinner: false,
          allIn: false,
        };
        state.games[tempTableId].antiBanking.antiBankAmount = antiBankAmount;
        state.games[tempTableId].antiBanking.isAntiBank = isAntiBank;
        state.games[tempTableId].processingDReserve = false;
        state.games[tempTableId].processingReserve = {
          status: true,
          position: position,
        };
        state.games[tempTableId].playerData.push(reservePlayerData);
        state.activeGame = tempTableId;
        console.log("buy in state after reserve ack ", state);
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleGameJoinRequest: (state, action) => {
      const { tempTableId, userDetails } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        const { userName, avatar, userId, level } = state.userDetails;
        let newPlayerData = {
          position: userDetails.position,
          userName: userName,
          avatar: avatar,
          userId: userId,
          atTableAmount: userDetails.atTableAmount,
          acelevel: level,
          isReservedSeat: false,
          isTakenSeat: true,
          isFolded: false,
          isActive: state.games[tempTableId].isGameStarted
            ? userDetails.isActivePlayer
            : true,
          isWinner: false,
          allIn: false,
          sitOutOption: false,
          // Since the join is successful, we will update reserved as false.
          isWaitingForBigBlind: userDetails.isWaitingForBigBlind,
        };
        state.games[tempTableId].openBuyInModal = false;
        let activePlayers = state.games[tempTableId].playerData.filter(
          (player) => player.position !== userDetails.position
        );
        activePlayers.push(newPlayerData);

        state.games[tempTableId].playerData = activePlayers;
        state.games[tempTableId].sitOutOption = false;
        state.games[tempTableId].playerSeat = userDetails?.position;
        state.games[tempTableId].playerSeatBeforeLeaveSeat =
          userDetails?.position;
        state.games[tempTableId].setBuyInValueForPlayerPosition = undefined;

        state.games[tempTableId].processingReserve = {
          status: false,
          position: userDetails.position,
        };

        state.games[tempTableId].playerWaitingPosition = undefined;
        state.games[tempTableId].totalWaitListPlayerCount = undefined;

        if (state.games[tempTableId].gameModal.type === BUY_IN) {
          state.games[tempTableId].gameModal.type = null;
          state.games[tempTableId].gameModal.visible = false;
        }
        state.games[tempTableId].antiBanking.antiBankAmount = 0;
        state.games[tempTableId].antiBanking.isAntiBank = false;

        if (state.tableSettings.haptics) {
          GameAudio.getInstance().playVibration(VIBRATE_LIGHT);
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handlePlayerJoinBroadcast: (state, action) => {
      const { tempTableId, userDetails } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        const {
          position,
          playerName,
          atTableAmount,
          acelevel,
          profilePic,
          isActivePlayer,
          isWaitingForBigBlind,
        } = userDetails;

        let newPlayerData = {
          position: position,
          userName: playerName,
          atTableAmount: atTableAmount,
          acelevel: acelevel,
          avatar: profilePic,
          isReservedSeat: false,
          isTakenSeat: true,
          isFolded: false,
          isActive: state.games[tempTableId].isGameStarted
            ? isActivePlayer
            : true,
          isWinner: false,
          allIn: false,
          isWaitingForBigBlind: isWaitingForBigBlind,
        };

        let currentPosition = state.games[tempTableId].playerData.find(
          (eachPlayer) => eachPlayer.position === position
        );
        if (currentPosition) {
          let updatedPlayerData = state.games[tempTableId].playerData.map(
            (eachPlayer) => {
              if (eachPlayer.position === position) {
                return newPlayerData;
              } else {
                return eachPlayer;
              }
            }
          );
          state.games[tempTableId].playerData = updatedPlayerData;
        } else {
          state.games[tempTableId].playerData.push(newPlayerData);
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handlePlayerAutoActions: (state, action) => {
      const { tempTableId, autoActions } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        const {
          callAny,
          call,
          callAmount,
          checkFold,
          check,
          fold,
          isCallAnySelected,
          isCallSelected,
          isCheckFoldSelected,
          isCheckSelected,
          isFoldSelected,
        } = autoActions;

        let autoAction = "";
        if (isCallAnySelected) {
          autoAction = AUTO_CALL_ANY_ACTION;
        } else if (isCallSelected) {
          autoAction = AUTO_CALL_ACTION;
        } else if (isCheckFoldSelected) {
          autoAction = AUTO_CHECK_OR_FOLD_ACTION;
        } else if (isCheckSelected) {
          autoAction = AUTO_CHECK_ACTION;
        } else if (isFoldSelected) {
          autoAction = AUTO_FOLD_ACTION;
        }
        state.games[tempTableId].autoAction = autoAction;
        state.games[tempTableId].showAutoActions = true;
        state.games[tempTableId].showDefaultActions = false;
        state.games[tempTableId].disableAutoActions = false;
        state.games[tempTableId].disableDefaultActions = false;
        state.games[tempTableId].autoCall = call;
        state.games[tempTableId].autoCallAny = callAny;
        state.games[tempTableId].callAmount = callAmount;
        state.games[tempTableId].autoCheckOrFold = checkFold;
        state.games[tempTableId].autoCheck = check;
        state.games[tempTableId].fold = fold;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateUserAutoActionPreference: (state, action) => {
      const { autoUserAction, tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].autoAction = autoUserAction;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processPlayerConfigAck: (state, action) => {
      const { tempTableId, statusCode, message } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (statusCode === 400) {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_ERROR,
            message: message,
          });
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateAutoTableSwitchQueue: (state, action) => {
      const { tempTableId } = action.payload;
      let autoTableSwitchingQueue = state.autoTableSwitchQueue;
      // Removing the game user acted on from autoTableSwitchingQueue
      autoTableSwitchingQueue = autoTableSwitchingQueue.filter(
        (id) => id !== tempTableId
      );
      state.autoTableSwitchQueue = autoTableSwitchingQueue;
      // If the length of the queue is greater than 0, then move the user to the next table in queue immediately
      if (
        autoTableSwitchingQueue.length > 0 &&
        state.tableSettings.autoTableSwitch
      ) {
        if (autoTableSwitchingQueue[0] > state.activeGame) {
          state.swipeDirection = "left";
        } else {
          state.swipeDirection = "right";
        }
        if (state.games[state.autoTableSwitchQueue[0]]) {
          state.activeGame = state.autoTableSwitchQueue[0];
        } else {
          console.log(
            "Auto Table Switch Failure - Setting active game to >>> ",
            state.autoTableSwitchQueue[0]
          );
          sendFaroLog({
            type: "autoTableSwitchFailure",
            state,
          });
        }
      }
    },

    handleTurnChange: (state, action) => {
      const { tempTableId, turnDetails } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        const {
          position,
          fold,
          check,
          call,
          raise,
          allInForCall,
          callAmount,
          time,
          maxTime,
          totalTime,
          currentRaiseAmount,
          minimumRaiseAmount,
        } = turnDetails;

        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            if (eachPlayer.position === position) {
              eachPlayer.tableTimer = time;
              eachPlayer.totalTime = totalTime;
              eachPlayer.maxTurnTimer = maxTime;
              eachPlayer.startTurnTimer = true;
              eachPlayer.startTableTimer = true;
              eachPlayer.allInForCall = allInForCall;
              eachPlayer.currentRaiseAmount =
                parseDecimalValuesOfNumber(currentRaiseAmount);
              eachPlayer.activeTableTimerType = PLAYER_DEFAULT_TIMER;
              return eachPlayer;
            } else {
              eachPlayer.startTurnTimer = false;
              eachPlayer.startTableTimer = false;
              return eachPlayer;
            }
          }
        );

        const currentUserData = state.games[tempTableId].playerData.find(
          (player) => player.position === state.games[tempTableId]?.playerSeat
        );

        state.games[tempTableId].playerData = updatedPlayerDetails;
        state.games[tempTableId].showAutoActions = false;
        state.games[tempTableId].showDefaultActions = true;
        state.games[tempTableId].disableAutoActions = false;
        state.games[tempTableId].disableDefaultActions = false;
        state.games[tempTableId].showCall = call;
        state.games[tempTableId].showFold = fold;
        state.games[tempTableId].showCheck = check;
        state.games[tempTableId].showRaise = raise;
        state.games[tempTableId].showAllIn = allInForCall;
        state.games[tempTableId].callAmount = callAmount;
        state.games[tempTableId].playerTurn = position;
        state.games[tempTableId].autoAction = "";
        state.games[tempTableId].minRaiseAmount =
          parseDecimalValuesOfNumber(minimumRaiseAmount);

        if (
          state.games[tempTableId]?.gameDefinition?.gameType === OMAHA ||
          state.games[tempTableId]?.gameDefinition?.gameType === OMAHA5
        ) {
          state.games[tempTableId].maxRaiseAmount =
            turnDetails.maximumRaiseAmount;
        } else {
          state.games[tempTableId].maxRaiseAmount = parseDecimalValuesOfNumber(
            currentUserData?.atTableAmount + currentUserData?.betAmount
          );
        }

        // Playing the sound iff user has to act in any way, not playing it for auto actions triggered by server
        if (call || fold || check || raise || allInForCall) {
          GameAudio.getInstance().stopSound();
          if (
            tempTableId === state?.activeGame &&
            state.applicationVisibility
          ) {
            if (state.tableSettings.haptics) {
              GameAudio.getInstance().playVibration(VIBRATE_LIGHT);
            }
            GameAudio.getInstance().playAudio(
              !state.tableSettings.sound,
              TURN_SOUND,
              true,
              false
            );
          }
        }

        // AUTO TABLE SWITCHING LOGIC
        // Appending the game to auto table switch queue if it requires user action
        let doesUserHaveToAct = call || fold || check || raise || allInForCall;
        let currentTableData = state.games[state.activeGame];
        let isPlayerTurnOnCurrentTable = currentTableData
          ? currentTableData.playerTurn === currentTableData.playerSeat
          : false;
        let autoTableSwitchingQueue = state.autoTableSwitchQueue;

        if (doesUserHaveToAct) {
          autoTableSwitchingQueue = autoTableSwitchingQueue.filter(
            (item) => item !== tempTableId
          );
          autoTableSwitchingQueue.push(tempTableId);
        }
        state.autoTableSwitchQueue = autoTableSwitchingQueue;

        // Switching will occur immediately here incase the player is on a table where it is not their turn.
        if (!isPlayerTurnOnCurrentTable) {
          if (
            autoTableSwitchingQueue.length > 0 &&
            state.tableSettings.autoTableSwitch
          ) {
            if (autoTableSwitchingQueue[0] > state.activeGame) {
              state.swipeDirection = "left";
            } else {
              state.swipeDirection = "right";
            }
            if (state.games[state.autoTableSwitchQueue[0]]) {
              state.activeGame = state.autoTableSwitchQueue[0];
            } else {
              sendFaroLog({
                type: "autoTableSwitchFailure",
                state,
              });
            }
          }
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleCallActionAck: (state, action) => {
      const {
        tempTableId,
        playerName,
        atTableAmount,
        betAmount,
        isAllIn,
        status,
      } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (status === "FAILED") {
          // Making sure that it is still the players turn to render buttons again.
          // If the turn has passed, do not show his actions even in case of failure.
          if (
            state.games[tempTableId].playerTurn ===
            state.games[tempTableId].playerSeat
          ) {
            state.games[tempTableId].disableAutoActions = false;
            state.games[tempTableId].disableDefaultActions = false;
            state.games[tempTableId].showDefaultActions = true;
            state.games[tempTableId].showAutoActions = false;
            state.games[tempTableId].toastMessages.push({
              type: TOAST_ERROR,
              message: "Failed to call. Please try again.",
            });
          }
        } else if (status === "SUCCESS") {
          let updatedPlayerDetails = state.games[tempTableId].playerData.map(
            (eachPlayer) => {
              if (eachPlayer.userName === playerName) {
                eachPlayer.atTableAmount =
                  parseDecimalValuesOfNumber(atTableAmount);
                eachPlayer.betAmount = parseDecimalValuesOfNumber(betAmount);
                eachPlayer.lastAction = CALL_ACTION;
                eachPlayer.allIn = isAllIn;
                eachPlayer.startTableTimer = false;
                eachPlayer.tableTimer = 0;
                eachPlayer.activeTableTimerType = "";
                eachPlayer.remainingTime = false;
                eachPlayer.startPlayerChipAnimation = true;
                eachPlayer.startAllInAnimation = isAllIn;
                return eachPlayer;
              } else {
                return eachPlayer;
              }
            }
          );

          state.games[tempTableId].playerData = updatedPlayerDetails;
          state.games[tempTableId].showAutoActions = false;
          state.games[tempTableId].showDefaultActions = false;
          state.games[tempTableId].disableAutoActions = false;
          state.games[tempTableId].disableDefaultActions = false;
          state.games[tempTableId].playerTurn = false;
          state.games[tempTableId].autoAction = "";

          GameAudio.getInstance().stopSound();

          if (state.tableSettings.haptics) {
            GameAudio.getInstance().playVibration(VIBRATE_LIGHT);
          }

          if (
            tempTableId === state?.activeGame &&
            state.applicationVisibility
          ) {
            GameAudio.getInstance().playAudio(
              !state.tableSettings.sound,
              CALL_SOUND,
              true,
              false
            );
          }
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateAllInAnimationFlag: (state, action) => {
      const { tempTableId, position } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (player) => {
            if (player.position === position) {
              player.startAllInAnimation = false;
            }
            return player;
          }
        );
        state.games[tempTableId].playerData = updatedPlayerDetails;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    setHappyHoursInfoLoading: (state, action) => {
      let { loading } = action.payload;
      state.happyHoursInfoLoading = loading;
    },

    updateGameDefinition: (state, action) => {
      const { gameDefinition } = action.payload;

      let tempTableIds = Object.keys(state.games);
      tempTableIds.map((tempTableId) => {
        let gameData = state.games[tempTableId];
        if (gameData.gameDefinition.gameDefId === gameDefinition.gameDefId) {
          gameData.gameDefinition = gameDefinition
        }
      })
    },

    setPlayerAvatar : (state, action) =>{
      state.playerAvatar = action.payload;
    },

    processHappyHoursInfo: (state, action) => {
      let { playerTotalSavings, totalTableSavings, playerTableSavings } =
        action.payload;
      state.happyHoursInfo = {
        playerTotalSavings: playerTotalSavings,
        totalTableSavings: totalTableSavings,
        playerTableSavings: playerTableSavings,
      };
      state.happyHoursInfoLoading = false;
      state.happyHoursInfoFailure = false;
    },

    processHappyHoursInfoFailure: (state, action) => {
      state.happyHoursInfoLoading = false;
      state.happyHoursInfoFailure = true;
    },

    handleCallActionBroadcast: (state, action) => {
      const { tempTableId, playerName, atTableAmount, betAmount, isAllIn } =
        action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            if (eachPlayer.userName === playerName) {
              eachPlayer.atTableAmount =
                parseDecimalValuesOfNumber(atTableAmount);
              eachPlayer.betAmount = parseDecimalValuesOfNumber(betAmount);
              eachPlayer.lastAction = CALL_ACTION;
              eachPlayer.allIn = isAllIn;
              eachPlayer.startTableTimer = false;
              eachPlayer.tableTimer = 0;
              eachPlayer.activeTableTimerType = "";
              eachPlayer.remainingTime = false;
              eachPlayer.startPlayerChipAnimation = true;
              eachPlayer.startAllInAnimation = isAllIn;
              return eachPlayer;
            } else {
              return eachPlayer;
            }
          }
        );
        state.games[tempTableId].playerData = updatedPlayerDetails;
        state.games[tempTableId].playerTurn = false;

        GameAudio.getInstance().stopSound();

        if (tempTableId === state?.activeGame && state.applicationVisibility) {
          GameAudio.getInstance().playAudio(
            !state.tableSettings.sound,
            CALL_SOUND,
            true,
            false
          );
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleCheckActionAck: (state, action) => {
      const { tempTableId, playerName, atTableAmount, status } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (status === "FAILED") {
          // Making sure that it is still the players turn to render buttons again.
          // If the turn has passed, do not show his actions even in case of failure.
          if (
            state.games[tempTableId].playerTurn ===
            state.games[tempTableId].playerSeat
          ) {
            state.games[tempTableId].disableAutoActions = false;
            state.games[tempTableId].disableDefaultActions = false;
            state.games[tempTableId].showDefaultActions = true;
            state.games[tempTableId].showAutoActions = false;
            state.games[tempTableId].toastMessages.push({
              type: TOAST_ERROR,
              message: "Failed to check. Please try again.",
            });
          }
        } else if (status === "SUCCESS") {
          let updatedPlayerDetails = state.games[tempTableId].playerData.map(
            (eachPlayer) => {
              if (eachPlayer.userName === playerName) {
                eachPlayer.atTableAmount =
                  parseDecimalValuesOfNumber(atTableAmount);
                eachPlayer.lastAction = CHECK_ACTION;
                eachPlayer.startTableTimer = false;
                eachPlayer.tableTimer = 0;
                eachPlayer.activeTableTimerType = "";
                eachPlayer.remainingTime = false;
                eachPlayer.startPlayerChipAnimation = false;
                return eachPlayer;
              } else {
                return eachPlayer;
              }
            }
          );
          state.games[tempTableId].playerData = updatedPlayerDetails;
          state.games[tempTableId].autoAction = "";
          state.games[tempTableId].showAutoActions = false;
          state.games[tempTableId].showDefaultActions = false;
          state.games[tempTableId].disableAutoActions = false;
          state.games[tempTableId].disableDefaultActions = false;
          state.games[tempTableId].playerTurn = false;

          GameAudio.getInstance().stopSound();

          if (state.tableSettings.haptics) {
            GameAudio.getInstance().playVibration(VIBRATE_LIGHT);
          }
          if (
            tempTableId === state?.activeGame &&
            state.applicationVisibility
          ) {
            GameAudio.getInstance().playAudio(
              !state.tableSettings.sound,
              CHECK_SOUND,
              true,
              false
            );
          }
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleCheckActionBroadcast: (state, action) => {
      const { tempTableId, playerName, atTableAmount } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            if (eachPlayer.userName === playerName) {
              eachPlayer.atTableAmount =
                parseDecimalValuesOfNumber(atTableAmount);
              eachPlayer.lastAction = CHECK_ACTION;
              eachPlayer.startPlayerChipAnimation = false;
              eachPlayer.startTableTimer = false;
              eachPlayer.tableTimer = 0;
              eachPlayer.activeTableTimerType = "";
              eachPlayer.remainingTime = false;
              return eachPlayer;
            } else {
              return eachPlayer;
            }
          }
        );
        state.games[tempTableId].playerData = updatedPlayerDetails;
        state.games[tempTableId].playerTurn = false;

        GameAudio.getInstance().stopSound();

        if (tempTableId === state?.activeGame && state.applicationVisibility) {
          GameAudio.getInstance().playAudio(
            !state.tableSettings.sound,
            CHECK_SOUND,
            true,
            false
          );
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleFoldActionAck: (state, action) => {
      const { tempTableId, playerName, atTableAmount, status } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (status === "FAILED") {
          // Making sure that it is still the players turn to render buttons again.
          // If the turn has passed, do not show his actions even in case of failure.
          if (
            state.games[tempTableId].playerTurn ===
            state.games[tempTableId].playerSeat
          ) {
            state.games[tempTableId].disableAutoActions = false;
            state.games[tempTableId].disableDefaultActions = false;
            state.games[tempTableId].showDefaultActions = true;
            state.games[tempTableId].showAutoActions = false;
            state.games[tempTableId].toastMessages.push({
              type: TOAST_ERROR,
              message: "Failed to fold. Please try again.",
            });
          }
        } else if (status === "SUCCESS") {
          let updatedPlayerDetails = state.games[tempTableId].playerData.map(
            (eachPlayer) => {
              if (eachPlayer.userName === playerName) {
                eachPlayer.isActive = true;
                eachPlayer.isFolded = true;
                eachPlayer.startTableTimer = false;
                eachPlayer.tableTimer = 0;
                eachPlayer.activeTableTimerType = "";
                eachPlayer.remainingTime = false;
                eachPlayer.lastAction = FOLD_ACTION;
                eachPlayer.activeTableTimerType = "";
                eachPlayer.remainingTime = false;
                return eachPlayer;
              } else {
                return eachPlayer;
              }
            }
          );

          state.games[tempTableId].playerData = updatedPlayerDetails;
          state.games[tempTableId].disableAutoActions = true;
          state.games[tempTableId].disableDefaultActions = true;
          state.games[tempTableId].showAutoActions = false;
          state.games[tempTableId].showDefaultActions = false;
          state.games[tempTableId].autoAction = "";
          state.games[tempTableId].playerTurn = false;
          state.games[tempTableId].startWinnerPotAnimation = false;

          GameAudio.getInstance().stopSound();

          if (
            tempTableId === state?.activeGame &&
            state.applicationVisibility
          ) {
            if (state.tableSettings.haptics) {
              GameAudio.getInstance().playVibration(VIBRATE_LIGHT);
            }
            GameAudio.getInstance().playAudio(
              !state.tableSettings.sound,
              FOLD_SOUND,
              true,
              false
            );
          }
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleFoldActionBroadcast: (state, action) => {
      const { tempTableId, playerName } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            if (eachPlayer.userName === playerName) {
              eachPlayer.isActive = true;
              eachPlayer.isFolded = true;
              eachPlayer.startTableTimer = false;
              eachPlayer.tableTimer = 0;
              eachPlayer.activeTableTimerType = "";
              eachPlayer.remainingTime = false;
              eachPlayer.lastAction = FOLD_ACTION;
              return eachPlayer;
            } else {
              return eachPlayer;
            }
          }
        );

        state.games[tempTableId].playerData = updatedPlayerDetails;
        state.games[tempTableId].showDefaultActions = false;
        state.games[tempTableId].showAutoActions = false;
        state.games[tempTableId].disableAutoActions = true;
        state.games[tempTableId].disableDefaultActions = true;
        state.games[tempTableId].playerTurn = false;

        GameAudio.getInstance().stopSound();

        if (tempTableId === state?.activeGame && state.applicationVisibility) {
          GameAudio.getInstance().playAudio(
            !state.tableSettings.sound,
            FOLD_SOUND,
            true,
            false
          );
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRaiseActionAck: (state, action) => {
      const { tempTableId, status, allIn, position, betAmount, atTableAmount } =
        action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (status === "FAILED") {
          // Making sure that it is still the players turn to render buttons again.
          // If the turn has passed, do not show his actions even in case of failure.
          if (
            state.games[tempTableId].playerTurn ===
            state.games[tempTableId].playerSeat
          ) {
            state.games[tempTableId].disableAutoActions = false;
            state.games[tempTableId].disableDefaultActions = false;
            state.games[tempTableId].showDefaultActions = true;
            state.games[tempTableId].showAutoActions = false;
            state.games[tempTableId].toastMessages.push({
              type: TOAST_ERROR,
              message: "Failed to Raise. Please try again.",
            });
          }
        } else if (status === "SUCCESS") {
          let updatedPlayerDetails = state.games[tempTableId].playerData.map(
            (eachPlayer) => {
              if (eachPlayer.position === position) {
                eachPlayer.atTableAmount =
                  parseDecimalValuesOfNumber(atTableAmount);
                eachPlayer.startTableTimer = false;
                eachPlayer.tableTimer = 0;
                eachPlayer.activeTableTimerType = "";
                eachPlayer.remainingTime = false;
                eachPlayer.allIn = allIn;
                eachPlayer.betAmount = parseDecimalValuesOfNumber(betAmount);
                eachPlayer.lastAction = RAISE_ACTION;
                eachPlayer.activeTableTimerType = "";
                eachPlayer.remainingTime = false;
                eachPlayer.startPlayerChipAnimation = true;
                eachPlayer.startAllInAnimation = allIn;
                return eachPlayer;
              } else {
                return eachPlayer;
              }
            }
          );

          state.games[tempTableId].disableAutoActions = false;
          state.games[tempTableId].disableDefaultActions = false;
          state.games[tempTableId].showAutoActions = false;
          state.games[tempTableId].showDefaultActions = false;
          state.games[tempTableId].playerData = updatedPlayerDetails;
          state.games[tempTableId].autoAction = "";
          state.games[tempTableId].playerTurn = false;

          GameAudio.getInstance().stopSound();

          if (tempTableId === state.activeGame && state.applicationVisibility) {
            if (state.tableSettings.haptics) {
              GameAudio.getInstance().playVibration(VIBRATE_LIGHT);
            }

            GameAudio.getInstance().playAudio(
              !state.tableSettings.sound,
              RAISE_SOUND,
              true,
              false
            );
          }
        } else {
          state.games[tempTableId].disableAutoActions = false;
          state.games[tempTableId].disableDefaultActions = false;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRaiseActionBroadcast: (state, action) => {
      const { tempTableId, playerName, allIn, betAmount, atTableAmount } =
        action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            if (eachPlayer.userName === playerName) {
              eachPlayer.atTableAmount =
                parseDecimalValuesOfNumber(atTableAmount);
              eachPlayer.startTableTimer = false;
              eachPlayer.tableTimer = 0;
              eachPlayer.activeTableTimerType = "";
              eachPlayer.remainingTime = false;
              eachPlayer.allIn = allIn;
              eachPlayer.betAmount = parseDecimalValuesOfNumber(betAmount);
              eachPlayer.lastAction = RAISE_ACTION;
              eachPlayer.startPlayerChipAnimation = true;
              eachPlayer.startAllInAnimation = allIn;
              return eachPlayer;
            } else {
              return eachPlayer;
            }
          }
        );

        state.games[tempTableId].playerData = updatedPlayerDetails;
        state.games[tempTableId].disableAutoActions = false;
        state.games[tempTableId].disableDefaultActions = false;
        state.games[tempTableId].playerTurn = false;

        GameAudio.getInstance().stopSound();

        if (tempTableId === state.activeGame && state.applicationVisibility) {
          GameAudio.getInstance().playAudio(
            !state.tableSettings.sound,
            RAISE_SOUND,
            true,
            false
          );
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleAutoCallActionAck: (state, action) => {
      const { tempTableId, autoActionEnable } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].autoAction = autoActionEnable
          ? AUTO_CALL_ACTION
          : "";
        state.games[tempTableId].disableAutoActions = false;
        state.games[tempTableId].disableDefaultActions = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleAutoCallAnyActionAck: (state, action) => {
      const { tempTableId, autoActionEnable } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].autoAction = autoActionEnable
          ? AUTO_CALL_ANY_ACTION
          : "";
        state.games[tempTableId].disableAutoActions = false;
        state.games[tempTableId].disableDefaultActions = false;

        GameAudio.getInstance().stopSound();

        if (tempTableId === state.activeGame && state.applicationVisibility) {
          GameAudio.getInstance().playAudio(
            !state.tableSettings.sound,
            BUTTON_SOUND,
            true,
            false
          );
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleAutoCheckActionAck: (state, action) => {
      const { tempTableId, autoActionEnable } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].autoAction = autoActionEnable
          ? AUTO_CHECK_ACTION
          : "";
        state.games[tempTableId].disableAutoActions = false;
        state.games[tempTableId].disableDefaultActions = false;

        GameAudio.getInstance().stopSound();

        if (tempTableId === state.activeGame && state.applicationVisibility) {
          GameAudio.getInstance().playAudio(
            !state.tableSettings.sound,
            BUTTON_SOUND,
            true,
            false
          );
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleAutoCheckFoldActionAck: (state, action) => {
      const { tempTableId, autoActionEnable } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].autoAction = autoActionEnable
          ? AUTO_CHECK_OR_FOLD_ACTION
          : "";
        state.games[tempTableId].disableAutoActions = false;
        state.games[tempTableId].disableDefaultActions = false;

        GameAudio.getInstance().stopSound();

        if (tempTableId === state.activeGame && state.applicationVisibility) {
          GameAudio.getInstance().playAudio(
            !state.tableSettings.sound,
            BUTTON_SOUND,
            true,
            false
          );
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleAutoFoldActionAck: (state, action) => {
      const { tempTableId, autoActionEnable } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].autoAction = autoActionEnable
          ? AUTO_FOLD_ACTION
          : "";
        state.games[tempTableId].disableAutoActions = false;
        state.games[tempTableId].disableDefaultActions = false;
        GameAudio.getInstance().stopSound();
        if (tempTableId === state.activeGame && state.applicationVisibility) {
          GameAudio.getInstance().playAudio(
            !state.tableSettings.sound,
            BUTTON_SOUND,
            true,
            false
          );
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handlePreSelectedAutoAction: (state, action) => {
      const { tempTableId, preSelectedAction } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].autoAction = preSelectedAction;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handlePotWinner: (state, action) => {
      const { tempTableId } = action.payload;
      state.games[tempTableId].playerData.map((player, index) => {
        player.isSmallBlind = false;
        player.isBigBlind = false;
        player.isForcedBlind = false;
        player.lastAction =
          player.lastAction === FOLD_ACTION ? player.lastAction : undefined;
      });
      state.games[tempTableId].showDefaultActions = false;
      state.games[tempTableId].showAutoActions = false;
      state.games[tempTableId].isWinnerDeclared = true;
    },

    updateWinningPotDisplay: (state, action) => {
      const { tempTableId, pot } = action.payload;
      let potData = { ...pot };
      potData.playerCoordinates = {};
      state.games[tempTableId].playerData.map((player, index) => {
        if (pot?.winnerNames.includes(player.userName)) {
          player.isWinner = true;
          if (!isNaN(player.winningAmount)) {
            player.atTableAmount = pot.winners[player.userName]?.atTableAmount;
            player.winningAmount = pot.netWinningPlayers[player.userName];
          } else {
            player.winningAmount = undefined;
          }
          // Updating winner positions here within winningPotDisplay
          let playerPosition = state.games[tempTableId]?.playerPositions.find(
            (data) => data.position === player.position
          );
          potData.playerCoordinates[player.userName] = current(playerPosition);
          potData.playAnimation = state.activeGame === tempTableId;
        } else {
          player.isWinner = false;
        }
      });
      state.games[tempTableId].winningPotDisplay = potData;
      if (tempTableId === state.activeGame && state.applicationVisibility) {
        GameAudio.getInstance().playAudio(
          !state.tableSettings.sound,
          WINNER_SOUND,
          true,
          false
        );
      }
    },

    handleRevealOtherPlayerCards: (state, action) => {
      const { tempTableId, resultDetails } = action.payload;
      const { resultBroadcasts } = resultDetails;

      let tableData = state.games[tempTableId];

      if (resultBroadcasts.length > 0) {
        tableData.playerData.map((player) => {
          resultBroadcasts.map((detail) => {
            if (
              detail.playerName === player.userName &&
              player.isActive &&
              detail?.userCards?.length > 0 &&
              detail?.rankDetails?.handType &&
              detail?.rankDetails?.winningHand
            ) {
              player.cards = detail.userCards;
              player.revealCard = true;
              player.handType = getHandType(detail.rankDetails.handType);
              player.winningHand = detail.rankDetails.winningHand.split(",");
            }
          });
        });
      }
    },

    handleResult: (state, action) => {
      const { tempTableId, resultDetails } = action.payload;
      const { resultBroadcasts, playersAutoMuckDtls } = resultDetails;
      let tableData = state.games[tempTableId];
      let highestHandRank = 0;

      if (tableData) {
        tableData.winningHand = [];

        if (resultBroadcasts.length > 0) {
          tableData.playerData.map((player) => {
            player.lastAction =
              player.allIn || player.lastAction === FOLD_ACTION
                ? player.lastAction
                : undefined;
            resultBroadcasts.map((detail) => {
              if (
                detail.playerName === player.userName &&
                player.isActive &&
                detail?.rankDetails
              ) {
                highestHandRank = Math.max(
                  highestHandRank,
                  detail?.rankDetails?.rank
                );
                player.rankDetails = detail?.rankDetails;
              }

              if (detail.playerName === state.userDetails.userName) {
                tableData.happyHoursSavingForGame = detail?.happyHourSavings;
              }

              // Below code is to handle auto muck scenario
              if (
                detail.playerName === player.userName &&
                (!detail?.userCards || !detail?.rankDetails) &&
                detail?.autoMuck
              ) {
                player.muckCards = true;
              }
            });
          });
        }
        tableData.playerTurn = false;
        // Below is the code to flag if the user is active on ANY table. This flag can be used in lobby to know if he is active on ATLEAST 1 table.
        state = checkIfIsPlayerTurnOnAnyTable(state);
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    initiateNextGameStartRequest: (state, action) => {
      const { tempTableId, loggedInPlayerObject } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        const loggedInPlayerOnTheTable = state.games[
          tempTableId
        ].playerData.find(
          (eachPlayer) => eachPlayer.userName === loggedInPlayerObject.userName
        );
        if (
          loggedInPlayerObject.playerStatus === DELETED_PLAYER ||
          loggedInPlayerObject.playerStatus === TO_BE_DELETED_PLAYER
        ) {
          state.games[tempTableId].gameModal.type = DELETED_PLAYER_LOGOUT;
          state.games[tempTableId].gameModal.visible = true;
        }
        if (
          !loggedInPlayerObject.isAllowLocation &&
          state.games[tempTableId].gameDefinition.flavourType === LB_REAL
        ) {
          state.games[tempTableId].gameModal.type =
            RESTRICTED_LOCATION_GAME_EXIT;
          state.games[tempTableId].gameModal.visible = true;
          TableUtils.getInstance().removeTableObserver(tempTableId, false);
        }
        if (
          loggedInPlayerOnTheTable &&
          !state.games[tempTableId].playerSitOutTimer &&
          !state.games[tempTableId].sitOutOption &&
          loggedInPlayerObject.playerStatus !== DELETED_PLAYER &&
          loggedInPlayerObject.playerStatus !== TO_BE_DELETED_PLAYER //DELETED_PLAYER & TO_BE_DELETED_PLAYER check not required here as they are checked during start of this method
        ) {
          sendNextGameProtocol({
            tempTableId: tempTableId,
            playerName: loggedInPlayerObject.userName,
          });
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleMaintenancePopup: (state, action) => {
      let { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];
      if (tableData) {
        state = processCleanTable(state, tempTableId);
        state.games[tempTableId].showMaintenancePopup = true;
        state.games[tempTableId].gameConnectionStatus =
          MATCH_MAKING_BET_DISABLED;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleAutoRebuySuccess: (state, action) => {
      const { tempTableId, playerName, Amount } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let isFunGame = state.games[tempTableId]?.isFunGame;

        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            if (eachPlayer.userName === playerName) {
              eachPlayer.atTableAmount = Amount;
              return eachPlayer;
            } else {
              return eachPlayer;
            }
          }
        );
        state.games[tempTableId].playerData = updatedPlayerDetails;
        state.games[tempTableId].toastMessages.push({
          type: TOAST_SUCCESS,
          message: isFunGame
            ? `Topup of ${Amount} completed successfully.`
            : `Topup of ₹${Amount} completed successfully.`,
        });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleAutoRebuyBroadcast: (state, action) => {
      const { tempTableId, playerName, Amount } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            if (eachPlayer.userName === playerName) {
              eachPlayer.atTableAmount = Amount;
              return eachPlayer;
            } else {
              return eachPlayer;
            }
          }
        );
        state.games[tempTableId].playerData = updatedPlayerDetails;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRebuyIn: (state, action) => {
      const { tempTableId, endTime } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (
          state.games[tempTableId].playerSeat !== undefined &&
          state.games[tempTableId].playerSeat !== null
        ) {
          state.games[tempTableId].openRebuyInModal = true;
          state.games[tempTableId].antiBanking.antiBankAmount = 0;
          state.games[tempTableId].antiBanking.isAntiBank = false;
          state.games[tempTableId].buyInTimeInSeconds = endTime;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleDRebuyIn: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].openRebuyInModal = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRebuyFailure: (state, action) => {
      const { tempTableId, statusCode } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (statusCode === REBUY_INSUFFICIENT_FUNDS) {
          state.games[tempTableId].openInSufficientFundsModal = true;
        } else {
          state.games[tempTableId].openRebuyInModal = false;
          state.games[tempTableId].toastMessages.push({
            type: TOAST_ERROR,
            message: "Something went wrong! please try again.",
          });
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRebuySuccessAck: (state, action) => {
      const { tempTableId, Amount, playerName, statusCode } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let isFunGame = state.games[tempTableId]?.isFunGame;

        let updatedPlayersData = state.games[tempTableId].playerData.map(
          (player) => {
            if (player.userName === playerName) {
              player.atTableAmount = Amount;
              return player;
            } else {
              return player;
            }
          }
        );
        state.games[tempTableId].playerData = [...updatedPlayersData];
        state.games[tempTableId].openRebuyInModal = false;
        if (statusCode === 403) {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_SUCCESS,
            message: `Rebuy-in will be processed after game start`,
          });
        } else if (statusCode === 501) {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_SUCCESS,
            message: isFunGame
              ? `Amount of ${Amount} added successfully`
              : `Amount of ₹${Amount} added successfully`,
          });
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRebuySuccessBroadcast: (state, action) => {
      const { tempTableId, Amount, playerName } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let updatedPlayersData = state.games[tempTableId].playerData.map(
          (player) => {
            if (player.userName === playerName) {
              player.atTableAmount = Amount;
              return player;
            } else {
              return player;
            }
          }
        );
        state.games[tempTableId].playerData = [...updatedPlayersData];
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleAutoRebuyFailure: (state, action) => {
      const { tempTableId, statusCode } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (statusCode === REBUY_INSUFFICIENT_FUNDS) {
          state.games[tempTableId].openInSufficientFundsModal = true;
        } else if (statusCode === REBUY_OTHER) {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_ERROR,
            message: "Something went wrong! please try again.",
          });
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateTopupModalOpenStatus: (state, action) => {
      const { isOpen, tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].openTopupModal = isOpen;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    initiateTopupRequest: (state, action) => {
      const { tempTableId, topUpAmount } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let requestPayload = {
          topUpAmount: topUpAmount,
        };
        let gameConnections = GameConnections.getInstance();
        let activeGameConnection =
          gameConnections.getGameConnection(tempTableId);
        if (activeGameConnection && activeGameConnection.messageHandler) {
          activeGameConnection.gameClientListener.requestTopup(requestPayload);
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTopupSuccessAck: (state, action) => {
      const {
        tempTableId,
        status,
        playerName,
        atTableAmount,
        amountToBeDebited,
      } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        let isFunGame = state.games[tempTableId]?.isFunGame;

        if (status === "SUCCESS") {
          let updatedPlayerData = state.games[tempTableId].playerData.map(
            (eachPlayer) => {
              if (eachPlayer.userName === playerName) {
                eachPlayer.atTableAmount = atTableAmount;
                if (amountToBeDebited) {
                  state.games[tempTableId].toastMessages.push({
                    type: TOAST_SUCCESS,
                    message: isFunGame
                      ? `Your top up of ${amountToBeDebited} chips is successful.`
                      : `Your top up of ₹${amountToBeDebited} is successful.`,
                  });
                } else {
                  state.games[tempTableId].toastMessages.push({
                    type: TOAST_SUCCESS,
                    message: isFunGame
                      ? `Your top up to ${atTableAmount} chips is successful.`
                      : `Your top up to ₹${atTableAmount} is successful.`,
                  });
                }
                return eachPlayer;
              } else {
                return eachPlayer;
              }
            }
          );
          state.games[tempTableId].playerData = [...updatedPlayerData];
          state.games[tempTableId].openTopupModal = false;
          state.games[tempTableId].topUpPending = false;
        } else if (status === "PENDING") {
          state.games[tempTableId].openTopupModal = false;
          state.games[tempTableId].toastMessages.push({
            type: TOAST_WARNING,
            message: `Topup pending`,
          });
          state.games[tempTableId].topUpPending = true;
        } else if (status === "CANCELLED") {
          state.games[tempTableId].topUpPending = false;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTopupBroadcast: (state, action) => {
      const { tempTableId, playerName, atTableAmount } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let updatedPlayerData = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            if (eachPlayer.userName === playerName) {
              eachPlayer.atTableAmount = atTableAmount;
              return eachPlayer;
            } else {
              return eachPlayer;
            }
          }
        );
        state.games[tempTableId].playerData = [...updatedPlayerData];
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTopupFailure: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].toastMessages.push({
          type: TOAST_ERROR,
          message: `Your top up has failed. `,
        });
        state.games[tempTableId].openTopupModal = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTopupCancel: (state, action) => {
      const { tempTableId } = action.payload;
      // Not showing the toast message in the case of *AUTO* top up cancellation. This is because it will show up every hand.
      // IM-2306
      if (action.payload?.isAutoTopUp) {
      }
      let tableData = state.games[tempTableId];
      if (tableData) {
        state.games[tempTableId].toastMessages.push({
          type: TOAST_INFO,
          message: `Your stack exceeds the max buy-in limit, so your top up has been cancelled.`,
        });
        state.games[tempTableId].openTopupModal = false;
        state.games[tempTableId].topUpPending = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRevealCards: (state, action) => {
      const { tempTableId, revealCardsDetails } = action.payload;
      const { gameStage, revealCard } = revealCardsDetails;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].gameStage = gameStage;
        state.games[tempTableId].doCommunityCardsExist = true;

        if (gameStage === GAME_STAGE_FLOP) {
          state.games[tempTableId].communityCards = revealCard;
        } else if (gameStage === GAME_STAGE_TURN) {
          let prevcards = state.games[tempTableId].communityCards.filter(
            (eachCard) => eachCard !== "cardBack"
          );
          state.games[tempTableId].communityCards = [
            ...prevcards,
            ...revealCard,
          ];
        } else if (gameStage === GAME_STAGE_RIVER) {
          let prevcards = state.games[tempTableId].communityCards.filter(
            (eachCard) => eachCard !== "cardBack"
          );

          state.games[tempTableId].communityCards = [
            ...prevcards,
            ...revealCard,
          ];
        } else if (gameStage === GAME_STAGE_SHOWDOWN) {
          state.games[tempTableId].communityCards = [...revealCard];
        }

        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            // not resetting last action whenever a player folds, call/raise actions are being reset in  resetLastActionBeforeCardReveals
            eachPlayer.lastAction = eachPlayer.isFolded
              ? eachPlayer.lastAction
              : undefined;
            eachPlayer.betAmount =
              (eachPlayer.allIn && gameStage === GAME_STAGE_SHOWDOWN) ||
              gameStage === GAME_STAGE_SHOWDOWN
                ? eachPlayer.betAmount
                : 0;
            eachPlayer.startTableTimer = false;
            eachPlayer.tableTimer = 0;
            eachPlayer.activeTableTimerType = "";
            eachPlayer.remainingTime = false;
            eachPlayer.showBlindIcon = false;

            return eachPlayer;
          }
        );
        state.games[tempTableId].playerData = updatedPlayerDetails;
        state.games[tempTableId].autoAction = state.games[tempTableId]
          .playerWaitingPosition
          ? state.games[tempTableId].autoAction
          : "";
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTableTimerChangeRequest: (state, action) => {
      const { tempTableId, position, time, maxTime } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let playerSeat = state.games[tempTableId].playerSeat;
        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            if (eachPlayer.position === position) {
              if (playerSeat !== position) {
                eachPlayer.tableTimer = time;
                eachPlayer.totalTime = time;
                eachPlayer.maxTurnTimer = maxTime;
                eachPlayer.startTableTimer = true;
                eachPlayer.activeTableTimerType = PLAYER_DEFAULT_TIMER;
                eachPlayer.remainingTime = false;
              }
              return eachPlayer;
            } else {
              eachPlayer.startTableTimer = false;
              eachPlayer.tableTimer = 0;
              eachPlayer.activeTableTimerType = "";
              eachPlayer.remainingTime = false;
              return eachPlayer;
            }
          }
        );
        state.games[tempTableId].playerData = updatedPlayerDetails;
        state.games[tempTableId].playerTurn = position;
        // Below is the code to flag if the user is active on ANY table. This flag can be used in lobby to know if he is active on ATLEAST 1 table.
        state = checkIfIsPlayerTurnOnAnyTable(state);
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processAutoTableSwitch: (state, action) => {
      let { tempTableId } = action.payload;
      // We do not need the autoTableSwitchSetting enabled to filter out the auto table switch array. Only when setting active tables, the function should be turned on.
      let autoTableSwitchingQueue = state.autoTableSwitchQueue;
      // Removing the game user acted on from autoTableSwitchingQueue
      autoTableSwitchingQueue = autoTableSwitchingQueue.filter(
        (id) => id !== tempTableId
      );
      state.autoTableSwitchQueue = autoTableSwitchingQueue;
      let isPlayerTurnOnCurrentTable = false;
      // If the length of the queue is greater than 0, then move the user to the next table in queue immediately
      if (
        autoTableSwitchingQueue.length > 0 &&
        state.tableSettings.autoTableSwitch &&
        !isPlayerTurnOnCurrentTable
      ) {
        if (autoTableSwitchingQueue[0] > state.activeGame) {
          state.swipeDirection = "left";
        } else {
          state.swipeDirection = "right";
        }
        if (state.games[state.autoTableSwitchQueue[0]]) {
          state.activeGame = state.autoTableSwitchQueue[0];
        } else {
          sendFaroLog({
            type: "autoTableSwitchFailure",
            state,
          });
        }
      }
    },

    handlePlayerExtraTime: (state, action) => {
      const { tempTableId, playerName, timeBank, position } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (eachPlayer) => {
            if (
              eachPlayer.userName === playerName &&
              eachPlayer.position === position
            ) {
              eachPlayer.maxTurnTimer = timeBank;
              eachPlayer.startTableTimer = true;
              eachPlayer.tableTimer = timeBank;
              eachPlayer.totalTime = timeBank;
              eachPlayer.remainingTime = timeBank;
              eachPlayer.activeTableTimerType = PLAYER_EXTRA_TIMER;
              return eachPlayer;
            } else {
              eachPlayer.tableTimer = 0;
              eachPlayer.maxTurnTimer = 0;
              eachPlayer.startTableTimer = false;
              eachPlayer.remainingTime = 0;
              eachPlayer.activeTableTimerType = "";
              return eachPlayer;
            }
          }
        );
        state.games[tempTableId].playerData = updatedPlayerDetails;
        state.games[tempTableId].playerTurn = position;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handlePlayerExtraTimeLeft: (state, action) => {
      const { tempTableId, playerName, extraTimeLeft } = action.payload;
      let tableData = state.games[tempTableId];
      if (tableData) {
        let updatedPlayerDetails = state.games[tempTableId].playerData.map(
          (player) => {
            if (player.userName === playerName) {
              player.extraTimeLeft = extraTimeLeft;
            }
            return player;
          }
        );
        state.games[tempTableId].playerData = updatedPlayerDetails;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    removePlayerFromTable: (state, action) => {
      const { tempTableId, userName } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let updatedPlayersData = state.games[tempTableId].playerData.filter(
          (eachPlayer) => eachPlayer.userName !== userName
        );
        state.games[tempTableId].playerData = updatedPlayersData;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    setPlayerRemainingTime: (state, action) => {
      const { tempTableId, remainingTime, playerPosition } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        let playersData = state.games[tempTableId]?.playerData;
        if (playersData && playersData.length > 0) {
          if (remainingTime <= 0) {
            GameAudio.getInstance().stopSound();
          }
          let modifiedPlayerData = playersData.find(
            (player) => player.position === playerPosition
          );
          if (modifiedPlayerData) {
            modifiedPlayerData.remainingTime = remainingTime;
          }
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    setTableTheme: (state, action) => {
      state.themeData.table = action.payload;
    },

    setTableBackground: (state, action) => {
      state.themeData.background = action.payload;
    },

    setCardDeck: (state, action) => {
      state.themeData.cardDeck = action.payload;
    },

    setCardBack: (state, action) => {
      state.themeData.cardBack = action.payload;
    },

    setCardSize: (state, action) => {
      state.themeData.cardSize = action.payload;
    },

    toggleHandStrength: (state) => {
      if (state.tableSettings.sound) {
        GameAudio.getInstance().stopSound();
        GameAudio.getInstance().playAudio(
          !state.tableSettings.sound,
          BUTTON_SOUND,
          true,
          false
        );
      }
      state.tableSettings.showHandStrength =
        !state.tableSettings.showHandStrength;
    },

    toggleAutoTableSwitch: (state) => {
      state.tableSettings.autoTableSwitch =
        !state.tableSettings.autoTableSwitch;
      if (!state.tableSettings.autoTableSwitch) {
        state.autoTableSwitchQueue = [];
      }
      if (state.tableSettings.sound) {
        GameAudio.getInstance().stopSound();
        GameAudio.getInstance().playAudio(
          !state.tableSettings.sound,
          BUTTON_SOUND,
          true,
          false
        );
      }
    },

    toggleDisplayInBB: (state, action) => {
      //state = cloneDeep(current(state));
      state.tableSettings.BBDisplay = !state.tableSettings.BBDisplay;
      if (state.tableSettings.sound) {
        GameAudio.getInstance().stopSound();
        GameAudio.getInstance().playAudio(
          !state.tableSettings.sound,
          BUTTON_SOUND,
          true,
          false
        );
      }
      if (
        state.tableSettings.BBDisplay &&
        action.payload &&
        action.payload?.playVibration
      ) {
        state.games[state.activeGame].toastMessages.push({
          type: TOAST_INFO,
          message:
            "Your chips are now displayed in BB. You can change this by long-pressing your avatar",
        });
      } else if (
        !state.tableSettings.BBDisplay &&
        action.payload &&
        action.payload?.playVibration
      ) {
        state.games[state.activeGame].toastMessages.push({
          type: TOAST_INFO,
          message:
            "Your chips are no longer displayed in BB. You can change this by long-pressing your avatar",
        });
      }
      if (action.payload && action.payload?.playVibration) {
        GameAudio.getInstance().stopSound();
        GameAudio.getInstance().playVibration(VIBRATE_HEAVY);
      }
    },

    toggleSmartGestures: (state) => {
      state.tableSettings.smartGestures = !state.tableSettings.smartGestures;
      if (state.tableSettings.smartGestures) {
        state.tableSettings.smartGestureOptions.smartCheck = true;
        state.tableSettings.smartGestureOptions.smartFold = true;
      } else {
        state.tableSettings.smartGestureOptions.smartCheck = false;
        state.tableSettings.smartGestureOptions.smartFold = false;
      }
      if (state.tableSettings.sound) {
        GameAudio.getInstance().stopSound();
        GameAudio.getInstance().playAudio(
          !state.tableSettings.sound,
          BUTTON_SOUND,
          true,
          false
        );
      }
    },

    toggleSmartFold: (state) => {
      let smartGestureOptions = state.tableSettings.smartGestureOptions;
      smartGestureOptions.smartFold = !smartGestureOptions.smartFold;
      if (!smartGestureOptions.smartFold && !smartGestureOptions.smartCheck) {
        state.tableSettings.smartGestures = false;
      }
    },

    toggleSmartCheck: (state) => {
      let smartGestureOptions = state.tableSettings.smartGestureOptions;
      smartGestureOptions.smartCheck = !smartGestureOptions.smartCheck;
      if (!smartGestureOptions.smartFold && !smartGestureOptions.smartCheck) {
        state.tableSettings.smartGestures = false;
      }
    },

    toggleAutoMuck: (state) => {
      state.tableSettings.autoMuck = !state.tableSettings.autoMuck;

      GameAudio.getInstance().stopSound();
      GameAudio.getInstance().playAudio(
        !state.tableSettings.sound,
        BUTTON_SOUND,
        true,
        false
      );
    },

    toggleAutoRebuy: (state) => {
      state.tableSettings.autoRebuy = !state.tableSettings.autoRebuy;

      GameAudio.getInstance().stopSound();
      GameAudio.getInstance().playAudio(
        !state.tableSettings.sound,
        BUTTON_SOUND,
        true,
        false
      );
    },

    toggleAutoTopup: (state) => {
      state.tableSettings.autoTopupConfig.enabled =
        !state.tableSettings.autoTopupConfig.enabled;

      GameAudio.getInstance().stopSound();
      GameAudio.getInstance().playAudio(
        !state.tableSettings.sound,
        BUTTON_SOUND,
        true,
        false
      );
    },

    handleAutoTopupFallsBelowSliderChange: (state, action) => {
      const { value } = action.payload;
      state.tableSettings.autoTopupConfig.fallsBelowAmount = value;
      state.tableSettings.autoTopupConfig.topUpAmount = Math.max(10, value);
    },

    handleAutoTopupSliderChange: (state, action) => {
      const { value } = action.payload;
      state.tableSettings.autoTopupConfig.topUpAmount = value;
    },

    toggleSound: (state) => {
      const updatedToggleValue = !state.tableSettings.sound;
      GameAudio.getInstance().stopSound();
      if (updatedToggleValue) {
        GameAudio.getInstance().playAudio(false, BUTTON_SOUND, true, false);
      }
      state.tableSettings.sound = updatedToggleValue;
      setToLocalStorage(IS_SOUND_ENABLED, updatedToggleValue);
    },

    toggleHaptics: (state) => {
      const updatedToggleValue = !state.tableSettings.haptics;
      GameAudio.getInstance().stopSound();
      if (updatedToggleValue) {
        GameAudio.getInstance().playVibration(VIBRATE_HEAVY);
      }
      if (state.tableSettings.sound) {
        GameAudio.getInstance().playAudio(
          !state.tableSettings.sound,
          BUTTON_SOUND,
          true,
          false
        );
      }
      state.tableSettings.haptics = updatedToggleValue;
      setToLocalStorage(IS_HAPTICS_EBABLED, updatedToggleValue);
    },

    updateGameTableBoundingRect: (state, action) => {
      const { cardWidth: cardWidth } = getCardWidthAndHeight();
      const {
        tempTableId,
        positions,
        orientation,
        horizontalOffset,
        verticalOffset,
      } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        try {
          let dimensions = JSON.parse(positions);

          state.games[tempTableId].gameTableDimensions = dimensions;
          state.games[tempTableId].communityCardPositon = [];
          if (orientation === PORTRAIT) {
            let value = cardWidth < MAX_CARD_WIDTH ? 1.7 : 1.75;
            for (let i = 0; i < TEXAS_COMMUNITY_CARDS_NUMBER; i++) {
              state.games[tempTableId].communityCardPositon.push({
                index: i,
                top: dimensions.height / value,
                left:
                  horizontalOffset +
                  0.175 * dimensions.width +
                  cardWidth * i +
                  i * 4,
              });
            }
          } else {
            for (let i = 0; i < TEXAS_COMMUNITY_CARDS_NUMBER; i++) {
              state.games[tempTableId].communityCardPositon.push({
                index: i,
                top: verticalOffset + 0.375 * dimensions.height,
                left:
                  horizontalOffset +
                  0.325 * dimensions.width +
                  cardWidth * i +
                  i * 5,
              });
            }
          }
        } catch (err) {
          console.log("Error in updating bounding client rect:", err);
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updatePlayerPositionsOnTable: (state, action) => {
      const { tempTableId, positions } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].playerPositions = positions;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    changeTableOrientation: (state, action) => {
      let orientation = action?.payload?.orientation;
      if (orientation) {
        state.tableOrientation = orientation;
      } else {
        if (state.tableOrientation === PORTRAIT) {
          state.tableOrientation = LANDSCAPE;
        } else {
          state.tableOrientation = PORTRAIT;
        }
      }
    },

    updateActiveGame: (state, action) => {
      state.activeGame = action.payload;
      state.visibleGameTables = [state.activeGame];
    },

    acknowledgePlayerMovedToCenter: (state, action) => {
      let { playerMovedToCenter, tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].playerMovedToCenter = playerMovedToCenter;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    watchAllGameTables: (state, action) => {
      if (!state?.timerObserver) {
        let observer = new TimerObserver(
          action.payload.dispatchFunction,
          state.games
        );
        state.timerObserve = observer;
      }
      state?.timerObserver?.timeoutHandler();
    },

    hideGameTable: (state) => {
      state.swipeDirection = undefined;
      state.showGameTable = false;
    },

    showGameTable: (state) => {
      state.swipeDirection = undefined;
      state.showGameTable = true;
      state?.timerObserver?.cleanup();
      state.timerObserver = null;
    },

    cleanUpTableObservers: (state) => {
      state?.timerObserver?.cleanup();
      state.timerObserver = null;
    },

    closeGameWindow: (state, action) => {
      let processedState = processCloseGameWindow(current(state), action);
      return processedState;
    },

    processReconnectionAck: (state, action) => {
      const { tempTableId, gameId, playerName, tableId, latestGameId } =
        action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        //let tableData = cloneDeep(tableData);

        if (tableData.gameId !== gameId) {
          console.log(
            "Local table data gameId and received 'gameId' key value is mismatched at reconnection ack"
          );
        }

        if (tableData.gameId !== latestGameId) {
          console.log(
            "Local table data gameId and received 'latestGameId' key value is mismatched at reconnection ack"
          );
          tableData.gameId = latestGameId;
        }
        if (tableId) {
          tableData.tableId = tableId;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    closeFailedGameTable: (state, action) => {
      let games = state.games;
      let { tempTableId } = action.payload;
      let arrayOfGames = Object.keys(games);
      try {
        TableUtils.getInstance().removeTableObserver(tempTableId);
      } catch (err) {
        console.log(
          "Error in removing table observer. Game connection probably does not exist. ",
          err
        );
      }
      // Checking if more than 1 game is created, if so, navigating to the other active game.
      if (arrayOfGames.length > 1) {
        arrayOfGames = arrayOfGames.filter((game) => game !== tempTableId);
        state.activeGame = arrayOfGames[0];
      } else {
        state.activeGame = null;
      }
      delete games[tempTableId];
      state.games = games;
    },

    closeExistingTourneyGameTable: (state, action) => {
      const { tourneyId } = action.payload;

      let games = state.games;
      if (games && Object.keys(games).length > 0) {
        let arrayOfGames = Object.keys(games);
        arrayOfGames.forEach((eachTempTableId) => {
          let currentGame = games[eachTempTableId];
          if (
            currentGame?.isTourneyGame &&
            currentGame?.gameDefinition.tourneyId === tourneyId
          ) {
            TableUtils.getInstance().removeTableObserver(eachTempTableId);
          }
        });
      }
    },

    deleteGameTable: (state, action) => {
      let games = state.games;
      let { tempTableId } = action.payload;
      // Checking if more than 1 game is created, if so, navigating to the other active game.
      if (games[tempTableId]) {
        let arrayOfGames = Object.keys(games);
        if (arrayOfGames.length > 1) {
          arrayOfGames = arrayOfGames.filter((game) => game !== tempTableId);
          state.activeGame = arrayOfGames[0];
        } else {
          state.activeGame = null;
          state.swipeDirection = undefined;
          state.showGameTable = false;
        }
        sendFaroLog({
          type: "DELETING_GAME_TABLE",
          tempTableId: tempTableId,
          removingGameDetails: games[tempTableId],
        });
        delete games[tempTableId];
        state.games = games;
      } else {
        showNoTableAvailableWarning(action);
      }

      state.autoTableSwitchQueue = state.autoTableSwitchQueue.filter(
        (id) => id !== tempTableId
      );
    },

    exitGameTable: (state, action) => {
      const { tempTableId, playerName } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        let gameConnection =
          GameConnectionsN?.getInstance()?.getGameConnection(tempTableId);
        if (gameConnection) {
          const requestPayload = {
            playerName: playerName,
            tableId: tableData.tableId,
            gameDefId: tableData.gameDefinition.gameDefId,
          };
          if (tableData.isTourneyGame) {
            requestPayload["isWatchPlayer"] = tableData.watchPlayer;
          }
          gameConnection.listener.close(requestPayload);
        } else {
          //Note: As game connection is not available we are not restricting user from eixt table, we manually deleting the table and making him exit.
          TableUtils.getInstance()?.removeTableObserver(tempTableId);
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    leaveTableSeat: (state, action) => {
      let { tempTableId, playerName } = action.payload;

      let gameConnections = GameConnections.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(
        state.activeGame
      );

      if (activeGameConnection && activeGameConnection.messageHandler) {
        let activeGameTable = state.games[tempTableId];
        if (activeGameTable) {
          const { tableId } = activeGameTable;
          const { gameDefId } = activeGameTable.gameDefinition;
          activeGameConnection.gameClientListener.leaveSeat({
            playerName: playerName,
            tableId: tableId,
            gameDefId: gameDefId,
          });
        } else {
          showNoTableAvailableWarning(action);
        }
      }
    },

    joinGameTable: (state, action) => {
      state.tableBlur = true;
      let gameDefinition = action.payload;
      let bigBlind = gameDefinition.bigBlind;

      let currentGames = state.games;
      let sameBlindWatchTable = undefined;
      let sameBlindTempTableId;

      for (let game in currentGames) {
        sameBlindTempTableId =
          state.games[game].gameDefinition.bigBlind === bigBlind &&
          !game.watchPlayer;
        if (sameBlindTempTableId) {
          sameBlindWatchTable = game;
        }
      }
      let gameJoinCheckObject = {
        createNewGameTable:
          sameBlindWatchTable === undefined
            ? true
            : sameBlindWatchTable
            ? false
            : true,
        tempTableId: sameBlindWatchTable ? sameBlindWatchTable : null,
      };
      state.gameJoinCheckObject = gameJoinCheckObject;
    },

    handleStartGameTimer: (state, action) => {
      const { tempTableId, startGameTime } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].showStartGameTimer = true;
        state.games[tempTableId].startGameTimerValue = startGameTime;
        state.games[tempTableId].disableOrientationChange = true;
        state.games[tempTableId].shouldPlayCardAnimation = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    disableStartGameTimer: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].showStartGameTimer = false;
        state.games[tempTableId].startGameTimerValue = 0;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleStartGame: (state, action) => {
      const {
        tempTableId,
        userCards,
        smallBlindAmount,
        bigBlindAmount,
        playersSeatData,
        gameId,
        tableId,
        anteUserInvestments,
        anteAmount,
        happyHoursEnabled,
        happyHoursRake,
        happyHoursHeadsUpRake,
      } = action.payload;
      let tableData = state.games[tempTableId];

      state.games[tempTableId].happyHoursEnabled = happyHoursEnabled;
      state.games[tempTableId].happyHoursSavingForGame = null;
      state.games[tempTableId].gameDefinition.happyHoursRake = happyHoursRake;
      state.games[tempTableId].gameDefinition.happyHoursHeadsUpRake =
        happyHoursHeadsUpRake;
      if (happyHoursEnabled && !state.games[tempTableId].showHappyHoursToast) {
        state.games[tempTableId].showHappyHoursToast = true;
        state.games[tempTableId].toastMessages.push({
          type: TOAST_INFO,
          message: "Happy Hour has started on this table, Enjoy low rake!",
        });

        if (doesExist(state.games[tempTableId].playerSeat)) {
          state.games[tempTableId].showHappyHoursButton = true;
        }
      }

      state.games[tempTableId].happyHourSavings = null;

      if (state.games[tempTableId].showHappyHoursToast && !happyHoursEnabled) {
        state.games[tempTableId].toastMessages.push({
          type: TOAST_INFO,
          message: "Happy Hour has ended on this table",
        });
        state.games[tempTableId].showHappyHoursToast = false;
      }

      if (tableData) {
        let oldPlayerStatsData = {};
        state.games[tempTableId].playerData.map((player, index) => {
          let dataObject = {
            enabledPlayerStats: player?.enabledPlayerStats
              ? player?.enabledPlayerStats
              : false,
            colorCode: player?.colorCode ? player?.colorCode : null,
            playerNotes: player?.playerNotes ? player?.playerNotes : null,
            playerName: player?.userName,
          };
          oldPlayerStatsData[player.userName] = dataObject;
        });

        let updatedPlayersData = playersSeatData.map((eachPlayer) => {
          // This is added to make sure playerSeat is never empty. Especially in the edge case of IM-690.
          // Edge case is described in the description of the ticket.
          if (eachPlayer.playerName === state.userDetails.userName) {
            state.games[tempTableId].playerSeat = eachPlayer.position;
            state.games[tempTableId].playerSeatBeforeLeaveSeat =
              eachPlayer.position;

            // This is to mark top up pending as false incase his at table amount is greater than max buy in.
            // Either it has been successfully processed, or he won previous hand.
            if (
              eachPlayer?.atTableAmount >
              state.games[tempTableId]?.gameDefinition?.maxbuyInAmount
            ) {
              state.games[tempTableId].topUpPending = false;
            }
          }

          let playerDetails = {
            position: eachPlayer.position,
            userName: eachPlayer.playerName,
            atTableAmount: eachPlayer.atTableAmount,
            acelevel: eachPlayer.aceLevel,
            avatar: eachPlayer.profilePic,
            isReservedSeat: false,
            isTakenSeat: true,
            isActive: eachPlayer.playerStatus === ACTIVE,
            isFolded: false,
            isWinner: false,
            allIn: eachPlayer.gamePlayerStatus === ALL_IN ? true : false,
            sitOutOption:
              eachPlayer.gamePlayerStatus === SEATED_OUT ? true : false,
            isWaitingForBigBlind:
              eachPlayer.gamePlayerStatus === WAITING_FOR_BB,
            gamePlayerStatus: eachPlayer.gamePlayerStatus,
            playerStatus: eachPlayer.playerStatus,
            totalScore: eachPlayer.totalScore,
            lastAction: "",
            isSmallBlind: eachPlayer.isSmallBlind,
            isBigBlind: eachPlayer.isBigBlind,
            isForcedBlind: eachPlayer.isForcedBlind,
            betAmount: eachPlayer.userInvestment,
            isDealer: eachPlayer.isDealer,
            winningAmount: 0,
            cards:
              state.games[tempTableId].playerSeat === eachPlayer.position
                ? userCards
                : [],
            startTableTimer: false,
            activeTableTimerType: "",
            tableTimer: 0,
            revealCard: false,
            showBlindIcon: true,
            enabledPlayerStats: oldPlayerStatsData[eachPlayer.playerName]
              ?.enabledPlayerStats
              ? oldPlayerStatsData[eachPlayer.playerName].enabledPlayerStats
              : false,
            colorCode: oldPlayerStatsData[eachPlayer.playerName]?.colorCode
              ? oldPlayerStatsData[eachPlayer.playerName]?.colorCode
              : null,
            playerNotes: oldPlayerStatsData[eachPlayer.playerName]?.playerNotes
              ? oldPlayerStatsData[eachPlayer.playerName]?.playerNotes
              : null,
            muckCards: false,
            winningAmount: 0,
          };
          return playerDetails;
        });
        tableData.isTableCleaned = false;
        tableData.gameStage = GAME_STAGE_PRE_FLOP;
        tableData.smallBlindAmount = smallBlindAmount; //I see this is not used anywhere @yashwanth
        tableData.gameDefinition.smallBlind = smallBlindAmount; //updating small blind of game definition
        tableData.bigBlindAmount = bigBlindAmount; //I see this is not used anywhere @yashwanth
        tableData.gameDefinition.bigBlind = bigBlindAmount; //updating big blind of game definition
        tableData.playerData = updatedPlayersData;
        tableData.isWinnerDeclared = false;
        tableData.isGameStarted =
          tableData.isTourneyGame && anteUserInvestments ? false : true;
        tableData.showStartGameTimer = false;
        tableData.doCommunityCardsExist = false;
        tableData.restrictUserFromLeavingTable = false;
        tableData.restrictUserFromSitOut = false;
        tableData.startGameTime = -1;
        tableData.communityCards = [];
        tableData.multiPotAmounts = [];
        tableData.gameId = gameId;
        tableData.tableId = tableId;
        tableData.showRankDetails = false;
        tableData.winningPotDisplay = undefined;
        tableData.totalPotAmt = undefined;
        tableData.winningHand = [];
        tableData.disableOrientationChange = true;
        tableData.revealCards = false;
        tableData.startWinnerPotAnimation = false;
        tableData.playerCards = userCards;
        state.games[tempTableId].shouldPlayCardAnimation = true;
        tableData.numberOfSidePots = 0;

        //  might have to change the condition
        if (userCards.length > 0) {
          tableData.playerStatus = ACTIVE;
          tableData.playerMovedToCenter = true;
        }
        if (state.tableSettings.haptics && tempTableId === state.activeGame) {
          GameAudio.getInstance().playVibration(VIBRATE_HEAVY);
        }
        if (state.tableSettings.sound && tempTableId === state.activeGame) {
          GameAudio.getInstance().playAudio(
            !state.tableSettings.sound,
            DEAL_SOUND,
            true,
            false
          );
        }

        if (
          !tableData.isTourneyGame &&
          tableData.playerSeat !== null &&
          tableData.playerSeat !== undefined
        ) {
          //Part of fix for IM-2504 issue.  We are requesting for refresh# when player took seat and in exact same time if we get start# , player data is not available in start#
          const isMySeatAvailable = playersSeatData.find(
            (eachSeatedPlayer) =>
              eachSeatedPlayer.position === tableData.playerSeat
          );
          if (!isMySeatAvailable) {
            sendRefreshProtocol({
              tempTableId: tempTableId,
              playerName: state.userDetails.userName,
              tableId: tableData.tableId,
              gameId: tableData.gameId,
              tourneyId: tableData.gameDefinition.tourneyId,
            });
          }
        }

        if (tableData.isTourneyGame && anteUserInvestments) {
          tableData.playerData.map((player) => {
            if (Object.keys(anteUserInvestments).includes(player.userName)) {
              player.betAmount = parseDecimalValuesOfNumber(
                anteUserInvestments[player.userName]
              );
              player.cards = []; // NOTE: we are making empty to avoid card dealing animation while playing ante amounts animation
              return player;
            }
            return player;
          });
        }
        state.games[tempTableId] = tableData;
        if (tableData.isTourneyGame) {
          state.games[tempTableId].restrictUserFromLeavingTable =
            tableData.watchPlayer ? false : true;
          state.games[tempTableId].anteAmount = anteAmount;
        }
        if (
          tableData.isTourneyGame &&
          state.games[tempTableId].activeTourneyBannerType !==
            TOURNEY_BANNER_MERGED_WITH_ANOTHER_TABLE
        ) {
          state.games[tempTableId].activeTourneyBannerType = null;
        }

        let gameConnection =
          GameConnectionsN.getInstance().getGameConnection(tempTableId);
        gameConnection.listener.setGameId(gameId);
        gameConnection.listener.setTableId(tableId);
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processPlayerStatsData: (state, action) => {
      let { data, tempTableId } = action.payload;
      let currentPlayerName = state.userDetails.userName;
      let playerStatData = data.filter(
        (item) => item.playerName === currentPlayerName
      )[0];
      let opponentData = null;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (playerStatData) {
          opponentData = state.games[tempTableId].playerData.find(
            (player) => player.userName === playerStatData.opponetName
          );
        }
        if (opponentData) {
          if (playerStatData?.enabledPlayerStats) {
            opponentData.enabledPlayerStats = true;
            opponentData.colorCode = playerStatData?.colorCode ?? null;
          } else {
            opponentData.enabledPlayerStats = false;
            opponentData.colorCode = null;
          }
          opponentData.playerNotes = playerStatData?.playerNotes;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processPlayerStatsOnDemand: (state, action) => {
      let { data, tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        let opponentData = state.games[tempTableId].playerData.find(
          (player) => player.userName === data?.opponetName
        );
        if (opponentData) {
          if (data?.enabledPlayerStats) {
            opponentData.enabledPlayerStats = true;
            opponentData.colorCode = data?.colorCode ? data?.colorCode : null;
          } else {
            opponentData.enabledPlayerStats = false;
            opponentData.colorCode = null;
          }
          opponentData.playerNotes = data?.playerNotes;
          window.sessionStorage.setItem(
            "player-profile-data",
            JSON.stringify(opponentData)
          );
          state.games[tempTableId].gameModal.type = PLAYER_STATS;
          state.games[tempTableId].gameModal.visible = true;
        } else {
          console.error("Player data does not exist for player stats.");
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processPlayerStatsOnDemandFailure: (state, action) => {
      let { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].toastMessages.push({
          type: TOAST_ERROR,
          message: "Failed to retrieve player stats. Please try again later.",
        });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processSavePlayerStatsResponse: (state, action) => {
      let { tempTableId, data } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (data?.status === 100) {
          let PStatsSuccessData = JSON.parse(
            window.sessionStorage.getItem("PS-Success-Data")
          );
          let playerData = state.games[tempTableId].playerData.find(
            (player) => player.userName === PStatsSuccessData.opponetName
          );
          if (playerData) {
            playerData.enabledPlayerStats =
              PStatsSuccessData.enabledPlayerStats;
            playerData.playerNotes = PStatsSuccessData.playerNotes;
            playerData.colorCode = PStatsSuccessData.colorCode;
            state.games[tempTableId].toastMessages.push({
              type: TOAST_SUCCESS,
              message: "Saved player details successfully!",
            });
          } else {
            state.games[tempTableId].toastMessages.push({
              type: TOAST_ERROR,
              message:
                "Player has left the seat. Your changes have been saved.",
            });
          }
          if (state.games[tempTableId].gameModal.type === PLAYER_STATS) {
            state.games[tempTableId].gameModal.type = null;
            state.games[tempTableId].gameModal.visible = false;
          }
          window.sessionStorage.removeItem("player-profile-data");
          window.sessionStorage.removeItem("PS-Success-Data");
        } else {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_ERROR,
            message: "Failed to save player stats. Please try again later.",
          });
        }
      } else {
        showNoTableAvailableWarning(action);
      }
      window.sessionStorage.removeItem("PS-Success-Data");
    },

    handleRefreshProtocolData: (state, action) => {
      let loggedInPlayerDetails = state.userDetails;
      const {
        tempTableId,
        stage,
        rankDetails,
        sitOutEndTime,
        waitingForSitOut,
        gameId,
        tableId,
        smallBlindAmount,
        bigBlindAmount,
        userCards,
        clientMultiPotPojoList,
        isWatchPlayer,
        isAutoPlayOn,
        isNGTRunning,
        anteAmount,
      } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        if (isNGTRunning) {
          sendNextGameProtocol({
            tempTableId: tempTableId,
            playerName: loggedInPlayerDetails.userName,
          });
        }

        let updatedPlayerData = state.games[tempTableId].playerData.map(
          (player) => {
            if (
              player !== undefined &&
              player.isActive &&
              player.position === state.games[tempTableId].playerSeat &&
              rankDetails.handType &&
              stage !== GAME_STAGE_PRE_FLOP
            ) {
              player.possibleWinningHandType = getHandType(
                rankDetails.handType
              );
              player.possibleWinningHandCards =
                rankDetails.winningHand.split(",");
            }
            player.showBlindIcon = true;
            return player;
          }
        );

        state.games[tempTableId].multiPotAmounts = clientMultiPotPojoList;
        state.games[tempTableId].useProgressOffsetValue = true;
        state.games[tempTableId].showRankDetails = true;
        state.games[tempTableId].playerData = updatedPlayerData;
        state.games[tempTableId].isWinnerDeclared = false;
        state.games[tempTableId].sitOutButtonDisabled = false;
        state.games[tempTableId].openBuyInModal = false;
        state.games[tempTableId].setBuyInValueForPlayerPosition = null;
        state.games[tempTableId].processingDReserve = false;
        state.games[tempTableId].gameId = gameId;
        state.games[tempTableId].tableId = tableId;
        state.games[tempTableId].shouldPlayCardAnimation = true;
        state.games[tempTableId].playerCards = userCards;
        state.games[tempTableId].gameDefinition.smallBlind = smallBlindAmount;
        state.games[tempTableId].gameDefinition.bigBlind = bigBlindAmount;
        state.games[tempTableId].tourneyAutoPlay = isAutoPlayOn;

        state.games[tempTableId].processingReserve = {
          status: false,
          position: undefined,
        };
        state.games[tempTableId].watchPlayer = isWatchPlayer;
        if (sitOutEndTime > 0) {
          state.games[tempTableId].sitOutOption = true;
          state.games[tempTableId].playerSitOutTimer = sitOutEndTime;
          state.games[tempTableId].showDefaultActions = false;
          state.games[tempTableId].showAutoActions = false;
        } else {
          if (waitingForSitOut) {
            state.games[tempTableId].sitOutOption = true;
          }
        }
        state.games[tempTableId].disableOrientationChange = false;
        if (state.games[tempTableId].isTourneyGame) {
          state.games[tempTableId].restrictUserFromLeavingTable = state.games[
            tempTableId
          ].watchPlayer
            ? false
            : true;
          if (
            state.games[tempTableId].activeTourneyBannerType !==
            TOURNEY_BANNER_MERGED_WITH_ANOTHER_TABLE
          ) {
            state.games[tempTableId].activeTourneyBannerType = null;
          }
        }
        state.autoTableSwitchQueue = state.autoTableSwitchQueue.filter(
          (id) => id !== tempTableId
        );

        /* Note:  This check is added explicitly to ensure that playerMovedToCenter flag is true when the player is part of a toruney and the first protocol he receives is refresh instead of start, same check has been added to startGame for tourney */
        if (userCards.length > 0 && state.games[tempTableId].isTourneyGame) {
          state.games[tempTableId].playerMovedToCenter = true;
          state.games[tempTableId].anteAmount = anteAmount;
        }

        let gameConnection =
          GameConnectionsN.getInstance().getGameConnection(tempTableId);
        gameConnection.listener.setGameId(gameId);
        gameConnection.listener.setTableId(tableId);
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    toggleCallAction: (state, action) => {
      const { tempTableId, betAmount, playerName } = action.payload;
      let gameConnections = GameConnections.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(tempTableId);

      if (activeGameConnection && activeGameConnection.messageHandler) {
        let activeGameTable = state.games[tempTableId];
        if (activeGameTable) {
          const { tableId } = activeGameTable;
          activeGameConnection.gameClientListener.requestCallAction({
            playerName: playerName,
            tableId: tableId,
            gameId: activeGameTable.gameId,
            betAmount: parseDecimalValuesOfNumber(betAmount),
          });
        } else {
          showNoTableAvailableWarning(action);
        }
      }
    },

    toggleCheckAction: (state, action) => {
      const { tempTableId, playerName } = action.payload;
      let gameConnections = GameConnections.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(tempTableId);
      if (activeGameConnection && activeGameConnection.messageHandler) {
        let activeGameTable = state.games[tempTableId];
        if (activeGameTable) {
          const { tableId } = activeGameTable;
          activeGameConnection.gameClientListener.requestCheckAction({
            playerName: playerName,
            tableId: tableId,
            gameId: activeGameTable.gameId,
          });
        } else {
          showNoTableAvailableWarning(action);
        }
      }
    },

    toggleRaiseAction: (state, action) => {
      const { tempTableId, betAmount, playerName } = action.payload;
      let gameConnections = GameConnections.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(tempTableId);

      if (activeGameConnection && activeGameConnection.messageHandler) {
        let activeGameTable = state.games[tempTableId];
        if (activeGameTable) {
          const { tableId } = activeGameTable;
          activeGameConnection.gameClientListener.requestRaiseAction({
            playerName: playerName,
            tableId: tableId,
            gameId: activeGameTable.gameId,
            betAmount: parseDecimalValuesOfNumber(betAmount),
          });
        } else {
          showNoTableAvailableWarning(action);
        }
      }
    },
    toggleFoldAction: (state, action) => {
      const { tempTableId, playerName } = action.payload;
      let gameConnections = GameConnections.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(tempTableId);
      if (activeGameConnection && activeGameConnection.messageHandler) {
        let activeGameTable = state.games[tempTableId];
        if (activeGameTable) {
          const { tableId } = activeGameTable;
          activeGameConnection.gameClientListener.requestFoldAction({
            playerName: playerName,
            tableId: tableId,
            gameId: activeGameTable.gameId,
          });
        } else {
          showNoTableAvailableWarning(action);
        }
      }
    },

    toggleAutoCallAction: (state, action) => {
      const { tempTableId, betAmount, playerName, autoActionEnable } =
        action.payload;
      let gameConnections = GameConnections.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(tempTableId);

      if (activeGameConnection && activeGameConnection.messageHandler) {
        let activeGameTable = state.games[tempTableId];
        if (activeGameTable) {
          const { tableId } = activeGameTable;
          activeGameConnection.gameClientListener.requestAutoCallAction({
            playerName: playerName,
            tableId: tableId,
            gameId: activeGameTable.gameId,
            betAmount: parseDecimalValuesOfNumber(betAmount),
            autoActionEnable: autoActionEnable,
          });
        } else {
          showNoTableAvailableWarning(action);
        }
      }
    },
    toggleAutoCallAnyAction: (state, action) => {
      const { tempTableId, betAmount, playerName, autoActionEnable } =
        action.payload;
      let gameConnections = GameConnections.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(tempTableId);

      if (activeGameConnection && activeGameConnection.messageHandler) {
        let activeGameTable = state.games[tempTableId];
        if (activeGameTable) {
          const { tableId } = activeGameTable;
          activeGameConnection.gameClientListener.requestAutoCallAnyAction({
            playerName: playerName,
            tableId: tableId,
            gameId: activeGameTable.gameId,
            betAmount: parseDecimalValuesOfNumber(betAmount),
            autoActionEnable: autoActionEnable,
          });
        } else {
          showNoTableAvailableWarning(action);
        }
      }
    },
    toggleAutoCheckFoldAction: (state, action) => {
      const { tempTableId, playerName, autoActionEnable } = action.payload;
      let gameConnections = GameConnections.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(tempTableId);

      if (activeGameConnection && activeGameConnection.messageHandler) {
        let activeGameTable = state.games[tempTableId];
        if (activeGameTable) {
          const { tableId } = activeGameTable;
          activeGameConnection.gameClientListener.requestAutoCheckFoldAnyAction(
            {
              playerName: playerName,
              tableId: tableId,
              gameId: activeGameTable.gameId,
              autoActionEnable: autoActionEnable,
            }
          );
        } else {
          showNoTableAvailableWarning(action);
        }
      }
    },
    toggleAutoCheckAction: (state, action) => {
      const { tempTableId, playerName, autoActionEnable } = action.payload;
      let gameConnections = GameConnections.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(tempTableId);

      if (activeGameConnection && activeGameConnection.messageHandler) {
        let activeGameTable = state.games[tempTableId];
        if (activeGameTable) {
          const { tableId } = activeGameTable;
          activeGameConnection.gameClientListener.requestAutoCheckAction({
            playerName: playerName,
            tableId: tableId,
            gameId: activeGameTable.gameId,
            autoActionEnable: autoActionEnable,
          });
        } else {
          showNoTableAvailableWarning(action);
        }
      }
    },

    toggleAutoFoldAction: (state, action) => {
      const { tempTableId, playerName, autoActionEnable } = action.payload;
      let gameConnections = GameConnections.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(tempTableId);

      if (activeGameConnection && activeGameConnection.messageHandler) {
        let activeGameTable = state.games[tempTableId];
        if (activeGameTable) {
          const { tableId } = activeGameTable;
          activeGameConnection.gameClientListener.requestAutoFoldAction({
            playerName: playerName,
            tableId: tableId,
            gameId: activeGameTable.gameId,
            autoActionEnable: autoActionEnable,
          });
        } else {
          showNoTableAvailableWarning(action);
        }
      }
    },

    toggleSitOutAction: (state, action) => {
      let tempTableId = state.activeGame;
      let type = action.payload?.type;
      let activeGameTable = state.games[tempTableId];

      if (activeGameTable) {
        let { tableId, gameId } = activeGameTable;
        let playerName = state.userDetails.userName;
        let sitOutState = activeGameTable.sitOutOption;

        let dataObject = {
          playerName,
          tableId,
          gameId,
        };

        if (activeGameTable) {
          if (type === "sitOutCancel") {
            sendCancelSitOutProtocol(tempTableId, dataObject);
          } else {
            if (sitOutState) {
              sendSitInProtocol(tempTableId, dataObject);
            } else {
              sendSitOutProtocol(tempTableId, dataObject);
            }
          }
        }
        state.games[tempTableId].sitOutButtonDisabled = true;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateReleaseToFoldStatus: (state, action) => {
      const { tempTableId, status } = action.payload;
      let game = state.games[tempTableId];
      if (game) {
        game.showReleaseToFoldAlert = status;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    sitInAction: (state, action) => {
      let { tempTableId, data } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (data.success) {
          state.games[tempTableId].sitOutOption = false;
          state.games[tempTableId].playerSitOutTimer = false;
          state.games[tempTableId].isWaitingForBigBlind = false;
          let playerData = state.games[tempTableId].playerData.find(
            (player) => player.userName === data.playerName
          );
          let isGameStarted = state.games[tempTableId].isGameStarted;
          playerData.sitOutOption = false;
          playerData.isActive = isGameStarted ? false : true;
        }

        state.games[tempTableId].sitOutButtonDisabled = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    sitOutAction: (state, action) => {
      let sitOutResult = action.payload.data;
      let tempTableId = action.payload.tempTableId;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (sitOutResult.success) {
          state.games[tempTableId].sitOutOption = true;
        }

        if (sitOutResult.timeout) {
          state.games[tempTableId].playerSitOutTimer = sitOutResult.timeout;
          state.games[tempTableId].showDefaultActions = false;
          state.games[tempTableId].showAutoActions = false;
        } else {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_INFO,
            message: "You will sit out next hand.",
          });
        }
        state.games[tempTableId].sitOutButtonDisabled = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    sitOutBroadcastAction: (state, action) => {
      let sitOutResult = action.payload.data;
      let tempTableId = action.payload.tempTableId;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (sitOutResult.success) {
          let playerObject = state.games[tempTableId].playerData.find(
            (player) => player.userName === sitOutResult.playerName
          );
          playerObject.sitOutOption = true;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    sitInBroadcastAction: (state, action) => {
      let sitOutResult = action.payload.data;
      let tempTableId = action.payload.tempTableId;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (sitOutResult.success) {
          let playerObject = state.games[tempTableId].playerData.find(
            (player) => player.userName === sitOutResult.playerName
          );
          playerObject.sitOutOption = false;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    sitOutCancelAction: (state, action) => {
      let sitOutCancelResult = action.payload.data;
      let tempTableId = action.payload.tempTableId;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (sitOutCancelResult.success) {
          state.games[tempTableId].sitOutOption = false;
          state.games[tempTableId].playerSitOutTimer = false;
        }
        state.games[tempTableId].sitOutButtonDisabled = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    sitInFailAction: (state, action) => {
      let { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].sitOutButtonDisabled = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    sitOutFailAction: (state, action) => {
      let { tempTableId, statusCode } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].toastMessages.push({
          type: TOAST_ERROR,
          message: "Failed to sit out, please try again in sometime.",
        });
        state.games[tempTableId].sitOutButtonDisabled = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    resizingTable: (state) => {
      state.resizingStatus = false;
    },

    setResizingToComplete: (state) => {
      state.resizingStatus = true;
    },

    updatePositionsArranged: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].positionsArranged = true;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    hidePlayerPositions: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].positionsArranged = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processPlayersDataOnTable: (state, action) => {
      const {
        gameId,
        playersData,
        tempTableId,
        happyHoursEnabled,
        happyHoursRake,
        happyHoursHeadsUpRake,
      } = action.payload;
      let tableData = state.games[tempTableId];

      // state.games[tempTableId].gameDefinition.happyHoursEnabled =
      //   happyHoursEnabled;
      // state.games[tempTableId].gameDefinition.happyHoursRake = happyHoursRake;
      // state.games[tempTableId].gameDefinition.happyHoursHeadsUpRake =
      //   happyHoursHeadsUpRake;

      if (tableData) {
        let playersOnTable = [];
        let currentPlayerSeat = state.games[tempTableId].playerSeat;
        let loggedInPlayerDetails = state.userDetails;
        let oldPlayerStatsData = {};

        state.games[tempTableId].playerData.map((player, index) => {
          let dataObject = {
            enabledPlayerStats: player?.enabledPlayerStats
              ? player?.enabledPlayerStats
              : false,
            colorCode: player?.colorCode ? player?.colorCode : null,
            playerNotes: player?.playerNotes ? player?.playerNotes : null,
            playerName: player?.userName,
          };
          oldPlayerStatsData[player.userName] = dataObject;
        });

        playersData.forEach((player) => {
          playersOnTable.push({
            position: player.position,
            userName: player.playerName,
            atTableAmount: player.atTableamt,
            acelevel: player.acelevel,
            avatar: player.profilePic,
            isReservedSeat: false,
            isTakenSeat: true,
            isActive: true,
            isFolded: false,
            isWinner: false,
            allIn: false,
            muckCards: false,
            sitOutOption: player.gamePlayerStatus === SEATED_OUT ? true : false,
            isWaitingForBigBlind: player.isWaitingForBigBlind,
            enabledPlayerStats: oldPlayerStatsData[player.playerName]
              ?.enabledPlayerStats
              ? oldPlayerStatsData[player.playerName].enabledPlayerStats
              : player?.statsEnabled ?? false,
            colorCode: oldPlayerStatsData[player.playerName]?.colorCode
              ? oldPlayerStatsData[player.playerName]?.colorCode
              : player?.colourCode ?? null,
            playerNotes: oldPlayerStatsData[player.playerName]?.playerNotes
              ? oldPlayerStatsData[player.playerName]?.playerNotes
              : player?.playerNotes ?? "",
          });
          if (player.playerName === loggedInPlayerDetails.userName) {
            currentPlayerSeat = player.position;
            state.games[tempTableId].sitOutButtonDisabled = false;
            state.games[tempTableId].openBuyInModal = false;
            state.games[tempTableId].setBuyInValueForPlayerPosition = null;
            state.games[tempTableId].processingDReserve = false;
            state.games[tempTableId].processingReserve = {
              status: false,
              position: undefined,
            };
          }
        });
        state.games[tempTableId].playerSeat = currentPlayerSeat;
        state.games[tempTableId].playerSeatBeforeLeaveSeat = currentPlayerSeat;
        state.games[tempTableId].playerData = [...playersOnTable];
        state.games[tempTableId].gameId = gameId;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processPlayersDataOnTableForAlreadyRunningGame: (state, action) => {
      const {
        playersData,
        tempTableId,
        gameStarted,
        gameStage,
        communityCards,
        gameId,
        userCards,
        happyHoursEnabled,
        happyHoursRake,
        happyHoursHeadsUpRake,
      } = action.payload;
      let tableData = state.games[tempTableId];
      state.games[tempTableId].happyHoursEnabled = happyHoursEnabled;
      state.games[tempTableId].gameDefinition.happyHoursRake = happyHoursRake;
      state.games[tempTableId].gameDefinition.happyHoursHeadsUpRake =
        happyHoursHeadsUpRake;

      if (tableData) {
        let currentPlayerSeat = state.games[tempTableId].playerSeat;
        let communityCardPositon =
          state.games[tempTableId].communityCardPositon;
        let loggedInPlayerDetails = state.userDetails;

        let oldPlayerStatsData = {};

        state.games[tempTableId].playerData.map((player, index) => {
          let dataObject = {
            enabledPlayerStats: player?.enabledPlayerStats
              ? player?.enabledPlayerStats
              : false,
            colorCode: player?.colorCode ? player?.colorCode : null,
            playerNotes: player?.playerNotes ? player?.playerNotes : null,
            playerName: player?.userName,
          };
          oldPlayerStatsData[player.userName] = dataObject;
        });

        let playersOnTable = [];
        playersData.forEach((player) => {
          const {
            atTableamt,
            isActivePlayer,
            isAllinPlayer,
            isBigBlind,
            isDealer,
            isFoldPlayer,
            isSitoutPlayer,
            isSmallBlind,
            isForcedBlind,
            playerName,
            userAcelevel,
            userProfilePic,
            isWaitingForBigBlind,
            gamePlayerStatus,
            latestPlayerAction,
            statsEnabled,
            colourCode,
            playerNotes,
          } = player.playerInfo;
          playersOnTable.push({
            position: player.position,
            userName: playerName,
            atTableAmount: atTableamt,
            acelevel: userAcelevel,
            avatar: userProfilePic,
            isReservedSeat: player.isReserved,
            isTakenSeat: true,
            isActive: isActivePlayer,
            isFolded: isFoldPlayer,
            isWinner: false,
            allIn: isAllinPlayer,
            isBigBlind: isBigBlind,
            isSmallBlind: isSmallBlind,
            isForcedBlind: isForcedBlind,
            isDealer: isDealer,
            isSitoutPlayer: isSitoutPlayer,
            showBlindIcon: true,
            sitOutOption: gamePlayerStatus === SEATED_OUT ? true : false,
            isWaitingForBigBlind: isWaitingForBigBlind,
            muckCards: false,
            cards:
              playerName === loggedInPlayerDetails.userName ? userCards : [],
            lastAction: isFoldPlayer
              ? FOLD_ACTION
              : latestPlayerAction
              ? latestPlayerAction?.action.toLowerCase() === AUTO_FOLD
                ? FOLD_ACTION
                : latestPlayerAction?.action.toLowerCase() === AUTO_CHECK
                ? CHECK_ACTION
                : latestPlayerAction.action
              : "",
            allIn: isAllinPlayer ? isAllinPlayer : false,
            winningAmount: 0,
            betAmount: latestPlayerAction
              ? parseDecimalValuesOfNumber(latestPlayerAction.amount)
              : 0,
            enabledPlayerStats: oldPlayerStatsData[player.playerName]
              ?.enabledPlayerStats
              ? oldPlayerStatsData[player.playerName].enabledPlayerStats
              : statsEnabled,
            colorCode: oldPlayerStatsData[player.playerName]?.colorCode
              ? oldPlayerStatsData[player.playerName]?.colorCode
              : colourCode,
            playerNotes: oldPlayerStatsData[player.playerName]?.playerNotes
              ? oldPlayerStatsData[player.playerName]?.playerNotes
              : playerNotes,
          });
          if (playerName === loggedInPlayerDetails.userName) {
            currentPlayerSeat = player.position;
          }
        });
        state.games[tempTableId].playerSeat = currentPlayerSeat;
        state.games[tempTableId].playerSeatBeforeLeaveSeat = currentPlayerSeat;
        state.games[tempTableId].setBuyInValueForPlayerPosition =
          currentPlayerSeat;
        state.games[tempTableId].playerData = [...playersOnTable];
        state.games[tempTableId].isGameStarted = gameStarted;
        state.games[tempTableId].gameStage = gameStage;
        state.games[tempTableId].communityCards = [...communityCards];
        if (communityCards.length > 0) {
          state.games[tempTableId].doCommunityCardsExist = true;
        }
        state.games[tempTableId].communityCardPositon = [
          ...communityCardPositon,
        ];
        state.games[tempTableId].gameId = gameId;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processLeaveSeatAck: (state, action) => {
      const { tableId, playerName, tempTableId, ackStatus } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        let currentPlayerData = state.games[tempTableId].playerData.find(
          (player) => player.position === state.games[tempTableId].playerSeat
        );
        if (
          state.games[tempTableId].isGameStarted &&
          currentPlayerData.isActive &&
          ackStatus !== LEAVE_SEAT_ACK_SUCCESS
        ) {
          let message = "";
          if (ackStatus === LEAVE_SEAT_ACK_PROCESSING) {
            message = "You will be removed at the end of this hand";
          } else if (ackStatus === LEAVE_SEAT_ACK_REQUEST_ALREADY_SENT) {
            message = "Leave seat request already sent";
          }
          state.games[tempTableId].toastMessages.push({
            type: TOAST_INFO,
            message: message,
          });
        } else if (
          state.games[tempTableId] &&
          ackStatus === LEAVE_SEAT_ACK_SUCCESS
        ) {
          state.games[tempTableId].playerData = state.games[
            tempTableId
          ].playerData.filter((player) => player.userName !== playerName);
          state.games[tempTableId].playerCards = [];
          state.games[tempTableId].playerSeat = undefined;
          state.games[tempTableId].setBuyInValueForPlayerPosition = undefined;
          state.games[tempTableId].playerPosition = [];
          state.games[tempTableId].playerMovedToCenter = false;
          state.games[tempTableId].playerTimer = undefined;
          state.games[tempTableId].playerSitOutTimer = false;
          state.games[tempTableId].sitOutOption = false;
          state.games[tempTableId].playerStatus = IN_ACTIVE;
          state.games[tempTableId].topUpPending = false;
          state.games[tempTableId].watchPlayer = true;
        }

        state.games[tempTableId].isWaitingForBigBlind = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    leaveSeatBroadcastAction: (state, action) => {
      const { playerName, tempTableId } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        let playerData = tableData.playerData.filter(
          (player) => player.userName !== playerName
        );
        tableData.playerData = playerData;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processLeaveSeatAckFromSitOut: (state, action) => {
      const { playerName, tempTableId } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].playerSeatBeforeLeaveSeat =
          state.games[tempTableId].playerSeat;

        tableData.playerData = tableData.playerData.filter(
          (player) => player.userName !== playerName
        );
        tableData.playerSeat = undefined;
        tableData.setBuyInValueForPlayerPosition = undefined;
        tableData.playerPosition = [];
        tableData.playerTimer = undefined;
        tableData.playerSitOutTimer = false;
        tableData.sitOutOption = false;
        tableData.playerStatus = IN_ACTIVE;
        tableData.isWaitingForBigBlind = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleJoinFailureAction: (state, action) => {
      let { tempTableId, statusCode, position, lowBalanceData } =
        action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        if (statusCode === 222) {
          state.games[tempTableId].gameModal.type = state.games[tempTableId]
            .isFunGame
            ? INSUFFICIENT_FUN_CHIPS_BALANCE
            : INSUFFICIENT_BALANCE;
          state.games[tempTableId].gameModal.visible = true;

          if (lowBalanceData && Object.keys(lowBalanceData).length > 0) {
            const gameDefinition = state.games[tempTableId].gameDefinition;
            state.games[tempTableId].gameModal.config = {
              amountToAdd: lowBalanceData?.amount_to_add,
              totalBalance: lowBalanceData?.total_balance,
              depositAndWinnings: lowBalanceData?.deposit_and_winning,
              bonus: lowBalanceData?.bonus,
              usableBonus: lowBalanceData?.usable_bonus,
              buyInAmount: lowBalanceData?.buyInAmount,
              isTourneyGame: state.games[tempTableId].isTourneyGame,
              blindsText: gameDefinition
                ? `(${returnGameTypeText(gameDefinition?.gameType)} ${
                    gameDefinition?.smallBlind
                  }/${gameDefinition?.bigBlind})`
                : "",
            };
          }

          state.games[tempTableId].toastMessages.push({
            type: TOAST_ERROR,
            message: state.games[tempTableId].isFunGame
              ? "You have insufficient funds. Please add chips to start playing."
              : "You have insufficient funds. Please add cash to start playing.",
          });
        } else if (statusCode === 204) {
          //ACCOUNT_ISSUE_GAME_JOIN
          state.games[tempTableId].gameModal.visible = true;
          state.games[tempTableId].gameModal.type = ACCOUNT_ISSUE_GAME_JOIN;
        } else if (statusCode === 205) {
          //WALLET_BALANCE_ISSUE_GAME_JOIN
          state.games[tempTableId].gameModal.visible = true;
          state.games[tempTableId].gameModal.type =
            WALLET_BALANCE_ISSUE_GAME_JOIN;
        } else if (statusCode === 206) {
          //DATA_LOST_ISSUE_GAME_JOIN
          state.games[tempTableId].gameModal.visible = true;
          state.games[tempTableId].gameModal.type = DATA_LOST_ISSUE_GAME_JOIN;
        } else if (statusCode === 207) {
          //JOIN_FAILURE_ACCOUNT_DELETED
          state.games[tempTableId].gameModal.visible = true;
          state.games[tempTableId].gameModal.type =
            JOIN_FAILURE_ACCOUNT_DELETED;
        } else if (statusCode === 111) {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_ERROR,
            message: "Your seat reservation time has ended, please try again.",
          });
        } else if (statusCode === 101) {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_ERROR,
            message: "Next game has started, please try again.",
          });
        } else {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_ERROR,
            message: "Oops! Something went wrong. Please try again later.",
          });
        }

        state.games[tempTableId].processingReserve = {
          status: false,
          position: position,
        };
        state.games[tempTableId].joinFailureFlag = true;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processLeaveSeatFailure: (state, action) => {
      let tempTableId = action.payload.tempTableId;
      let tableData = state.games[tempTableId];

      if (tableData) {
        tableData.toastMessages.push({
          type: TOAST_ERROR,
          message: "Cannot Leave Seat",
        });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    createTableData: (state, action) => {
      processCreateTableData(state, action);
    },

    resetTableData: (state, action) => {
      let processedState = processResetTableData(current(state), action);
      return processedState;
    },

    updateGameConnectionStatusN: (state, action) => {
      const { tempTableId, status } = action.payload;

      let tableData = state.games[tempTableId];
      if (tableData) {
        tableData.gameConnectionStatus = status;

        if (status === GAME_CONNECTION_PROTOCOL_SUCCESS) {
          tableData.isConnectionAckReceivedOnce = true;
        }

        tableData.gameAPIState =
          status === CHECK_GAME_API_READY_TO_REQUEST
            ? CHECK_GAME_API
            : status === INITIATE_TOURNEY_GAME_RECONNECTION
            ? TOURNEY_GAME_RECONNECTION_STATE
            : tableData.gameAPIState;

        if (tableData.isTourneyGame) {
          tableData.isTourneyNodeSwitch = false;
          tableData.isReset = false;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateGameConnectionFailureProtocolStatus: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].gameAPICurrentStatus =
          GAME_JOIN_API_RESPONSE_FAILURE;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updatePlayerDetails: (state, action) => {
      //assigning lobby player object for reference
      const { lobbyPlayerData } = action.payload;
      state.userDetails = lobbyPlayerData;
    },

    handleReloginFailure: (state, action) => {
      const { tempTableId } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        tableData.openInSufficientFundsModal = false;
        tableData.openTopupModal = false;
        tableData.openBuyInModal = false;
        tableData.openRebuyInModal = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    startGameListenerN: (state, action) => {
      const { tempTableId, dispatchFunction } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        let gameConnections = GameConnectionsN.getInstance();

        let connection = gameConnections.getGameConnection(tempTableId);
        if (!connection) {
          let gameListenerN = new GameListenerN(dispatchFunction);
          gameListenerN.setTempTableId(tempTableId);
          gameListenerN.setIsTourneyGame(tableData.isTourneyGame);

          connection = {
            tempTableId: tempTableId,
            listener: gameListenerN,
          };
          gameConnections.addGameConnection(connection);
        } else {
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processMatchMakingResponse: (state, action) => {
      const {
        tempTableId,
        connectionStatus,
        tableId,
        gameId,
        hostname,
        port,
        connectionType,
        awsalbcookie,
        gameEndpoint,
      } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        tableData.gameConnectionStatus = connectionStatus;
        tableData.tableId = tableId;
        tableData.gameId = gameId;
        tableData.serverAddress = hostname;
        tableData.serverPort = port;
        tableData.connectionType = connectionType;
        tableData.awsalbcookie = awsalbcookie;
        tableData.gameEndpoint = gameEndpoint;
      } else {
        showNoTableAvailableWarning();
      }
    },

    processCheckGameResponse: (state, action) => {
      const {
        tempTableId,
        connectionStatus,
        tableId,
        gameId,
        hostname,
        port,
        connectionType,
        awsalbcookie,
        gameEndpoint,
      } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        tableData.gameConnectionStatus = connectionStatus;
        tableData.tableId = tableId;
        tableData.gameId = gameId;
        tableData.serverAddress = hostname;
        tableData.serverPort = port;
        tableData.connectionType = connectionType;
        tableData.awsalbcookie = awsalbcookie;
        tableData.gameEndpoint = gameEndpoint;
      } else {
        showNoTableAvailableWarning();
      }
    },

    setToastMessage: (state, action) => {
      const { tempTableId, type, message } = action.payload;
      let tableData = state.games[tempTableId];
      if (tableData) {
        tableData.toastMessages.push({ type: type, message: message });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    removeToastMessage: (state, action) => {
      const { tempTableId, message } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        let index = tableData.toastMessages.indexOf(message);
        tableData.toastMessages.splice(index, 1);
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    setCardDistributionAnimationCompleteFlag: (state, action) => {
      const { tempTableId } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        tableData.disableOrientationChange = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handlePrizePotAmount: (state, action) => {
      const { tempTableId, potAmountDetails } = action.payload;
      const {
        atTableAmounts,
        userStageInvestment,
        totalPot,
        gameStage,
        multiPotsPojo,
        betAnimation,
      } = potAmountDetails;

      let playersWhoInvestedInPot = Object.keys(userStageInvestment);

      let tableData = state.games[tempTableId];

      if (tableData) {
        tableData.totalPotAmt = parseDecimalValuesOfNumber(totalPot);
        tableData.gameStage = gameStage;
        tableData.playerData.map((player) => {
          if (playersWhoInvestedInPot.includes(player.userName)) {
            player.betAmount = parseDecimalValuesOfNumber(
              userStageInvestment[player.userName]
            );
          }
        });

        // Using bet animation flag to increase multiPotAmounts array size as it indicates whether betting is over for the round or not
        if (betAnimation) {
          // updating at table amount in case of refund.
          let updatedPlayer = tableData.playerData.map((player) => {
            player.atTableAmount = atTableAmounts[player.userName];
            if (userStageInvestment[player.userName]) {
              player.betAmount = parseDecimalValuesOfNumber(
                userStageInvestment[player.userName]
              );
            }
            return player;
          });

          tableData.multiPotAmounts = multiPotsPojo;

          // multiPotsPojo.map((item, index) => {
          //   tableData.multiPotAmounts[index] = item;
          // });

          tableData.playerData = updatedPlayer;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateInSufficientFundsModalOpenStatus: (state, action) => {
      const { tempTableId, isOpen } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].openInSufficientFundsModal = isOpen;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateWaitingForBB: (state, action) => {
      let { isWaitingForBigBlind, tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].isWaitingForBigBlind = isWaitingForBigBlind;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRankDetails: (state, action) => {
      const { tempTableId, data } = action.payload;
      const { rankDetails } = data;

      let tableData = state.games[tempTableId];

      if (tableData) {
        if (tableData?.gameStage === GAME_STAGE_SHOWDOWN) {
          return;
        }
        let mySeat = tableData.playerSeat;
        tableData.playerData.map((player) => {
          if (player.position === mySeat) {
            player.possibleWinningHandType = getHandType(rankDetails.handType);
            player.possibleWinningHandCards =
              rankDetails.winningHand.split(",");
            return player;
          }
        });
        tableData.showRankDetails = true;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateHandHistory: (state, action) => {
      let { details } = action.payload;

      let currentHandHistory = state.handHistory;
      if (details?.potAmount) {
        details.potAmount = parseDecimalValuesOfNumber(
          details?.potAmount,
          2,
          checkToDisplayValueInK(details?.potAmount),
          checkToDisplayValueInL(details?.potAmount)
        );
      }
      // Saving a different parameter called activeHandIndex to know which hand we are viewing.
      currentHandHistory.activeHandIndex =
        currentHandHistory.activeHandIndex !== undefined &&
        currentHandHistory.activeHandIndex !== null
          ? currentHandHistory.activeHandIndex
          : details.gameIds.length > 1
          ? details.gameIds.length - 1
          : 0;
      // Details contain all hand history data along with different game Ids.
      currentHandHistory.gameIds = details.gameIds;
      currentHandHistory.details = details;
      state.handHistoryLoading = false;
    },

    setHandHistoryLoading: (state) => {
      state.handHistoryLoading = true;
    },

    updateTableStats: (state, action) => {
      let { details, tempTableId } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tableStats = details;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    openHandHistoryRequest: (state) => {
      let dataObject = {
        playerName: state.userDetails.userName,
        tableId: state.games[state.activeGame].tableId,
      };
      let gameConnection = GameConnections.getInstance().getGameConnection(
        state.activeGame
      );
      gameConnection.gameClientListener.processHandHistoryRequest(dataObject);
    },

    clearHandHistoryIndex: (state) => {
      state.handHistory.activeHandIndex = undefined;
      state.handHistoryLoading = true;
    },

    requestHandHistory: (state, action) => {
      let { gameId, index } = action.payload;
      let tableData = state.games[state.activeGame];

      if (tableData) {
        let dataObject = {
          playerName: state.userDetails.userName,
          tableId: state.games[state.activeGame]?.tableId,
          gameId,
        };
        if (state.games[state.activeGame]?.isTourneyGame) {
          dataObject["tourneyId"] =
            state.games[state.activeGame]?.gameDefinition.tourneyId;
        }

        let currentHandHistory = state.handHistory;
        currentHandHistory.activeHandIndex = index;
        currentHandHistory.details = {};

        let gameConnection = GameConnectionsN.getInstance().getGameConnection(
          state.activeGame
        );
        gameConnection.listener.processHandHistoryRequest(dataObject);
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateGameApplicationVisibility: (state, action) => {
      let { status } = action.payload;

      state.applicationVisibility = status;
    },

    syncUpTableTimers: (state, action) => {
      const { playerName, position, time, type } = action.payload.data;
      const { tempTableId } = action.payload;

      let gameObject = state.games[tempTableId];

      if (gameObject) {
        let positionOfPlayerTurn = gameObject.playerTurn;
        let playerObject = gameObject.playerData.find(
          (player) => player.position === position
        );
        let activePlayerObjectOnClientSide = gameObject.playerData.find(
          (player) => player.position === gameObject.playerTurn
        );
        if (activePlayerObjectOnClientSide && playerName) {
          if (playerName !== activePlayerObjectOnClientSide?.userName) {
            let message = {
              type: "Active player mismatch in heart beat",
              data: {
                clientActiveUser: activePlayerObjectOnClientSide.userName,
                serverActiveUser: playerName,
              },
            };

            let gameConnection =
              GameConnectionsN.getInstance().getGameConnection(tempTableId);
            gameConnection.listener
              .getCommunicationChannel()
              .sendMessage(`DB#${JSON.stringify(message)}`);
            sendFaroLog(message);
          }
        }
        if (
          type === "T#" &&
          position === positionOfPlayerTurn &&
          Math.abs(playerObject.remainingTime - time) > 2
        ) {
          playerObject.tableTimer = time - 1;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleCleanTable: (state, action) => {
      const { tempTableId, cleanGameId, status, tourneyGameAndCheckExitTable } =
        action.payload;
      // Remove the game connection if status is 800. Since game is under maintenance.
      processCleanTable(
        state,
        tempTableId,
        cleanGameId,
        tourneyGameAndCheckExitTable
      );
      // Need to filter out this game from autoTableSwitching queue.
      state.autoTableSwitchQueue = state.autoTableSwitchQueue.filter(
        (id) => id !== tempTableId
      );
    },

    handleNextGameTimer: (state, action) => {
      const { tempTableId } = action.payload;
      let gameTable = state.games[tempTableId];

      if (gameTable) {
        if (
          gameTable.playerSeat !== undefined &&
          gameTable.playerSeat !== null &&
          !gameTable.isTourneyGame
        ) {
          gameTable.restrictUserFromLeavingTable = true;
        }
        if (gameTable.isTourneyGame) {
          gameTable.restrictUserFromLeavingTable = !gameTable.watchPlayer;
        }
        state.games[tempTableId] = gameTable;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    checkPlayerIsAvailableInSeats: (state, action) => {
      const { tempTableId, playersData } = action.payload;
      let isPlayerPartOfGame = false;
      let loggedInPlayerDetails = state.userDetails;
      let tableData = state.games[tempTableId];

      if (tableData) {
        playersData.map((eachPlayer) => {
          if (eachPlayer.playerName === loggedInPlayerDetails.userName) {
            isPlayerPartOfGame = true;
            // Fix for IM-1195
            state.games[tempTableId].playerMovedToCenter = true;
          }
        });

        if (!isPlayerPartOfGame) {
          state.games[tempTableId].playerCards = [];
          state.games[tempTableId].playerSeat = undefined;
          state.games[tempTableId].setBuyInValueForPlayerPosition = state.games[
            tempTableId
          ].playerWaitingPosition
            ? state.games[tempTableId].setBuyInValueForPlayerPosition
            : undefined;
          state.games[tempTableId].playerPosition = [];
          state.games[tempTableId].playerTimer = undefined;
          state.games[tempTableId].playerSitOutTimer = false;
          state.games[tempTableId].sitOutOption = false;
          state.games[tempTableId].playerStatus = IN_ACTIVE;
          state.games[tempTableId].playerTurn = false;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleCloseFailure: (state, action) => {
      const { tempTableId } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        tableData.toastMessages.push({
          type: TOAST_ERROR,
          message: "Failure: Cannot exit table",
        });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateAmountToAdd: (state, action) => {
      let { tempTableId, amountToAdd } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].amountToAdd = amountToAdd;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    disableMultitableSwipe: (state) => {
      state.canUserSwipe = false;
    },

    enableMultitableSwipe: (state) => {
      state.canUserSwipe = true;
    },

    processRabbitHuntingDetails: (state, action) => {
      const { tempTableId, cards } = action.payload;

      let tableData = state.games[tempTableId];

      if (tableData) {
        let gameStage = tableData.gameStage;
        let rabbitHuntingCardsArray = [];
        for (let card of cards) {
          let item = {
            source: card,
            rabbitHunting: true,
          };
          rabbitHuntingCardsArray.push(item);
        }
        tableData.communityCards = [
          ...tableData.communityCards,
          ...rabbitHuntingCardsArray,
        ];
        tableData.disableOrientationChange = true;
        state.games[tempTableId].doCommunityCardsExist = true;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    openLocalGameModal: (state, action) => {
      const { tempTableId, type, data } = action.payload;
      // console.log("Spruha log >>> opening modal ", type);
      //state = cloneDeep(current(state));
      if (
        state.games[tempTableId].gameModal.type === DISCONNECTION &&
        type !== RELOGIN_FAILURE
      ) {
        //return state;
        return;
      } else if (state.games[tempTableId]) {
        // console.log("Spruha log >>> opening modal ", type);
        state.games[tempTableId].gameModal.visible = true;
        state.games[tempTableId].gameModal.type = type;
        if (action.payload?.data) {
          state.games[tempTableId].gameModal.data = action.payload?.data;
        }
        if (action.payload.config) {
          state.games[tempTableId].gameModal.config = action.payload.config
            ? action.payload.config
            : null;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    closeLocalGameModal: (state, action) => {
      const { tempTableId } = action.payload;

      if (state.games[tempTableId]) {
        state.games[tempTableId].gameModal.visible = false;
        state.games[tempTableId].gameModal.type = null;
        state.games[tempTableId].gameModal.data = null;
        state.games[tempTableId].gameModal.config = null;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    closeInternetDisconnectionGameModal: (state, action) => {
      const { tempTableId } = action.payload;

      if (state.games[tempTableId]) {
        if (state.games[tempTableId].gameModal.type === DISCONNECTION) {
          state.games[tempTableId].gameModal.visible = false;
          state.games[tempTableId].gameModal.type = null;
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handlePlayerDisconnectionTime: (state, action) => {
      const { tempTableId, playerName, position, disconnectionTimeBank } =
        action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].playerData.map((player) => {
          if (player.position === position && player.userName === playerName) {
            player.tableTimer = disconnectionTimeBank;
            player.totalTime = disconnectionTimeBank;
            player.startTableTimer = true;
            player.activeTableTimerType = DISCONNECTION_TIMER;
            player.remainingTime = false;
          }
        });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyPrize: (state, action) => {
      const { tempTableId, tourneyEnded } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tourneyPrizeDetails = {
          ...action.payload,
        };
        state.games[tempTableId].gameModal.visible = true;
        state.games[tempTableId].gameModal.type = TOURNEY_GAME_WINNER_MODAL;
        state.games[tempTableId].restrictUserFromLeavingTable = true;
        state.games[tempTableId].tourneyGameCompleted = tourneyEnded;

        //NOTE: We are watedly adding watchplayer as true for active player as no channel is there, to avoid re-entry table creation issue at the time of processing lack tourney games.
        state.games[tempTableId].watchPlayer = true;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyEliminate: (state, action) => {
      const { tempTableId, tourneyEnded, rank } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].gameModal.visible = true;
        state.games[tempTableId].gameModal.type = GAME_LOOSER_MODAL;
        state.games[tempTableId].gameModal.config = { rank: rank };
        state.games[tempTableId].restrictUserFromLeavingTable = true;
        state.games[tempTableId].tourneyGameCompleted = tourneyEnded;

        //NOTE: We are watedly adding watchplayer as true for active player as no channel is there, to avoid re-entry table creation issue at the time of processing lack tourney games.
        state.games[tempTableId].watchPlayer = true;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handlersTourneyRebuyIn: (state, action) => {
      const { tempTableId, rebuyInDetails } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tourneyRebuyInDetails = rebuyInDetails;
        state.games[tempTableId].gameModal.visible = true;
        state.games[tempTableId].gameModal.type =
          TOURNEY_REBUYIN_WITH_DOUBLE_REBUY;
        state.games[tempTableId].isTourneyRebuyRequestLoading = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    submitTourneyRebuyRequest: (state, action) => {
      const { tempTableId, rebuyRequestPayload } = action.payload;

      const tableData = state.games[tempTableId];
      if (tableData) {
        const { tableId, tourneyId } = tableData;
        let gameConnection =
          GameConnectionsN.getInstance().getGameConnection(tempTableId);

        gameConnection.listener.processTourneyRebuyRequest({
          tourneyId: tourneyId,
          tableId: tableId,
          ...rebuyRequestPayload,
        });

        state.games[tempTableId].isTourneyRebuyRequestLoading = true;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    processTourneyRebuyCancle: (state, action) => {
      const { tempTableId, channel, playerName } = action.payload;

      const tableData = state.games[tempTableId];
      if (tableData) {
        const { tableId, tourneyId } = tableData;
        let gameConnection =
          GameConnectionsN.getInstance().getGameConnection(tempTableId);

        gameConnection.listener.processTourneyRebuyCancle({
          tourneyId: tourneyId,
          tableId: tableId,
          channel: channel,
          playerName: playerName,
        });

        state.games[tempTableId].isTourneyRebuyRequestLoading = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyRebuyAck: (state, action) => {
      const { tempTableId, buyInMessage } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].isTourneyRebuyRequestLoading = false;
        state.games[tempTableId].gameModal.visible = false;
        state.games[tempTableId].gameModal.type = null;
        state.games[tempTableId].gameModal.config = null;
        state.games[tempTableId].toastMessages.push({
          type: TOAST_SUCCESS,
          message: buyInMessage,
        });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyRebuyCancle: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].isTourneyRebuyRequestLoading = false;
        state.games[tempTableId].gameModal.visible = false;
        state.games[tempTableId].gameModal.type = null;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyRebuyFailure: (state, action) => {
      const { tempTableId, buyInMessage } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].isTourneyRebuyRequestLoading = false;
        state.games[tempTableId].gameModal.visible = false;
        state.games[tempTableId].gameModal.type = null;
        state.games[tempTableId].toastMessages.push({
          type: TOAST_ERROR,
          message: buyInMessage,
        });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyRebuyBroadcast: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].activeTourneyBannerType =
          TOURNEY_BANNER_WAITING_FOR_REBUYS;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyRebuyEnded: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (
        tableData &&
        tableData?.activeTourneyBannerType === TOURNEY_REBUYIN_WITH_DOUBLE_REBUY
      ) {
        state.games[tempTableId].activeTourneyBannerType = null;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyWaitingForMerging: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].activeTourneyBannerType =
          TOURNEY_BANNER_MERGE_WAIT_MESSAGE;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyNodeSwitch: (state, action) => {
      const { tempTableId, nodeIp, gameId, tableId, awsalbcookie } =
        action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].activeTourneyBannerType =
          TOURNEY_BANNER_NODE_SWITCH;

        const { hostname, port, protocol } = new URL(nodeIp);

        state.games[tempTableId].serverAddress = hostname;
        state.games[tempTableId].serverPort = port;
        state.games[tempTableId].connectionType = protocol.replace(":", "");
        state.games[tempTableId].gameId = gameId;
        state.games[tempTableId].tableId = tableId;
        state.games[tempTableId].awsalbcookie = awsalbcookie;
        state.games[tempTableId].isTourneyNodeSwitch = true;

        //added but commented by @yashwanth - need to confirm with @ganesh
        // state.games[tempTableId].gameAPIState = TOURNEY_GAME_CONNECTION_STATEMATCH_MAKING_API;
        // state.games[tempTableId].gameConnectionStatus = INITIATE_TOURNEY_GAME_CONNECTION;

        let gameConnection =
          GameConnectionsN.getInstance().getGameConnection(tempTableId);
        gameConnection.listener.setGameId(gameId);
        gameConnection.listener.setTableId(tableId);
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRequestTourneyRankingLeaderboardDetails: (state, action) => {
      const { tempTableId, playerName } = action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        const { tableId, gameDefinition } = tableData;
        let gameConnection =
          GameConnectionsN.getInstance().getGameConnection(tempTableId);

        gameConnection.listener.requestTourneyRankingLeaderboard({
          tourneyId: gameDefinition.tourneyId,
          tableId: tableId,
          playerName: playerName,
        });

        state.games[
          tempTableId
        ].isTourneyRankingLeaderboardRequestInProgress = true;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyRankingLeaderBoard: (state, action) => {
      const { tempTableId, rankingLeaderboardData, playerName } =
        action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tourneyRankingLeaderboardDetails = {
          ...rankingLeaderboardData,
        };

        let rankDataList =
          state.games[tempTableId].tourneyRankingLeaderboardDetails
            .rankDataList;
        if (rankDataList && rankDataList?.length > 0) {
          let index = rankDataList.findIndex(
            (item) => item["playerName"] === playerName
          );
          if (index > 0) {
            //to rearrange ranks order only if the player name is greater than index 0
            let currentUserRankDetails = rankDataList[index];
            rankDataList.splice(index, 1);

            let updatedRanksDataList = rankDataList;
            let reorderedRanksData = [
              currentUserRankDetails,
              ...updatedRanksDataList,
            ];
            state.games[
              tempTableId
            ].tourneyRankingLeaderboardDetails.rankDataList =
              reorderedRanksData;
          }
        }

        state.games[
          tempTableId
        ].isTourneyRankingLeaderboardRequestInProgress = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyPlayerRankDetails: (state, action) => {
      const { tempTableId, rankDetails } = action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tourneyPlayerRankDetails = {
          ...rankDetails,
        };
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleBlindsUpDetails: (state, action) => {
      const { tempTableId, blindsUpDetails } = action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tourneyBlindsUpDetails = {
          ...blindsUpDetails,
        };
        // Commented below code Temporarily - ante details to be added in refresh protocol too (same like in start protocol)
        // state.games[tempTableId].gameDefinition.smallBlind =
        //   blindsUpDetails.sb;
        // state.games[tempTableId].gameDefinition.bigBlind =
        //   blindsUpDetails.bb;
        // state.games[tempTableId].gameDefinition["level"] =
        //   blindsUpDetails.level;
        // state.games[tempTableId].gameDefinition["ante"] =
        //   blindsUpDetails.ante;

        state.games[tempTableId].activeTourneyBannerType =
          TOURNEY_BANNER_BLINDS_UP;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyAutoPlayAcknowledgement: (state, action) => {
      const { autoPlay, playerName, tourneyId, tempTableId } = action.payload;
      state.games[tempTableId].tourneyAutoPlay = autoPlay;
    },

    handleTourneyBreakBroadcast: (state, action) => {
      const { tempTableId, breakDetails } = action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tourneyGameBreakBroadcast = {
          ...breakDetails,
        };

        state.games[tempTableId].activeTourneyBannerType =
          breakDetails.endTime !== 0
            ? TOURNEY_BANNER_BREAK_TIME
            : TOURNEY_BANNER_BREAK_WAITING_FOR_OTHER_TABLES;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyBreakEnd: (state, action) => {
      const { tempTableId } = action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tourneyGameBreakBroadcast = null;
        state.games[tempTableId].activeTourneyBannerType = null;
        state.games[tempTableId].tourneyAddOnDetails = null;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyAddOn: (state, action) => {
      const { tempTableId, addOnDetails } = action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tourneyGameBreakBroadcast = {
          ...addOnDetails,
        };
        state.games[tempTableId].activeTourneyBannerType =
          addOnDetails.endTime !== 0
            ? TOURNEY_BANNER_BREAK_TIME
            : TOURNEY_BANNER_ADDON_BREAK_WAITING_FOR_OTHER_TABLES;

        if (addOnDetails?.addOnDetailsToClient) {
          state.games[tempTableId].tourneyAddOnDetails = {
            ...addOnDetails.addOnDetailsToClient,
          };
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyWatchPlayerAddOn: (state, action) => {
      const { tempTableId, endTime } = action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tourneyGameBreakBroadcast = {
          ...action.payload,
        };
        state.games[tempTableId].activeTourneyBannerType =
          endTime !== 0
            ? TOURNEY_BANNER_BREAK_TIME
            : TOURNEY_BANNER_BREAK_WAITING_FOR_OTHER_TABLES;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyPlayerInTheMoneyDetails: (state, action) => {
      const { tempTableId } = action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].activeTourneyBannerType =
          TOURNEY_BANNER_IN_WINNING_POSITIONS;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleOpenTourneyAddOnPopup: (state, action) => {
      const { tempTableId } = action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].gameModal.visible = true;
        state.games[tempTableId].gameModal.type = TOURNEY_ADDON;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    closeTourneyAddOnPopup: (state, action) => {
      const { tempTableId } = action.payload;
      const tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].gameModal.visible = false;
        state.games[tempTableId].gameModal.type = null;
        state.games[tempTableId].tourneyAddOnDetails = null;
        state.games[tempTableId].isTourneyAddOnRequestInProgress = false;

        const { tableId, gameDefinition } = tableData;
        let loggedInPlayerDetails = state.userDetails;

        let gameConnection =
          GameConnectionsN.getInstance().getGameConnection(tempTableId);

        gameConnection.listener.requestTourneyAddonCancle({
          tourneyId: gameDefinition.tourneyId,
          tableId: tableId,
          playerName: loggedInPlayerDetails.userName,
        });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    requestTourneyAddOnTopup: (state, action) => {
      const { playerName, userId, channel } = action.payload;
      const tableData = state.games[state.activeGame];

      if (tableData) {
        const { tourneyId, tableId } = tableData;

        let requestPayload = {
          tourneyId: tourneyId,
          tableId: tableId,
          playerName: playerName,
          userId: userId,
          channel: channel,
        };

        let gameConnection = GameConnectionsN.getInstance().getGameConnection(
          tableData.tempTableId
        );

        gameConnection.listener.processTourneyAddOnRequest({
          ...requestPayload,
        });
        state.games[state.activeGame].isTourneyAddOnRequestInProgress = true;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyAddonAck: (state, action) => {
      const { tempTableId, statusCode, addOnMessage } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (statusCode === 200) {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_SUCCESS,
            message: addOnMessage,
          });
        } else if (statusCode === 400) {
          state.games[tempTableId].toastMessages.push({
            type: TOAST_ERROR,
            message: addOnMessage,
          });
        }
        state.games[tempTableId].isTourneyAddOnRequestInProgress = false;
        state.games[tempTableId].tourneyAddOnDetails = null;

        state.games[tempTableId].gameModal.visible = false;
        state.games[tempTableId].gameModal.type = null;
        state.games[tempTableId].gameModal.data = null;
        state.games[tempTableId].gameModal.config = null;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyAddonFailureAck: (state, action) => {
      const { tempTableId, addOnMessage } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].toastMessages.push({
          type: TOAST_ERROR,
          message: addOnMessage,
        });
        state.games[tempTableId].isTourneyAddOnRequestInProgress = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyTableSwitching: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].activeTourneyBannerType =
          TOURNEY_BANNER_MERGED_WITH_ANOTHER_TABLE;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleTourneyNextLevelUp: (state, action) => {
      const { tempTableId, nextLevelDetails } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].tourneyNextLevelUpDetails = {
          ...nextLevelDetails,
        };
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    closeTourneyBanner: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].activeTourneyBannerType = null;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    checkForTableMergeBannerAndClose: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (
        tableData &&
        tableData.isTourneyGame &&
        tableData.activeTourneyBannerType ===
          TOURNEY_BANNER_MERGED_WITH_ANOTHER_TABLE
      ) {
        setTimeout(() => {
          state.games[tempTableId].activeTourneyBannerType = null;
        }, TOURNEY_TABLE_MERGE_BANNER_DELAY);
      } else if (!tableData) {
        showNoTableAvailableWarning(action);
      }
    },

    handleSitOutButtonOnDRebuyIn: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        // This is to enable the i'm back button on closing rebuy in modal
        state.games[tempTableId].openRebuyInModal = false;
        state.games[tempTableId].sitOutButtonDisabled = false;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateDisableOrientationChange: (state, action) => {
      const { tempTableId, isDisabled } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].disableOrientationChange = isDisabled;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updateUserRestrictionStatus: (state, action) => {
      const { tempTableId, status } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].restrictUserFromSitOut = status;
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    reportHandHistoryIssue: (state, action) => {
      const {
        gameId,
        tempTableId,
        fraudPlayers,
        issueType,
        channel,
        comments,
        accessToken,
        a23Token,
      } = action.payload;
      const { gameDefinition, isTourneyGame } = state.games[tempTableId];

      let temp = new Date();
      let date = new Date(temp.getTime() + 330 * 60 * 1000);
      date = date.toISOString().replace("T", ",");
      date = date.replace("Z", "");
      const requestPayload = {
        reported_fraud_players: fraudPlayers,
        game_name: isTourneyGame
          ? gameDefinition.tourneyName
          : gameDefinition.tableName,
        comments: comments,
        date_posted: date,
        issue_type: issueType,
        channel: channel,
        game_type: isTourneyGame ? "ip-poker-tourney" : "ip-poker-game", //gameDefinition.gameType, NOTE: changed this to poker-game as per live change.
        game_id: isTourneyGame
          ? `${gameId}#${gameDefinition?.tourneyId}`
          : gameId,
      };
      let gameConnection =
        GameConnectionsN.getInstance().getGameConnection(tempTableId);
      gameConnection.listener.submitReportIssueDetails({
        apiURL: process.env.REACT_APP_REPORT_ISSUE,
        apiPayload: requestPayload,
        accessToken: accessToken,
        a23Token: a23Token,
        tempTableId: tempTableId,
      });
    },

    handleReportIssueSuccess: (state, action) => {
      const { data, tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (data.status) {
          state.games[tempTableId].gameModal.visible = false;
          state.games[tempTableId].gameModal.type = null;
          state.games[tempTableId].toastMessages.push({
            type: TOAST_SUCCESS,
            message: data.message,
          });
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    updatePlayerSettingsConfig: (state, action) => {
      const {
        playerBasicSettings,
        playerAutoRebuyConfig,
        playerHaptics,
        bettingOptions,
      } = action.payload;
      const { autoRebuy, playerAutoTopUpConfig } = playerAutoRebuyConfig;

      console.log("TABLE SETTINGS PAYLOAD >>> ", action.payload);

      // Helper function to remap presets back to indexes
      const remapPresetsToIndexes = (presetValues) => {
        return Object.keys(presetValues).reduce((acc, key) => {
          const index = parseInt(key.replace("preset", "")) - 1; // Convert preset1 to 0, preset2 to 1, etc.
          acc[index] = presetValues[key];
          return acc;
        }, {});
      };

      state.tableSettings = {
        showHandStrength: playerBasicSettings.handStrength,
        autoTableSwitch: playerBasicSettings.autoTableSwitching,
        autoMuck: playerBasicSettings.autoMuck,
        smartGestures: playerHaptics.smartGestures,
        BBDisplay: playerBasicSettings.displayChipsInBB,
        smartGestureOptions: {
          smartFold: playerHaptics.dragToFold,
          smartCheck: playerHaptics.doubleTapOnCheck,
        },
        sound: playerHaptics.sound,
        haptics: playerHaptics.vibrations,
        autoRebuy: autoRebuy,
        autoTopupConfig: {
          enabled: playerAutoTopUpConfig.autoTopUpenabled,
          fallsBelowAmount: playerAutoTopUpConfig.fallsBelowPercentage,
          topUpAmount: playerAutoTopUpConfig.topUpPercentage,
          fallBelowMinAmount: playerAutoTopUpConfig.fallBelowMinAmount,
          fallsBelowMaxAmount: playerAutoTopUpConfig.fallsBelowMaxAmount,
          topUpMinAmount: playerAutoTopUpConfig.topUpMinAmount,
          topUpMaxAmount: playerAutoTopUpConfig.topUpMaxAmount,
        },
        bettingOptions: {
          defaultPreflopPresetValue: bettingOptions.defaultPreflopPresetValue,
          defaultPostflopPresetValue: bettingOptions.defaultPostflopPresetValue,
          preflopPresetValues: remapPresetsToIndexes(
            bettingOptions.preflopPresetValues
          ),
          postflopPresetValues: remapPresetsToIndexes(
            bettingOptions.postflopPresetValues
          ),
        },
      };
      console.log("TABLE SETTINGS >>> ", state.tableSettings, bettingOptions);
      setToLocalStorage(IS_SOUND_ENABLED, playerHaptics.sound);
      setToLocalStorage(IS_HAPTICS_EBABLED, playerHaptics.vibrations);
    },

    submitUpdatedTableSettingsConfig: (state, action) => {
      const { tableSettings } = state;
      const {
        showHandStrength,
        autoTableSwitch,
        autoMuck,
        smartGestures,
        smartGestureOptions,
        BBDisplay,
        sound,
        haptics,
        autoRebuy,
        autoTopupConfig,
        bettingOptions,
      } = tableSettings;
      const {
        enabled,
        fallsBelowAmount,
        topUpAmount,
        fallBelowMinAmount,
        fallsBelowMaxAmount,
        topUpMinAmount,
        topUpMaxAmount,
      } = autoTopupConfig;
      let loggedInPlayerDetails = state.userDetails;

      // Helper function to remap indexes to preset1, preset2, etc.
      const remapPresets = (presetValues) => {
        return Object.keys(presetValues).reduce((acc, key) => {
          acc[`preset${parseInt(key) + 1}`] = presetValues[key];
          return acc;
        }, {});
      };

      // Remap bettingOptions
      const updatedBettingOptions = {
        ...bettingOptions,
        preflopPresetValues: remapPresets(bettingOptions.preflopPresetValues),
        postflopPresetValues: remapPresets(bettingOptions.postflopPresetValues),
      };

      const requestPayload = {
        playerName: loggedInPlayerDetails.userName,
        playerBasicSettings: {
          handStrength: showHandStrength,
          autoTableSwitching: autoTableSwitch,
          displayChipsInBB: BBDisplay,
          autoMuck: autoMuck,
        },
        playerHaptics: {
          vibrations: haptics,
          sound: sound,
          smartGestures: smartGestures,
          doubleTapOnCheck: smartGestureOptions.smartCheck,
          dragToFold: smartGestureOptions.smartFold,
        },
        playerAutoRebuyConfig: {
          autoRebuy: autoRebuy,
          playerAutoTopUpConfig: {
            autoTopUpenabled: enabled,
            fallsBelowPercentage: fallsBelowAmount,
            topUpPercentage: topUpAmount,
            fallBelowMinAmount: fallBelowMinAmount,
            fallsBelowMaxAmount: fallsBelowMaxAmount,
            topUpMinAmount: topUpMinAmount,
            topUpMaxAmount: topUpMaxAmount,
          },
        },
        bettingOptions: updatedBettingOptions,
      };

      let gameConnections = GameConnectionsN.getInstance();
      let activeGameConnection = gameConnections.getGameConnection(
        state.activeGame
      );
      if (activeGameConnection && activeGameConnection.listener) {
        activeGameConnection.listener.processTableSettingsSaveRequest(
          requestPayload
        );
      }
    },

    handleTourneyStartTableCreationBasedOnActiveGames: (state, action) => {
      let tableData = state.games[state.activeGame];

      if (tableData) {
        state.games[state.activeGame].toastMessages.push({
          type: TOAST_INFO,
          message: action.payload.message,
        });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleJoinWaitListAck: (state, action) => {
      const {
        tempTableId,
        playerName,
        status,
        totWaitListCount,
        playerWaitingPosition,
      } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (state.userDetails.userName === playerName) {
          if (status === 100) {
            // if the player is successfully added to waitlist using its waitist position for further flow
            state.games[tempTableId].playerWaitingPosition =
              playerWaitingPosition;
            state.games[tempTableId].totalWaitListPlayerCount =
              totWaitListCount;

            state.games[tempTableId].toastMessages.push({
              type: TOAST_SUCCESS,
              message: `You have been added to the wait list.`,
            });
          } else if (status === 111) {
            // show insufficient funds modal
            state.games[tempTableId].openInSufficientFundsModal = true;
            state.games[tempTableId].autoAction = "";
            state.games[tempTableId].playerWaitingPosition = undefined;
          }
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleWaitListFailure: (state, action) => {
      const { tempTableId } = action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        state.games[tempTableId].playerWaitingPosition = undefined;
        state.games[tempTableId].totalWaitListPlayerCount = undefined;

        state.games[tempTableId].autoAction = "";

        state.games[tempTableId].toastMessages.push({
          type: TOAST_ERROR,
          message: `Failed to join waitlist. Please try again later.`,
        });
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleRemoveFromWaitList: (state, action) => {
      const { tempTableId, playerName, status, totWaitListCount } =
        action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (state.userDetails.userName === playerName && status === 100) {
          state.games[tempTableId].totalWaitListPlayerCount = totWaitListCount;

          state.games[tempTableId].playerWaitingPosition = undefined;

          state.games[tempTableId].autoAction = "";

          state.games[tempTableId].toastMessages.push({
            type: TOAST_INFO,
            message: `You have been removed from the wait list.`,
          });
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },

    handleJoinOrRemoveFromWaitListBroadcast: (state, action) => {
      const { tempTableId, playerName, status, totWaitListCount } =
        action.payload;
      let tableData = state.games[tempTableId];

      if (tableData) {
        if (status === 100 && state.userDetails.userName === playerName) {
          state.games[tempTableId].totalWaitListPlayerCount = totWaitListCount;
          if (totWaitListCount === 1) {
            state.games[tempTableId].playerWaitingPosition = totWaitListCount;
          }
        }
      } else {
        showNoTableAvailableWarning(action);
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchMatchMakingDetails.fulfilled, (state, action) => {
        const { tempTableId, data, status } = action.payload;
        sendFaroLog({
          requestType: "MATCH_MAKING_FULFILLED",
          responseData: data,
          status: status,
        });
        let tableData = state.games[tempTableId];
        if (tableData) {
          const {
            gameEndpoint,
            tableIdGameIdEntity,
            status,
            amountToAdd,
            awsAlbCookie,
          } = data;
          const { tableId, gameId } = tableIdGameIdEntity;
          //fulfilled response time logging - match making
          let date = new Date();
          let logsArray = tableData.apiMatchMakingTimeLogsArray;
          let logObject = logsArray[logsArray.length - 1]; //length zero is not considered as ideally it should be atleast one

          //no balance -- show add cash alert
          //200 -- success, received all values required to establish game socket connection
          //201 -- low balance
          //202 -- account error (like blocked or contact A23 support)
          //203 -- match making server error (check to confirm retry)

          if (status === 200) {
            tableData.gameConnectionStatus = MATCH_MAKING_API_RESPONSE_SUCCESS;

            const { hostname, port, protocol } = new URL(gameEndpoint);

            tableData.tableId = tableId;
            tableData.gameId = gameId;
            tableData.serverAddress = hostname;
            tableData.serverPort = port;
            tableData.connectionType = protocol.replace(":", ""); //ws or wss
            tableData.awsalbcookie = awsAlbCookie;
            window.document.cookie = `AWSALB=${awsAlbCookie};domain=${hostname};path=/`;
          } else {
            tableData.gameConnectionStatus = MATCH_MAKING_API_REQUEST_REJECTED;
            tableData.startConnectionTimeout = true;
          }

          logObject[
            tableData.gameConnectionStatus === MATCH_MAKING_API_RESPONSE_SUCCESS
              ? RESPONSE_KEY_NAME
              : REJECTED_KEY_NAME
          ] = `Date: ${date.getDate()}:${date.getMonth()}:${date.getFullYear()}, Time: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`;
          logObject[RESPONSE_CODE_KEY_NAME] = status;
        } else {
          showNoTableAvailableWarning();
        }
      })
      .addCase(fetchMatchMakingDetails.rejected, (state, action) => {
        const { tempTableId } = action.payload;
        sendFaroLog({
          requestType: "MATCH_MAKING_REJECTED",
        });
        //state = current(state);
        let tableData = state.games[tempTableId];

        if (tableData) {
          tableData.gameConnectionStatus = MATCH_MAKING_API_REQUEST_REJECTED;
          tableData.startConnectionTimeout = true;

          //rejected response time logging - match making
          let date = new Date();
          let logsArray = tableData.apiMatchMakingTimeLogsArray;
          let logObject = logsArray[logsArray.length - 1]; //length zero is not considered as ideally it should be atleast one

          logObject[
            REJECTED_KEY_NAME
          ] = `Date: ${date.getDate()}:${date.getMonth()}:${date.getFullYear()}, Time: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`;
        } else {
          showNoTableAvailableWarning();
        }
      })
      .addCase(fetchCheckGameDetails.fulfilled, (state, action) => {
        const { tempTableId, data } = action.payload;
        sendFaroLog({
          requestType: "CHECK_GAME_FULFILLED",
          responseData: data,
        });
        const { nodeIP, gameId, status, tableID, awsalbcookie } = data;

        let tableData = state.games[tempTableId];
        if (tableData) {
          let date = new Date();
          let logsArray = tableData.apiCheckGameTimeLogsArray;
          let logObject = logsArray[logsArray.length - 1]; //length zero is not considered as ideally it should be atleast one

          //200 -- success, received all values required to establish game socket connection

          if (status === 200) {
            tableData.gameConnectionStatus = CHECK_GAME_API_RESPONSE_SUCCESS;

            const { hostname, port, protocol } = new URL(nodeIP);
            tableData.serverAddress = hostname;
            tableData.serverPort = port;
            tableData.connectionType = protocol.replace(":", ""); //ws or wss
            tableData.gameId = gameId;
            tableData.tableId = tableID;
            tableData.awsalbcookie = awsalbcookie;

            window.document.cookie = `AWSALB=${awsalbcookie};domain=${hostname};path=/`;
          } else {
            tableData.gameConnectionStatus = CHECK_GAME_API_REQUEST_REJECTED;
            tableData.startConnectionTimeout = true;
          }

          logObject[
            tableData.gameConnectionStatus === CHECK_GAME_API_RESPONSE_SUCCESS
              ? RESPONSE_KEY_NAME
              : REJECTED_KEY_NAME
          ] = `Date: ${date.getDate()}:${date.getMonth()}:${date.getFullYear()}, Time: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`;
          logObject[RESPONSE_CODE_KEY_NAME] = status;
        } else {
          showNoTableAvailableWarning(action);
        }
      })
      .addCase(fetchCheckGameDetails.rejected, (state, action) => {
        let tempTableId = action.payload;
        sendFaroLog({
          requestType: "CHECK_GAME_REJECTED",
        });
        let tableData = state.games[tempTableId];

        if (tableData) {
          tableData.gameConnectionStatus = CHECK_GAME_API_REQUEST_REJECTED;
          tableData.startConnectionTimeout = true;

          //rejected response time logging - check game
          let date = new Date();
          let logsArray = tableData.apiCheckGameTimeLogsArray;
          let logObject = logsArray[logsArray.length - 1]; //length zero is not considered as ideally it should be atleast one

          logObject = logsArray[logsArray.length - 1]; //length zero is not considered as ideally it should be atleast one
          logObject[
            REJECTED_KEY_NAME
          ] = `Date: ${date.getDate()}:${date.getMonth()}:${date.getFullYear()}, Time: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`;
        } else {
          showNoTableAvailableWarning(action);
        }
      });
  },
});

export const {
  setPlayerRemainingTime,
  setTableTheme,
  setTableBackground,
  setCardBack,
  setCardDeck,
  setCardSize,
  setBuyInValueForPlayerPosition,
  toggleHandStrength,
  toggleAutoTableSwitch,
  toggleDisplayInBB,
  toggleSmartGestures,
  toggleSmartFold,
  toggleSmartCheck,
  toggleAutoMuck,
  toggleSound,
  toggleHaptics,
  updateGameTableBoundingRect,
  updatePlayerPositionsOnTable,
  changeTableOrientation,
  updateActiveGame,
  acknowledgePlayerMovedToCenter,
  watchAllGameTables,
  hideGameTable,
  showGameTable,
  exitGameTable,
  joinGameTable,
  leaveTableSeat,
  toggleSitOutAction,
  sitInAction,
  sitOutAction,
  resizingTable,
  setResizingToComplete,
  updatePositionsArranged,
  hidePlayerPositions,
  processPlayersDataOnTable,
  processLeaveSeatAck,
  createTableData,
  resetTableData,
  updateGameConnectionStatusN,
  closeGameWindow,
  startGameListenerN,
  processMatchMakingResponse,
  processCheckGameResponse,
  updateGameConnectionFailureProtocolStatus,
  requestReserveSeat,
  requestRemoveReserveSeat,
  setRemoveReserveSeatForPlayer,
  handleGameJoinRequest,
  handlePlayerJoinBroadcast,
  handleReserveSeatBroadcast,
  handleRemoveReserveSeatBroadcast,
  handleStartGame,
  handlePlayerAutoActions,
  handleTableTimerChangeRequest,
  handleStartGameTimer,
  disableStartGameTimer,
  setToastMessage,
  removeToastMessage,
  handleTurnChange,
  handleRevealCards,
  processLeaveSeatFailure,
  toggleCallAction,
  toggleCheckAction,
  toggleRaiseAction,
  toggleFoldAction,
  toggleAutoCallAction,
  toggleAutoCheckAction,
  toggleAutoFoldAction,
  handleCallActionAck,
  handleCheckActionAck,
  handleFoldActionAck,
  handleRaiseActionAck,
  handleAutoCallActionAck,
  handleAutoCheckActionAck,
  handleAutoFoldActionAck,
  toggleAutoCallAnyAction,
  toggleAutoCheckFoldAction,
  handleAutoCallAnyActionAck,
  handleAutoCheckFoldActionAck,
  updateDisableGameActions,
  removePlayerFromTable,
  closeFailedGameTable,
  deleteGameTable,
  handleJoinFailureAction,
  handleCallActionBroadcast,
  handleCheckActionBroadcast,
  handleFoldActionBroadcast,
  handleRaiseActionBroadcast,
  handlePotWinner,
  handlePrizePotAmount,
  processReconnectionAck,
  updateWaitingForBB,
  handleRankDetails,
  updateHandHistory,
  updateTableStats,
  setHandHistoryLoading,
  openHandHistoryRequest,
  requestHandHistory,
  syncUpTableTimers,
  handleRevealOtherPlayerCards,
  handleResult,
  sitOutBroadcastAction,
  sitInBroadcastAction,
  handleCleanTable,
  processPlayersDataOnTableForAlreadyRunningGame,
  sitOutFailAction,
  sitInFailAction,
  sitOutCancelAction,
  processLeaveSeatAckFromSitOut,
  leaveSeatBroadcastAction,
  handleRSeatBroadcast,
  handlePlayerExtraTime,
  handlePlayerExtraTimeLeft,
  initiateNextGameStartRequest,
  handleCloseFailure,
  handleAutoRebuySuccess,
  handleAutoRebuyBroadcast,
  handleAutoRebuyFailure,
  handleRebuyIn,
  handleDRebuyIn,
  handleRebuyFailure,
  handleRebuySuccessAck,
  handleRebuySuccessBroadcast,
  updateReleaseToFoldStatus,
  updateInSufficientFundsModalOpenStatus,
  initiateTopupRequest,
  handleTopupSuccessAck,
  handleTopupBroadcast,
  handleTopupFailure,
  handleTopupCancel,
  updateTopupModalOpenStatus,
  disableMultitableSwipe,
  enableMultitableSwipe,
  checkPlayerIsAvailableInSeats,
  handleRefreshProtocolData,
  updatePlayerDetails,
  handleReloginFailure,
  handleNextGameTimer,
  handlePreSelectedAutoAction,
  updateAllInAnimationFlag,
  processRabbitHuntingDetails,
  toggleAutoRebuy,
  openLocalGameModal,
  closeLocalGameModal,
  closeInternetDisconnectionGameModal,
  handleTourneyPrize,
  handleSitOutButtonOnDRebuyIn,
  handleBlindsUpDetails,
  handleTourneyBreakBroadcast,
  handleTourneyAutoPlayAcknowledgement,
  handleTourneyNextLevelUp,
  updateUserAutoActionPreference,
  closeTourneyBanner,
  updateAutoTableSwitchQueue,
  handlePlayerDisconnectionTime,
  updateDisableOrientationChange,
  handleTourneyEliminate,
  updateUserRestrictionStatus,
  clearHandHistoryIndex,
  reportHandHistoryIssue,
  handleReportIssueSuccess,
  updatePlayerSettingsConfig,
  submitUpdatedTableSettingsConfig,
  toggleAutoTopup,
  handleAutoTopupFallsBelowSliderChange,
  handleAutoTopupSliderChange,
  processPlayerStatsOnDemand,
  processPlayerStatsData,
  processPlayerStatsOnDemandFailure,
  processSavePlayerStatsResponse,
  updateAmountToAdd,
  updateGameApplicationVisibility,
  handlersTourneyRebuyIn,
  submitTourneyRebuyRequest,
  handleTourneyRebuyAck,
  processTourneyRebuyCancle,
  handleTourneyRebuyCancle,
  handleTourneyRebuyBroadcast,
  handleTourneyRebuyFailure,
  handleTourneyStartTableCreationBasedOnActiveGames,
  processAutoTableSwitch,
  processReserveFailure,
  requestTourneyAddOnTopup,
  handleTourneyAddonAck,
  handleMaintenancePopup,
  handleTourneyAddonFailureAck,
  handleRequestTourneyRankingLeaderboardDetails,
  handleTourneyRankingLeaderBoard,
  cleanUpTableObservers,
  handleTourneyPlayerRankDetails,
  handleTourneyAddOn,
  handleOpenTourneyAddOnPopup,
  closeTourneyAddOnPopup,
  handleTourneyTableSwitching,
  handleTourneyNodeSwitch,
  handleTourneyBreakEnd,
  handleTourneyRebuyEnded,
  handleJoinWaitListAck,
  handleRemoveFromWaitList,
  handleJoinOrRemoveFromWaitListBroadcast,
  handleWaitListFailure,
  processPlayerConfigAck,
  handleTourneyWatchPlayerAddOn,
  handleTourneyPlayerInTheMoneyDetails,
  closeExistingTourneyGameTable,
  handleTourneyWaitingForMerging,
  checkForTableMergeBannerAndClose,
  updateWinningPotDisplay,
  setPreflopPresetValues,
  setPostflopPresetValues,
  setDefaultPreflopPresetValue,
  setDefaultPostflopPresetValue,
  setHappyHoursInfoLoading,
  processHappyHoursInfo,
  processHappyHoursInfoFailure,
  updateGameDefinition,
  setPlayerAvatar,
} = gameTableSlice.actions;

export default gameTableSlice.reducer;
