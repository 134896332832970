import React, { useEffect, useState } from "react";
import { GridIcon, GridImage } from "./HomeStyle";

const GridImageComponent = ({ item, action }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageError = (err) => {
    setImageError(true);
  };

  const handleImageLoad = () => {
    setImageLoading(false);
    setImageLoaded(true);
  };

  return imageError ? (
    <GridIcon id={`main-tile-${item?.name}`}
      onClick={action}
      style={{
        textAlign: "center",
        fontSize: "12px",
        fontWeight: "bold",
        background: "#33607080",
        minHeight: '140px'
      }}
    >
      {/* <GridImage src={src} /> */}
      {item?.name}
    </GridIcon>
  ) : imageLoading || imageLoaded ? (
    <GridIcon onClick={action} id={`plotline-main-tile-${(item?.name?.replace(/ /g,''))}`}>
      <GridImage
        style={{ opacity: imageLoading ? 0 : 1 }}
        onError={(err) => handleImageError(err)}
        onLoad={() => handleImageLoad()}
        src={item.logoUrl}
      />
    </GridIcon>
  ) : null;
};

export default GridImageComponent;
