import {
  GAME_STAGE_FLOP,
  GAME_STAGE_RIVER,
  GAME_STAGE_SHOWDOWN,
  GAME_STAGE_TURN,
} from "../../data/Constants";
import Handler from "../Handler";

class SeatsHandler extends Handler {
  execute(message) {
    let seatsResponse = JSON.parse(message);
    if (seatsResponse.gameStarted) {
      const {
        tableId,
        gameId,
        runningTablechair,
        flopCards,
        turnCard,
        riverCard,
        totalPotAmt,
        gameStarted,
        gameStage,
        happyHoursEnabled,
        happyHoursRake,
        happyHoursHeadsUpRake,
      } = seatsResponse;
      let cards = [];
      if (gameStage === GAME_STAGE_FLOP) {
        cards = [...flopCards, "cardBack", "cardBack"];
      } else if (gameStage === GAME_STAGE_TURN) {
        cards = [...flopCards, turnCard, "cardBack"];
      } else if (gameStage === GAME_STAGE_RIVER) {
        cards = [...flopCards, turnCard, riverCard];
      } else if (gameStage === GAME_STAGE_SHOWDOWN) {
        cards = [...flopCards, turnCard, riverCard];
      }

      this.service.processRunningTableChairs({
        gameStarted: gameStarted,
        tableId: tableId,
        gameId: gameId,
        playersData: runningTablechair,
        gameStage: gameStage,
        communityCards: [...cards],
        userCards: [],
        happyHoursEnabled: happyHoursEnabled,
        happyHoursRake: happyHoursRake,
        happyHoursHeadsUpRake: happyHoursHeadsUpRake
      });
      // this.service.processFlopCards({ tableId, gameId, flopCards });
      // this.service.processTurnCard({ tableId, gameId, turnCard });
      // this.service.processRiverCards({ tableId, gameId, riverCard });
      // this.service.processPotAmount({ tableId, gameId, totalPotAmt });
    } else {
      const { gameId, gameStarted, tableId, yetToRunTableChairs, happyHoursEnabled, happyHoursRake, happyHoursHeadsUpRake } =
        seatsResponse;
      this.service.processSeatsOnTable({
        gameStarted: gameStarted,
        tableId: tableId,
        gameId: gameId,
        playersData: yetToRunTableChairs,
        happyHoursEnabled: happyHoursEnabled,
        happyHoursRake: happyHoursRake,
        happyHoursHeadsUpRake: happyHoursHeadsUpRake
      });
    }
  }
}
export default SeatsHandler;

// {
//     "tableId": "tableId",
//     "gameId": "gameId",
//     "runningTablechair": [
//       {
//         "playerInfo": {
//           "playerName": "playerName",
//           "isDealer": "isDealer",
//           "isFoldPlayer": "isFoldPlayer",
//           "isActivePlayer": "isActivePlayer",
//           "isSmallBlind": "isSmallBlind",
//           "isBigBlind": "isBigBlind",
//           "isSitoutPlayer": "isSitoutPlayer",
//           "atTableamt": "atTableamt",
//           "isAllinPlayer": "isAllinPlayer",
//           "userAcelevel": "userAcelevel",
//           "userProfilePic": "userProfilePic"
//         },
//         "position": "position",
//         "isReserved": "isReserved"
//       }
//     ],
//     "flopCards": "flopCards",
//     "turnCard": "turnCard",
//     "riverCard": "riverCard",
//     "totalPotAmt": "totalPotAmt"
//   }

// Seats#{
//   tableId: "THN240723-1105437bvmfe",
//   gameId: "240723-1105442jqk4e",
//   gameStarted: false,
//   yetToRunTableChairs: [
//     {
//       position: 1,
//       playerName: "gg02",
//       profilePic: "profilePic",
//       aceLevel: "aceLevel",
//     },
//   ],
// };
