import styled from "styled-components";
import colors from "../../../styleGuide/Colors";
import specialMessage from "./../../../../assets/gameTable/specialMessage.png";
import {
  BaseContoBoldText,
  BaseContoText,
} from "../../../styleGuide/Typos";

export const Wrapper = styled.div`
  width: 100%;
  height: 60%;
  z-index: 2;
  position: relative;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items : center;
  //   Tile view
  // @media (min-width: 300px) and (max-width: 550px) {
  //   width: 60vw;
  // }
`;

export const Acknowledgement = styled.div`
  // background-image : url(${specialMessage});
  width: 95%;
  // height : 77%;
  // background-repeat : no-repeat;
  display : flex;
  justify-content : center;
  align-items : center;
  position : relative;
  // background-size: 100% 100%;
`

export const AcknowlegeImage = styled.img`
  width: 100%;
  height : 100%;
`;

export const HandLabel = styled(BaseContoBoldText)`
  text-transform: uppercase;
  position: absolute;
  top: 16%;
  color : white;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const CongratulationsLabel = styled.span`
  text-transform: uppercase;
  position: absolute;
  top: 29%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: -webkit-linear-gradient(-115.73deg, #713529 35%, #522314 67.31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 10px;
`;

export const InfoBox = styled.div`
  border: 1px solid #fff;
  border-radius: 5px;
  width: 55%;
  padding: 6px 0;
  position: absolute;
  top: 42%;
  display: flex;
  justify-content: center;

  // Tile view
  // @media (min-width: 300px) and (max-width: 550px) {
  //   width: 30vw;
  // }
`;

export const ImageContainer = styled.div`
  // width: 40%;
  text-align: center;
  padding: 0.3rem;
`;

export const PlayerAvatar = styled.img`
  width: 60px;
  height : 60px;
  border-radius: 50%;
  border: 1px solid #fffdea;
`;

export const PlayerName = styled(BaseContoText)`
  display: block;
  color : #fffdea;
  font-size : 12px;
`;
export const DetailsContainer = styled.div`
  // width: 60%;
  padding-left: 8px;
`;

export const DetailsBox = styled.div`
  line-height: 3.4vh;
`;

export const Details = styled.span`
  color: ${(props) => (props.color ? props.color : colors.white)};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  font-size: 12px;

`;

export const GameLabel = styled.span`
  background: #6f4b18;
  border-radius: 12px;
  border-bottom: 0.65px solid #d0933c;
  color: #fff;
  font-size: 12px;
  padding: 2px 5px;
`;

export const TagLine = styled.div`
  position: absolute;
  top: 75%;
  display: flex;
  justify-content: center;
  width: 70%;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  z-index:2;  
  margin-top:5%;
  // position:absolute; 
  bottom:20%;
  display : flex;
  width : 90%;
  flex-direction : row;
  justify-content : space-around;
  align-items : center;
`
export const SecondaryCTAButton = styled.button`
  display: flex;
  font-family : 'Conto';
  font-size : 16px;
  width: 125px;
  height: 33px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  outline: none;
  border: none;
  color: #08D421;
  font-weight: bold;
  border: 1px solid #42BE40;
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.4);
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px rgb(7 55 16) ;
  :active {
    background-color: ${colors.confirmPrimaryCTAbackground};
    box-shadow: 0 0px rgb(7 55 16) ;
    // transform: translateY(1px);
  }
`;

export const PrimaryCTAButton = styled.button`
  font-family : 'Conto';
  font-size : 16px;
  display: flex;
  width: 125px;
  height: 33px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  outline: none;
  border: none;
  color: white;
  font-weight: bold;
  border-top: 1px solid ${colors.dragonGreen};
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.4);
  background: ${colors.confirmPrimaryCTAbackground};
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px rgb(7 55 16) ;

  :active {
    background-color: ${colors.confirmPrimaryCTAbackground};
    box-shadow: 0 0px rgb(7 55 16) ;
    // transform: translateY(1px);
  }
`;

