import styled from "styled-components";
import colors from "../../../../Common/styleGuide/Colors";

export const LeaderboardButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primaryDeepBlue};
  border-radius: 4px;
  border: 1px solid ${colors.deepTeal60};
  position: absolute;
  top: ${(props) => (props.isTourney ? "128px" : "64px")};
  left: 2%;
  z-index: 10;
`;

export const LeaderboardOverlay = styled.div`
  width: 335px;
  height: 135px;
  border-radius: 8px;
  border: 1px solid #223655;
  background: linear-gradient(90deg, rgba(3, 33, 70, 0.65) 0%, #032146 100%),
    #4f5153;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: ${(props) => (props.isTourney ? "165px" : "105px")};
  left: 2%;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .swiper {
    width: 95%;
    height: 80%;
    padding-top: 5px;
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    background-color: ${colors.pebbleGray};
    opacity: 0.6;
  }
  .swiper-pagination-bullet-active {
    width: 4px;
    height: 4px;
    border-radius: 8px;
    background-color: ${colors.lightYellow};
    opacity: 1;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0px;
  }
  .icon-close {
    height: 15px;
    width: 15px;
    background: #eabf69;
    border-radius: 50%;
    align-self: center;
    padding: 2px;
    position: absolute;
    right: 10px;
  }
`;

export const LeaderboardCard = styled.div`
  display: flex;
  font-size: 12px;
  padding: 8px;
  justify-content: center;
  // align-items: 'center',
  // gap: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.16);
  width: 100%;
  height: 90%;
`;

export const MinHandCriteriaLock = styled.img`
width: 1.9vw;
margin-left: 5px;
`


export const LeaderboardHeading = styled.div`
  color: white;
  display: flex;
  width: 95%;
  // height: 20%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
`;

export const LeaderboardFooter = styled.div`
    background: #4f5153;
    color: #fffdea;
    font-size: 0.75rem;
    font-weight: 500;
    justify-content: space-between;
    padding: 8px 12px;
    align-items: center;
    display: flex;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #707070;
    border-radius: 0px 0px 7px 7px;
`
export const LeaderboardWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
`

export const PrizeDetails = styled.div`
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  justify-content: center;
  align-items: center;
  width: 28%;
  margin-right: 8px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 72%;
  justify-content: space-between;
  .icon-calendar {
    width: 15px;
  }
  .icon-rank {
    width: 12px;
    padding-right: 5px;
  }
  .icon-score {
    width: 15px;
    padding-right: 5px;
  }
`;

export const LbTitle = styled.span`
  color: #3a4c71;
  font-weight: 500;
`;
export const StartDate = styled.span`
  color: #3a4c71;
  font-weight: 500;
  background: #fffdea;
  padding: 2px 5px;
  border-radius: 2px;
  align-self: center;
  width: 100%;
`;

export const RankDetails = styled.span`
  color: #196a29;
  font-weight: 700;
  margin: ${(props) => (props.leaderboard ? "0px auto 0px 7px" : null)};
`;

export const LbDetails = styled.span`
  color: #032146;
  font-weight: 700;
  margin: ${(props) => (props.leaderboard ? "0px auto 0px 7px" : null)};
`;

export const RankScoreInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LbIcons = styled.img`
  padding-right: 5px;
`;
