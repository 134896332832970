import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";

export const HappyHoursContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "table-settings-move-up forwards 0.25s"
      : "table-settings-move-down forwards 0.5s"};
  background: rgba(4, 42, 52, 1);
  overflow: scroll;
  @keyframes table-settings-move-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes table-settings-move-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

export const HappyHoursHeader = styled.div`
  /* background: ${colors.secondaryDeepBlue}; */
  /* background: ${colors.deepBlue}; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  padding: 12px;
`;

export const HappyHoursBody = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HappyHoursLoadingContainer = styled.div`
  width: 100%;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HappyHoursGraphic = styled.img`
  width: 40%;
  height: auto;
  margin: auto;
  margin-bottom: 16px;
`;
export const HappyHoursInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  align-items: flex-start;
  margin-bottom: 6px;
`;

export const HappyHoursPlayerSavingsContainer = styled.div`
  position: relative;
`;

export const HappyHoursInfo = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
  background-color: rgba(7, 80, 99, 1);
  padding: 6px 10px;
  border-radius: 8px;
  z-index: 10;
`;

export const LifeTimeSavingsInfo = styled.div`
  z-index: 1;
  position: relative;
  bottom: 12px;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(8, 213, 33, 0.25) 0%,
    rgba(25, 106, 41, 0.25) 100%
  );
  padding: 16px 10px 2px 10px;
  border-radius: 0px 0px 8px 8px;
`;

export const HappyHoursInfoText = styled.div`
  font-size: 10px;
  font-family: "Conto";
  color: white;
  font-weight: 500;
  white-space: nowrap;
`;

export const LifeTimeSavingsInfoText = styled.div`
  font-size: 10px;
  font-family: "Conto";
  color: white;
  font-weight: 500;
  white-space: nowrap;
`;
export const LifeTimeSavingsAmount = styled.div`
  color: ${colors.primaryGold};
  font-family: "Conto";
  font-weight: 700;
  font-size: 12px;
`;

export const HappyHoursInfoAmount = styled.div`
  color: white;
  font-family: "Conto";
  font-weight: 700;
  font-size: 16px;
`;

export const HappyHoursTimerContainer = styled.div`
    background: linear-gradient(90deg, rgba(2, 24, 30, 0) 0%, #02181E 50%, rgba(2, 24, 30, 0) 100%);
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    padding: 8px 40px;
    margin-bottom: 12px;
`

export const HappyHoursTimerText = styled.div`
    font-size: 11px;
    color: ${colors.pebbleGray};
`

export const TakeSeatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  /* margin-top: 0px; */
`

export const TakeSeatButton = styled.div`
  background: ${colors.ctaPrimaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
`

export const TakeSeatText = styled.div`
  color: white;
  font-size: 12px;

  font-size: 12px;
  @media screen and (min-width: 500px) {
    font-size: 16px;
  }
`

export const HappyHoursTimerValue = styled.div`
    color: ${(props) => props.start ? colors.leafyGreen : colors.secondaryRed};
    font-size: 14px;
    font-weight: 700;
`

export const HappyHoursIcon = styled.img`
  width: ${(props) => props.size ? props.size + 'px' : '16px'};
  height: ${(props) => props.size ? props.size + 'px' : '16px'};
  margin-right: 0.5rem;
`

export const HappyHoursRulesContainer = styled.div`
  width: 100%;

`
export const HappyHoursSliderHeader = styled.div`
display: flex;
justify-content: center;
align-items: center;
color: ${colors.primaryGold};
font-size: 14px;
font-weight: 500;
width: 100%;
background-color: rgba(7, 80, 99, 0.2);
text-shadow: 0px 1px 2px black;
padding: 12px 0px;
`

export const HappyHoursRulesBody = styled.div`
  padding: 16px 16px 28px 16px;
  display: flex;
  width: 90%;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`

export const HappyHoursRule = styled.div`
  font-size: 12px;
  color: white;
  font-weight: 400;
  display: flex;
  align-items: center;
`

export const HappyHoursFailureContainer = styled.div`
  width: 100%;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const HappyHoursFailureText = styled.div`
  font-size: 12px;
  color white;
  text-align: center;
  width: 80%;
`;