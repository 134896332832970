import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo10GrayContoRegularText,
  Typo12GrayContoRegularText,
} from "../../../Common/styleGuide/Typos";
import {
  GAME_CONNECTION_PROTOCOL_SUCCESS,
  MATCH_MAKING_BET_DISABLED,
} from "../../data/Constants";
import {
  LandscapeBG1,
  LandscapeBG2,
  LandscapeBG3,
  LandscapeBG4,
  BG1,
  BG2,
  BG3,
  BG4,
} from "./gameAssets";

export const Container = styled.div`
  min-width: var(--app-width);
  width: var(--app-width);
  background-image: ${(props) =>
    props.orientation === "PORTRAIT"
      ? props.bg === "BG1"
        ? "url(" + BG1 + ")"
        : props.bg === "BG2"
        ? "url(" + BG2 + ")"
        : props.bg === "BG3"
        ? "url(" + BG3 + ")"
        : props.bg === "BG4"
        ? "url(" + BG4 + ")"
        : null
      : props.bg === "BG1"
      ? "url(" + LandscapeBG1 + ")"
      : props.bg === "BG2"
      ? "url(" + LandscapeBG2 + ")"
      : props.bg === "BG3"
      ? "url(" + LandscapeBG3 + ")"
      : props.bg === "BG4"
      ? "url(" + LandscapeBG4 + ")"
      : null};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: ${colors.gameTableBg};
  overflow: hidden;
  height: var(--app-height);
  /* transition: ${(props) => (props.showGameTable ? "0s" : "1s ease")}; */
  /* opacity: ${(props) => (props.showGameTable ? 1 : 0)}; */
  filter: ${(props) =>
    !props.gameConnection ||
    props.gameConnectionStatus === MATCH_MAKING_BET_DISABLED
      ? "brightness(0.4)"
      : null};
  pointer-events: ${(props) =>
    props.gameConnectionStatus !== GAME_CONNECTION_PROTOCOL_SUCCESS
      ? "none"
      : null};
`;

export const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1)), rgba(0, 0, 0, 0.1);
`;

export const PureRelativeWrapper = styled.div`
  position: relative;
`;

export const MiniGameTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0rem 10px;
  z-index: 2;
`;

export const GameTableImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GameTableBackgroundImage = styled.img`
  max-width: ${(props) => (props.orientation === "LANDSCAPE" ? "auto" : "95%")};
  max-height: ${(props) =>
    props.orientation === "LANDSCAPE" ? "auto" : "75%"};
  height: ${(props) => (props.orientation === "LANDSCAPE" ? "auto" : "auto")};
  width: ${(props) => (props.orientation === "LANDSCAPE" ? "75%" : "auto")};
  position: absolute;
  top: ${(props) => (props.orientation === "LANDSCAPE" ? "58%" : "50%")};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  filter: brightness(0.85);
  @keyframes move-out-left {
    0% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(-200%, -50%);
    }
  }

  @keyframes move-in-left {
    0% {
      transform: translate(-200%, -50%);
    }
    100% {
      transform: translate(-50%, -50%);
    }
  }

  @keyframes move-out-right {
    0% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(100%, -50%);
    }
  }

  @keyframes move-in-right {
    0% {
      transform: translate(100%, -50%);
    }
    100% {
      transform: translate(-50%, -50%);
    }
  }

  @media (min-width: 768px) {
    max-height: ${(props) =>
      props.orientation === "LANDSCAPE" ? "90%" : "70%"};
  }
`;

export const ButtonWrapper = styled.div`
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  gap: 5px;
  bottom: 2%;
  left: 50%;
  z-index: 3;
  animation: ${(props) =>
    props.showReleaseToFold
      ? "hideButtons 0.25s forwards"
      : "showButtons 0.25s forwards"};

  @keyframes hideButtons {
    0% {
      transform: translateY(0) translateX(-50%);
    }
    100% {
      transform: translateY(calc(100% + 25px)) translateX(-50%);
    }
  }

  @keyframes showButtons {
    0% {
      transform: translateY(calc(100% + 25px)) translateX(-50%);
    }
    100% {
      transform: translateY(0%) translateX(-50%);
    }
  }
`;

export const JoinBackButton = styled.button`
  margin-left: 15px;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: "Conto";
  display: flex;
  align-items: center;
  bottom: 6px;
  z-index: 3;
  transition: 0.25s ease;
  opacity: ${(props) => (props.disabled ? 0.45 : 1)};
  background: ${colors.greenBtn};
`;

export const PlayNextWrapper = styled.div`
  width: 30%;
  display: flex;
  padding: 0 2%;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: ${(props) => (props.orientation === "LANDSCAPE" ? "0%" : "50%")};
  transform: ${(props) =>
    props.orientation === "LANDSCAPE" ? null : "translateX(-50%)"};
  bottom: 2%;
  z-index: 3;
  animation: fade-in 0.25s forwards;
`;

export const JoinBackWrapper = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  padding: 0 3%;
  align-items: center;
  justify-content: ${(props) =>
    props.orientation === "LANDSCAPE" ? "flex-end" : "center"};
  position: absolute;
  bottom: 6px;
  z-index: 3;
  animation: fade-in 0.25s forwards;
`;

export const JoinBackText = styled.span`
  color: white;
  font-family: "Conto";
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const LandscapeButtonWrapper = styled.div`
  width: 96%;
  padding: 0 2%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 6px;
  right: 0px;
  z-index: 3;
  animation: ${(props) =>
    props.showReleaseToFold
      ? "hideButtons 0.25s forwards"
      : "showButtons 0.25s forwards"};

  @keyframes hideButtons {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc(100% + 25px));
    }
  }

  @keyframes showButtons {
    0% {
      transform: translateY(calc(100% + 25px));
    }
    100% {
      transform: translateY(0%);
    }
  }
`;

export const LandscapeWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;

export const LeftActions = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const RightActions = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
`;

export const CenterSpacing = styled.div`
  width: 30%;
`;

export const HeaderActionButtonsWrapper = styled.div`
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 1rem;
  padding: 0 2%;
  z-index: 4;
`;

export const HeaderInfoButtonWrapper = styled.div`
  width: 94%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 3.5rem;
  padding: 0 3%;
`;

export const MaskingLayer = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  display: ${(props) => (props.orientation === "LANDSCAPE" ? "none" : "block")};
  /* transform: ${(props) =>
    props.orientation === "LANDSCAPE" ? "translateY(20%)" : "null"}; */
  z-index: 1;
`;

export const CommonButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 96%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: ${(props) => (props.orientation === "LANDSCAPE" ? "45px" : "60px")};
  /* top: ${(props) =>
    props.orientation === "LANDSCAPE" ? "60px" : "60px"}; */
  z-index: 4;
  pointer-events: none;
  @media (min-width: 768px) {
    bottom: 75px;
  }
`;

export const HandHistoryButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primaryDeepBlue};
  border-radius: 4px;
  border: 1px solid ${colors.deepTeal60};
  pointer-events: all;
  /* margin-left: 2%; */
`;

export const TopupButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primaryDeepBlue};
  border-radius: 4px;
  border: 1px solid ${colors.deepTeal60};
  /* margin-left: 6px; */
  /* margin-bottom: ${(props) =>
    props.orientation === "LANDSCAPE" ? "6px" : "12px"}; */
  pointer-events: all;
`;

export const IconsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const GameButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primaryDeepBlue};
  border-radius: 4px;
  border: 1px solid ${(props) => props.start ? colors.leafyGreen : props.start === false ? colors.secondaryRed : colors.deepTeal};
  pointer-events: all;
  position: relative;
  /* margin-right: 2%; */
`;

export const ButtonImage = styled.img`
  width: 55%;
  height: auto;
`;

export const HappyHoursTimerText = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% - 1px));
  color: ${(props) => props.start ? colors.leafyGreen : colors.secondaryRed};
  font-weight: 700;
  font-size: 11px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LoadingText = styled.span`
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 0rem;
`;

export const WinningAmountText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 5px));
  color: white; // Or your preferred color for winning amount
  background: rgba(24, 75, 24, 1);
  border-radius: 4px;
  font-weight: 700;
  font-size: 10px;
  padding: 2px 6px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddCashButton = styled.button`
  border: none;
  outline: none;
  background: ${colors.greenBtn};
  color: white;
  font-family: "Conto";
  padding: 0.5rem 1rem;
  border-radius: 5px;
  width: 100px;
`;

export const GoToLobbyButton = styled.button`
  border: 1px solid ${colors.primaryGold};
  outline: none;
  background: transparent;
  color: ${colors.primaryGold};
  font-family: "Conto";
  padding: 0.5rem 0rem;
  border-radius: 5px;
  margin-top: 1rem;
  width: 100px;
`;

export const DisconnectionResult = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
`;

export const CloseGameButton = styled.div`
  margin-top: 1rem;
  background: ${colors.redBtn};
  color: white;
  font-family: "Conto";
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
`;

export const PlayNextButton = styled.button``;

export const ReleaseToFoldAlertContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  transform: translateY(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: showReleaseText 0.25s forwards;
  background: ${colors.redBtn};
  height: 40px;
  z-index: 2;

  @keyframes showReleaseText {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`;

export const ReleaseToFoldText = styled.span`
  color: white;
  font-weight: bold;
  font-family: "Conto";
  font-size: 12px;
`;

export const ChipImg = styled.img`
  width: 10px;
  margin-right: 2px;
`;

export const WaitListPlayerCount = styled(Typo10GrayContoRegularText)`
  min-width: 30%;
  margin: 0px 2% 0px 3%;
`;
