import React, { useEffect, useState } from 'react'
import { HappyHoursGoldGlass, HappyHoursLobbyContainer, HappyHoursLobbySection, HappyHoursSortContainer, HappyHoursSubtitle, HappyHoursTimeContainer, HappyHoursTitle, HappyHoursToggle } from '../LobbyGameTilesList/styledComponents'
import happyHoursGoldImg from "../../../assets/lobby/happyHoursGoldIcon.png";
import happyHoursOn from "../../../assets/lobby/toggleOn.png";
import happyHoursOff from "../../../assets/lobby/toggleOff.png";
import { useDispatch, useSelector } from 'react-redux';
import { useTimer } from '../../../hooks/useTimer';
import { sortLobbyGamesByHappyHours, updateHappyHoursSort, updateHappyHoursTileSort } from '../../redux/slices/lobbySlice';
import { DISCONNECTED } from '../../data/Constants';

const HappyHoursCashBanner = () => {
    const dispatch = useDispatch();
    const happyHoursSort = useSelector((state) => state.lobby.happyHoursTileSort);
    const currentLobbyTime = useSelector((state) => state.lobby.currentTimeStamp)
    const isHappyHoursEnabled = useSelector((state) => state.lobby.isHappyHoursEnabled);
    const happyHoursMinStartTime = useSelector((state) => state.lobby.happyHoursMinStartTime);
    const happyHoursMaxEndTime = useSelector((state) => state.lobby.happyHoursMaxEndTime);
    const [happyHoursTimer, setHappyHoursTimer] = useState({
        start: true,
        endTime: null,
    });
    const timerValue = useTimer(happyHoursTimer.endTime);
    const masterGameDefinitions = useSelector((state) => state.lobby.masterGameDefinitions);
    const happyHoursTimerStarted = useSelector((state) => state.lobby.happyHoursTimerStarted)

    useEffect(() => {
        // Sort All Game Defs here by start time of happy hours
        dispatch(sortLobbyGamesByHappyHours());
    }, [masterGameDefinitions, happyHoursSort]);


    useEffect(() => {
        // console.log('Spruha log >>> Happy Hours Timings >>> ', happyHoursMinStartTime, currentLobbyTime, happyHoursMaxEndTime)
        if (currentLobbyTime < happyHoursMinStartTime && happyHoursMinStartTime !== Infinity) {
            // console.log('Spruha log >>> Setting timer to start! ', happyHoursMinStartTime);
            setHappyHoursTimer({
                start: true,
                endTime: happyHoursMinStartTime
            });
        } else if (happyHoursMinStartTime < currentLobbyTime < happyHoursMaxEndTime) {
            // console.log('Spruha log >>> Setting timer to end! ', happyHoursMaxEndTime);
            setHappyHoursTimer({
                start: false,
                endTime: happyHoursMaxEndTime
            })
        } else {
            // console.log('Spruha log >>> Setting timer to null! ', happyHoursMaxEndTime);
            setHappyHoursTimer({
                start: null,
                endTime: null,
            })
        }
    }, [happyHoursMinStartTime, happyHoursMaxEndTime, currentLobbyTime])

    if (happyHoursTimerStarted || isHappyHoursEnabled) {
        return (
            <HappyHoursLobbyContainer id={"plotline-lobby-happy-hours"}>
                <HappyHoursLobbySection id={"plotline-happy-hours-info"}>
                    <HappyHoursTitle>
                        <HappyHoursGoldGlass src={happyHoursGoldImg} />
                        Happy Hours Live
                        {
                            timerValue
                                ?
                                <HappyHoursTimeContainer start={happyHoursTimer.start} id={"plotline-happy-hours-timer"}>
                                    {happyHoursTimer.start ? 'Starts in ' : 'Ends in '}{timerValue}
                                </HappyHoursTimeContainer>
                                : null
                        }
                    </HappyHoursTitle>
                    <HappyHoursSubtitle>
                        Play More, Win More - It's Zero Rake Hours!
                    </HappyHoursSubtitle>
                </HappyHoursLobbySection>
                <HappyHoursLobbySection id={"plotline-happy-hours-toggle"}>
                    <HappyHoursSortContainer
                        onClick={() => dispatch(updateHappyHoursTileSort())}
                    >
                        Sort Bets
                        <HappyHoursToggle
                            src={happyHoursSort ? happyHoursOn : happyHoursOff}
                        />
                    </HappyHoursSortContainer>
                </HappyHoursLobbySection>
            </HappyHoursLobbyContainer>
        )
    }

    return null
}

export default HappyHoursCashBanner