export const APP_RUNNING_DEVELOPMENT_MODE = "development";
export const APP_RUNNING_PRODUCTION_MODE = "production";

export const WAITING = 0;
export const PLAYING = 1;
export const PLAYER_ACTIVE = "active";

export const LB_PLAY = "fun";
export const LB_REAL = "real";

export const LOBBY_DATA = "LD";
export const SPECIAL_BETS_DATA = "SPECIAL_BETS_DATA";

export const LOBBY_PROTOCOL = "ld";

export const ALL = "ALL";
export const TEXAS_HOLDEM = "TEXAS_HOLDEM";
export const OMAHA = "OMAHA";
export const OMAHA5 = "OMAHA5";

export const GAME_STATUS_ACTIVE = "Active";
export const GAME_STATUS_IN_ACTIVE = "Inactive";

export const GAME_DEFINITION_UPDATE = "update";
export const GAME_DEFINITION_NEW = "new";

export const PLAYER_CARDS_IN_TEXAS_HOLDEM = 2;
export const PLAYER_CARDS_IN_OMAHA = 4;
export const PLAYER_CARDS_IN_OMAHA5 = 5;

export const MY_TOURNEYS = "MY_TOURNEYS";
export const ACTIVE_TOURNEYS = "ACTIVE_TOURNEYS";
export const COMPLETED_TOURNEYS = "COMPLETED_TOURNEYS";

export const ALL_TOURNEYS = "ALL_TOURNEYS";
export const TICKET_TOURNEYS = "TICKET_TOURNEYS";
export const GTD_TOURNEYS = "GTD_TOURNEYS";
export const SIT_AND_GO_TOURNEYS = "Sit And Go";
export const SATTY_TOURNEYS = "SATTY_TOURNEYS";
export const TIMER_TOURNEYS = "Timer";
export const FREE_ROLL_TOURNEYS = "FREE_ROLL_TOURNEYS";

export const GAMES_TAB_BAR = "GAMES_TAB_BAR";
export const TOURNEYS_TAB_BAR = "TOURNEYS_TAB_BAR";
export const TOURNEYS_VARIANT_TAB_BAR = "TOURNEYS_VARIANT_TAB_BAR";

//Audio names

export const ALL_IN_SOUND = "ALL_IN_SOUND";
export const BET_SOUND = "BET_SOUND";
export const BUTTON_SOUND = "BUTTON_SOUND";
export const CALL_SOUND = "CALL_SOUND";
export const CHAT_SOUND = "CHAT_SOUND";
export const CHECK_SOUND = "CHECK_SOUND";
export const DEAL_SOUND = "DEAL_SOUND";
export const EXTRA_TIMER_SOUND = "EXTRA_TIMER_SOUND";
export const FOLD_SOUND = "FOLD_SOUND";
export const PLAY_REGISTER_SOUND = "PLAY_REGISTER_SOUND";
export const RAISE_SOUND = "RAISE_SOUND";
export const RAISE_BAR_SOUND = "RAISE_BAR_SOUND";
export const TOURNAMENT_BUST_SOUND = "TOURNAMENT_BUST_SOUND";
export const TURN_SOUND = "TURN_SOUND";
export const WINNER_SOUND = "WINNER_SOUND";
export const APPLAUSE_SOUND = "APPLAUSE_SOUND";

//game definition keys
// export const DEFINITION_ID = "gameDefId";
// export const DEFINITION_GAME_TYPE = "gameType";
// export const DEFINITION_GAME_NAME = "gameName";
// export const DEFINITION_BET = "bet";
// export const DEFINITION_WINNERS_COUNT = "winnersCount";
// export const DEFINITION_WINNING_AMOUNT = "winningAmount";
// export const DEFINITION_MAX_PLAYERS = "maxPlayers";
// export const DEFINITION_FIRST_PLAYER_WINNING_AMOUNT =
//   "firstPlayerWinningAmount";
// export const DEFINITION_SECOND_PLAYER_WINNING_AMOUNT =
//   "secondPlayerWinningAmount";
// export const DEFINITION_NUMBER_OF_ROUNDS = "noOfRounds";
// export const DEFINITION_NUMBER_OF_SKIPS = "noOfSkips";
// export const DEFINITION_ONLINE_PLAYER_COUNT = "onlinePlayerCnt";
// export const DEFINITION_BONUS_CAPPING = "bonusPercentage";
// export const DEFINITION_BONUS = "bonusAmount";
// export const DEFINITION_TOTAL_AMOUNT = "depositAndWinnings";
// export const DEFINITION_AMOUNT_TO_ADD = "amountToAdd";
// export const DEFINITION_STATUS = "bonusCapStatus";
// export const DEFINITION_GAME_STATUS = "status";
// export const DEFINITION_OPEN = "open";
// export const DEFINITION_CLOSE = "close";
// export const DEFINITION_ROUNDWISE_WINNINGS = "roundWinningAmount";

//Channels
// export const HPS_CHANNEL = "HPS";

//Game Connections
// export const GOOD_CONNECTION = "GoodConnection"; //green color
//var NORMAL_CONNECTION = "NormalConnection"; //yellow color (not there in mobile)
// export const WEAK_CONNECTION = "WeakConnection"; //red color
// export const DISCONNECTED = "Disconnected"; //cross icon on network symbol

export const NETWORK_ICON_GOOD = "networkIconGood";
export const NETWORK_ICON_AVERAGE = "networkIconAverage";
export const NETWORK_ICON_WEAK = "networkIconWeak";
export const NETWORK_ICON_DISCONNECTED = "networkIconDisconnected";

// export const SKIP_EXHAUSTED = "skipsExhausted";
// export const LEFT_TABLE = "leftTable";
// Audio names
// export const ACTIVE_TIME_SOUND = "ActiveTime";
// export const ACTIVE_TIMEOUT_SOUND = "ActiveTimeOutShort";
// export const DISCARD_SOUND = "DiscardSound";
// export const DRAW_SOUND = "DrawSound";
// export const SPLIT_SAVE_BET_SOUND = "SplitSaveBetSound";
// export const PLAYER_JOIN_SOUND = "PlayerJoinSound";
// export const WINNER_SOUND = "WinnerSound";
// export const ACTIVE_TIMEOUT_SHORT_SOUND = "ActiveTimeOutShort";
// export const WINNER_SOUND_SHORT = "WinnerSoundShort";
// export const DROP_SOUND = "DropSound";
// export const PLAYER_TURN_SOUND = "TurnSound";
// export const WRONG_SHOW = "WrongShow";

//Game Alerts
// export const LEAVE_TABLE_ALERT = "LeaveTableAlert";
// export const DISCONNECTED_ALERT = "DisconnectedAlert";
// export const DISQUALIFICATION_ALERT = "DisqualificationAlert";
// export const REFAIL_ALERT = "Refail";
// export const LOCATION_DENIED_ALERT = "LocationDeniedAlert";
// export const RECON_FAIL_ALERT = "ReconFailAlert"; // 800 status response
// export const CONNECTION_FAIL_ALERT = "ConnectionFailAlert";
// export const BROWSER_ACCESS_DENIED = "browserAccessDenied";
// export const DEVICE_UNSUPPORTED = "tabletUnsupported";
// export const INACTIVE_ALERT = "InactiveAlert";
// export const LOCKED_BET = "LockedBetAlert";

// Modal Constants
export const BUY_IN = "Buy In Modal";
export const HAND_RANKING = "Hand Ranking Modal";
export const LEAVE_TABLE = "Leave Table Modal";
export const LEAVE_SEAT = "Leave Seat Modal";
export const PLAYER_STATS = "Player Stats Modal";
export const INFORMATION = "Information Modal";
export const INSUFFICIENT_BALANCE = "Insufficient Balance Modal";
export const MAX_TABLE_LIMIT = "Maximum Table Limit";
export const MAX_FUN_GAMES_LIMIT = "Maximum Fun Games Limit";
export const INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT =
  "INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT";
export const DISCONNECTION = "Disconnection Modal";
export const FOLD_CONFIRM = "Fold Confirm";
export const NEW_TABLE = "New Table";
export const REBUY_IN = "REBUY_IN";
export const GAME_LOOSER_MODAL = "GAME_LOOSER_MODAL";
export const TOURNEY_GAME_WINNER_MODAL = "TOURNEY_GAME_WINNER_MODAL";
export const TOURNEY_GAME_COMPLETE = "TOURNEY_GAME_COMPLETE";
export const GAME_TABLE_WATCH_CHANNEL_EXIST = "GAME_TABLE_WATCH_CHANNEL_EXIST";
export const REPORT_AN_ISSUE = "REPORT_AN_ISSUE";
export const INSUFFICIENT_FUN_CHIPS_BALANCE = "INSUFFICIENT_FUN_CHIPS_BALANCE";
export const RESTRICT_FUN_CHIPS_RELOAD = "RESTRICT_FUN_CHIPS_RELOAD";
export const TOURNEY_REBUYIN_WITH_DOUBLE_REBUY =
  "TOURNEY_REBUYIN_WITH_DOUBLE_REBUY";
export const MAINTENANCE = "MAINTENANCE";
export const TOURNEY_ADDON = "TOURNEY_ADDON";
export const RAKEBACK_LEVEL_UPGRADE = "RAKEBACK_LEVEL_UPGRADE";
export const RESTRICTED_LOCATION = "RESTRICTED_LOCATION";
export const FORCE_LOGOUT = "ForceLogout";
export const ACCOUNT_ISSUE_GAME_JOIN = "ACCOUNT_ISSUE_GAME_JOIN";
export const WALLET_BALANCE_ISSUE_GAME_JOIN = "WALLET_BALANCE_ISSUE_GAME_JOIN";
export const DATA_LOST_ISSUE_GAME_JOIN = "DATA_LOST_ISSUE_GAME_JOIN";
export const DELETED_PLAYER_LOGOUT = "DELETED_PLAYER_LOGOUT";
export const RESTRICTED_LOCATION_GAME_EXIT = "RESTRICTED_LOCATION_GAME_EXIT";
export const RESTRICTED_ACCESS = "RESTRICTED_ACCESS";
export const TOURNEY_FAIRPLAY_POLICY_RESTRICTION =
  "TOURNEY_FAIRPLAY_POLICY_RESTRICTION";
export const TOURNEY_LATE_REGISTRATION_SUCCESS =
  "TOURNEY_LATE_REGISTRATION_SUCCESS";
export const TOURNEY_WATCH_GAME_COMPLETED = "TOURNEY_WATCH_GAME_COMPLETED";
export const UNSUPPORTED_DEVICE = "UNSUPPORTED_DEVICE";
export const UNSUPPORTED_DEVICE_FOLD_TABLET_MODE_DEVICE =
  "UNSUPPORTED_DEVICE_FOLD_TABLET_MODE_DEVICE";
export const ASSET_ERROR = "ASSET_ERROR";
export const TOURNEY_TICKET_RECEIVED_THROUGH_ADD_CASH =
  "TOURNEY_TICKET_RECEIVED_THROUGH_ADD_CASH";
export const JOIN_FAILURE_ACCOUNT_DELETED = "JOIN_FAILURE_ACCOUNT_DELETED";

// Slider Constants
export const THEMES = "Themes";
export const TABLE_SETTINGS = "Table Settings";
export const HAND_HISTORY = "Hand History";
export const TABLE_STATS = "Table Stats";
export const CONSOLIDATED_MENU = "Consolidated Menu";
export const TOURNEY_RANKING_LEADERBOARD = "TOURNEY_RANKING_LEADERBOARD";
export const TOURNEY_CANCEL_WITH_REFUND = "CANCELLED_WITH_REFUND";
export const TOURNEY_CANCEL_WITH_HOLD = "CANCELLED_WO_REFUND";
export const GAME_INFO = "GAME_INFO";
export const HAPPY_HOURS = "HAPPY_HOURS";

//Insufficient Balance Alert

// export const INSUFFICIENT_BALANCE_ALERT = "InsufficientBalanceAlert";
// export const PREMIUM_ACCESS_ALERT = "PremiumAccessAlert";
// export const PLAYER_STATUS_PSEUDO_LOCKED = "plocked";
// export const HIGHER_BETS_SOON_ALERT = "higherBets";

//Game To Lobby call backs
// export const CLOSE_GAME_WINDOW_CALL_BACK = "CloseGameWindowCallBack";
// export const CLEAR_LACK_GAMES_DATA = "CLEAR_LACK_GAMES_DATA";

//Game protocols to server

// export const START_ACK_PROTOCOL = "StartA#";
// export const DISCARD_PROTOCOL = "Disc#";
// export const DISCARD_ACK_PROTOCOL = "DiscA#";
// export const USER_PROFILE = "up#";
// export const CARDS_DATA_PROTOCOL = "cd#";
// export const DEBUG_LOG_PROTOCOL = "DB#";

//Game protocol from server
// export const CONNECT_ACK_GAME = "cona";
// export const PROFILE_MASK_PROTOCOL = "profilemask";
// export const START_GAME_PROTOCOL = "start";
// export const CUT_SEAT_PROTOCOL = "cfs";
// export const DISCARD_RESPONSE_PROTOCOL = "disc";
// export const HAND_WINNER_PROTOCOL = "hw";

//Heart beat type
// export const BID_SELECTION = "b";
// export const ACTIVE_PLAYER_TURN = "t";
// export const START_GAME_COUNTDOWN = "s";
// export const NEXT_ROUND_COUNTDOWN = "n";

// //Delay (in milliseconds) to execute protocols received from server
// export const DEFAULT_EXECUTION_TIME_FOR_Lobby_PROTOCOL = 0;
// export const DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL = 100;
// export const CUT_SEAT_EXECUTION_TIME =
//   700 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;
// export const START_GAME_EXECUTION_TIME =
//   4000 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;
// export const DISCARD_EXECUTION_TIME =
//   500 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;
// // export const HAND_WINNER_EXECUTION_TIME = 1000 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;
// export const HAND_WINNER_EXECUTION_TIME =
//   2400 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;

// //Game Join statuses
export const GAME_JOIN_API_READY_TO_REQUEST = "GAME_JOIN_API_READY_TO_REQUEST";
// export const GAME_JOIN_API_REQUEST_INITIATED =
//   "GAME_JOIN_API_REQUEST_INITIATED";
// export const GAME_JOIN_API_REQUEST_FULFILLED =
//   "GAME_JOIN_API_REQUEST_FULFILLED";
export const GAME_JOIN_API_RESPONSE_FAILURE = "GAME_JOIN_API_RESPONSE_FAILURE";
// export const GAME_JOIN_API_RESPONSE_SUCCESS = "GAME_JOIN_API_RESPONSE_SUCCESS";
// export const GAME_JOIN_API_RESPONSE_UNEXPECTED =
//   "GAME_JOIN_API_RESPONSE_UNEXPECTED";
// export const GAME_JOIN_API_REQUEST_REJECTED = "GAME_JOIN_API_REQUEST_REJECTED";
// export const GAME_SOCKET_INITIATED = "GAME_SOCKET_INITIATED";
// export const GAME_SOCKET_CONNECTED = "GAME_SOCKET_CONNECTED";
// export const GAME_SOCKET_DISCONNECTED = "GAME_SOCKET_DISCONNECTED";
// export const GAME_SOCKET_TIMEOUT = "GAME_SOCKET_TIMEOUT";
// export const GAME_SOCKET_REMOVAL = "GAME_SOCKET_REMOVAL";
// export const GAME_JOIN_API_TERMINATED = "GAME_JOIN_API_TERMINATED";

// // balance
// export const MAX_BALANCE_RANGE = 1000;

// // Discard types
// export const PLAYER_DISCARD = "P";
// export const AUTO_DISCARD = "A";

// // Orientation ack
// export const LANDSCAPE_ACK = "landscapeAck";
// export const PORTRAIT_ACK = "portraitAck";

// // Minimum iOS verison support
// export const MIN_DEVICE_IOS_VERSION = 15;
// export const MIN_ANIMATION_IOS_VERSION = 15;

// export const TRANSACTION_STATUS = "success";

export const COUNTER_KEY_NAME = "counter";
export const REQUEST_KEY_NAME = "request";
export const RESPONSE_KEY_NAME = "response";
export const RESPONSE_CODE_KEY_NAME = "responseCode";
export const REJECTED_KEY_NAME = "rejected";

export const SEQUENTIAL_HOLD = "SEQUENTIAL_HOLD";
export const SEQUENTIAL_START = "SEQUENTIAL_START";
export const SEQUENTIAL_FINISH = "SEQUENTIAL_FINISH";
export const SEQUENTIAL_RESET = "SEQUENTIAL_RESET";

export const TIMEOUT_CONNECTION = "TIMEOUT_CONNECTION";

//Match making status
export const MATCH_MAKING_API = "MATCH_MAKING_API";
export const RELOGIN_GAME_CONNECTION = "RELOGIN_GAME_CONNECTION";
export const MATCH_MAKING_API_READY_TO_REQUEST =
  "MATCH_MAKING_API_READY_TO_REQUEST";
export const MATCH_MAKING_API_REQUEST_INITIATED =
  "MATCH_MAKING_API_REQUEST_INITIATED";
// export const MATCH_MAKING_API_REQUEST_FULFILLED = "MATCH_MAKING_API_REQUEST_FULFILLED";
export const MATCH_MAKING_API_REQUEST_REJECTED =
  "MATCH_MAKING_API_REQUEST_REJECTED";
export const MATCH_MAKING_API_RESPONSE_SUCCESS =
  "MATCH_MAKING_API_REQUEST_SUCCESS";
// export const MATCH_MAKING_API_RESPONSE_FAILURE =
//   "MATCH_MAKING_API_REQUEST_FAILURE";
export const MATCH_MAKING_API_RESPONSE_UNEXPECTED =
  "MATCH_MAKING_API_RESPONSE_UNEXPECTED";
export const MATCH_MAKING_API_INSUFFICIENT_BALANCE =
  "MATCH_MAKING_API_INSUFFICIENT_BALANCE";
export const MATCH_MAKING_BET_DISABLED = "MATCH_MAKING_BET_DISABLED";
export const MATCH_MAKING_ACCOUNT_ISSUE = "MATCH_MAKING_ACCOUNT_ISSUE";

//Check game status
export const CHECK_GAME_API = "CHECK_GAME_API";
export const CHECK_GAME_API_READY_TO_REQUEST =
  "CHECK_GAME_API_READY_TO_REQUEST";
export const CHECK_GAME_API_REQUEST_INITIATED =
  "CHECK_GAME_API_REQUEST_INITIATED";
// export const CHECK_GAME_API_REQUEST_FULFILLED = "CHECK_GAME_API_REQUEST_FULFILLED";
export const CHECK_GAME_API_REQUEST_REJECTED =
  "CHECK_GAME_API_REQUEST_REJECTED";
export const CHECK_GAME_API_RESPONSE_SUCCESS =
  "CHECK_GAME_API_RESPONSE_SUCCESS";
// export const CHECK_GAME_API_RESPONSE_FAILURE =
//   "CHECK_GAME_API_RESPONSE_FAILURE";
export const CHECK_GAME_API_RESPONSE_UNEXPECTED =
  "CHECK_GAME_API_RESPONSE_UNEXPECTED";
export const CHECK_GAME_ACCOUNT_ISSUE = "CHECK_GAME_ACCOUNT_ISSUE";
export const NODE_MISMATCH_ERROR = "NODE_MISMATCH_ERROR";

export const INITIATE_LACK_GAME_CONNECTION = "INITIATE_LACK_GAME_CONNECTION";

//Tourney game status
export const TOURNEY_GAME_CONNECTION_STATE = "TOURNEY_GAME_CONNECTION_STATE";
export const TOURNEY_GAME_RECONNECTION_STATE =
  "TOURNEY_GAME_RECONNECTION_STATE";
export const INITIATE_TOURNEY_GAME_CONNECTION =
  "INITIATE_TOURNEY_GAME_CONNECTION";
export const INITIATE_TOURNEY_GAME_RECONNECTION =
  "INITIATE_TOURNEY_GAME_RECONNECTION";

//Game socket status
export const GAME_SOCKET_INITIATED = "GAME_SOCKET_INITIATED";
export const GAME_SOCKET_CONNECTED = "GAME_SOCKET_CONNECTED";
export const GAME_SOCKET_RECONNECTED = "GAME_SOCKET_RECONNECTED";
export const GAME_SOCKET_DISCONNECTED = "GAME_SOCKET_DISCONNECTED";
export const GAME_SOCKET_TIMEOUT = "GAME_SOCKET_TIMEOUT";
export const GAME_SOCKET_REMOVED = "GAME_SOCKET_REMOVED";
export const RELOGIN_FAILURE = "RELOGIN_FAILURE";
export const GAME_SOCKET_ERROR = "GAME_SOCKET_ERROR";
export const CONNECTION_REQUESTS_LIMIT_REACHED =
  "CONNECTION_REQUESTS_LIMIT_REACHED";
export const CONNECTION_TIMEOUT_GAME_JOIN = "CONNECTION_TIMEOUT_GAME_JOIN";

export const GAME_JOIN_API_TERMINATED = "GAME_JOIN_API_TERMINATED";
export const GAME_CONNECTION_PROTOCOL_INITIATE =
  "GAME_CONNECTION_PROTOCOL_INITIATE";
export const GAME_CONNECTION_PROTOCOL_SUCCESS =
  "GAME_CONNECTION_PROTOCOL_SUCCESS";
export const GAME_AWS_COOKIE_PROTOCOL_INITIATE =
  "GAME_AWS_COOKIE_PROTOCOL_INITIATE";
export const GAME_AWS_COOKIE_PROTOCOL_SUCCESS =
  "GAME_AWS_COOKIE_PROTOCOL_SUCCESS";
export const TOURNEY_GAME_CONNECTION_INITIATE =
  "TOURNEY_GAME_CONNECTION_INITIATE";
export const AXIOS_NETWORK_ERROR_MESSAGE = "Network Error";

export const CLOSE_GAME_WINDOW_CALL_BACK = "CLOSE_GAME_WINDOW_CALL_BACK";
export const HIDE_GAME_WINDOW_CALL_BACK = "HIDE_GAME_WINDOW_CALL_BACK";

// Leave table status
export const EXIT_TABLE_ACK_AWAITED = "awaiting";
export const EXIT_TABLE_ACK_SUCCESS = "success";
export const EXIT_TABLE_ACK_FAILURE = "failure";
export const EXIT_TABLE_ACK_CLEAR = "clear";

// Leave seat status

export const LEAVE_SEAT_ACK_AWAITED = "awaiting";
export const LEAVE_SEAT_ACK_SUCCESS = 100;
export const LEAVE_SEAT_ACK_PROCESSING = 104;
export const LEAVE_SEAT_ACK_REQUEST_ALREADY_SENT = 103;
export const LEAVE_SEAT_ACK_FAILURE = "failure";
export const LEAVE_SEAT_ACK_CLEAR = "clear";

// Timer

export const TIMER_TYPE_BUY_IN = "buy-in";
export const TIMER_TYPE_PLAYER = "player";

//Game stages
export const GAME_STAGE_PRE_FLOP = "Pre_FLOP";
export const GAME_STAGE_FLOP = "FLOP";
export const GAME_STAGE_TURN = "TURN";
export const GAME_STAGE_RIVER = "RIVER";
export const GAME_STAGE_SHOWDOWN = "SHOWDOWN";

// card type
export const CARD_TYPE_TURN = "TURN";
export const CARD_TYPE_RIVER = "RIVER";

// Game Actions

export const CALL_ACTION = "call";
export const CHECK_ACTION = "check";
export const FOLD_ACTION = "fold";
export const RAISE_ACTION = "raise";

export const GAME_RAISESLIDER_PRE_FLOP_VALUES = [
  "All In",
  "4x BB",
  "3x BB",
  "2.5x BB",
  "1x BB",
];

export const GAME_RAISESLIDER_POST_FLOP_VALUES = [
  "All In",
  "2x Pot",
  "Pot",
  "75% Pot",
  "50% Pot",
  "25% Pot",
];

// hand ranks
export const HIGH_CARD = "High Card";
export const ONE_PAIR = "One Pair";
export const TWO_PAIRS = "Two pairs";
export const THREE_OF_A_KIND = "Three of a kind";
export const STRAIGHT = "Straight";
export const FLUSH = "Flush";
export const FULL_HOUSE = "Full House";
export const FOUR_OF_A_KIND = "Four of a kind";
export const STRAIGHT_FLUSH = "Straight Flush";
export const ROYAL_FLUSH = "Royal Flush";

// ORIENTATION
export const PORTRAIT = "PORTRAIT";
export const LANDSCAPE = "LANDSCAPE";

// PLAYER STATUS
export const ACTIVE = 303;
export const IN_ACTIVE = 304;
export const LEAVETABLE_IN_PROCESS = 401;
export const SEATED_OUT = 402;
export const REBUY_IN_PROCESS = 403;
export const TAKESEAT_IN_PROCESS = 404;
export const WAITING_FOR_BB = 405;
export const WAITING_FOR_GAME = 406;
export const FOLDED = 407;
export const WAITING_FOR_NEXT_GAME = 408;
export const ALL_IN = 409;
export const ACTIVE_G = 410;
// PROTOCOLS
export const START_GAME_PROTOCOL = "start";
export const REVEAL_CARDS = "revealcard";
export const POT_WINNER = "potwinner";
export const CALL_A = "calla";
export const CALL_B = "callb";
export const RAISE_A = "raisea";
export const RAISE_B = "raiseb";
export const PRIZE_POT = "prizepot";
export const RESULT = "result";
export const REFRESH_PROTOCOL = "refresh";
export const RABBIT_HUNTING_PROTOCOL = "rabbith";
export const TOURNEY_BREAK_BROADCAST = "breakbroadcast";
export const TOURNEY_ADDON_BREAK = "tourneyaddon";
export const NEXT_GAME_CANCEL = "ngc";

// ANIMATION TIME
export const DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL = 100;

export const TEXAS_START_GAME_EXECUTION_TIME =
  1000 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;

export const OMAHA_START_GAME_EXECUTION_TIME =
  1000 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;

export const OMAHA5_START_GAME_EXECUTION_TIME =
  1000 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;

export const REVEAL_FLOP_CARDS_EXECUTION_TIME = 600;

export const DIRECT_SHOWDOWN_REVEAL_COMMUNITY_CARDS_EXECUTION_TIME = 600;

export const REVEAL_TURN_RIVER_CARDS_EXECUTION_TIME = 350;

export const CHIP_ANIMATION_EXECUTION_TIME =
  300 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;

export const PRIZE_POT_EXECUTION_TIME =
  400 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;

export const POT_WINNER_EXECUTION_TIME =
  1500 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;

export const RESULT_ANIMATION_EXECUTION_TIME =
  100 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;
// add delay after result

export const REFRESH_EXECUTION_TIME =
  500 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;

export const RABBIT_HUNTING_PROTOCOL_EXECUTION_TIME =
  200 + DEFAULT_EXECUTION_TIME_FOR_GAME_PROTOCOL;

export const MOVE_PLAYER_BETS_TO_CENTER_ANIMATION_TIME_IN_MS = 350;

export const TOURNEY_BREAK_BROADCAST_EXECUTION_TIME_IN_MS = 5000;

export const TOURNEY_TPS_REQUEST_WITH_DELAY = 3000;
export const CONVERT_CHIPS_TO_BB_IN_LONG_PRESS_TIME_IN_MS = 1000;

//VIBRATION CONSTANTS
export const VIBRATE_LIGHT = "light";
export const VIBRATE_MEDIUM = "medium";
export const VIBRATE_HEAVY = "heavy";
export const VIBRATE_SUCCESS = "success";
export const VIBRATE_ERROR = "success";
export const VIBRATE_WARNING = "warning";

export const PLAYER_DEFAULT_TIMER = "PLAYER_DEFAULT_TIMER";
export const PLAYER_EXTRA_TIMER = "PLAYER_EXTRA_TIMER";
export const DISCONNECTION_TIMER = "DISCONNECTION_TIMER";

export const EXTRA_TIME = "Extra Time";
export const DISCONNECTED = "Disconnected";

//LOCALSTORAGE CONSTANTS
export const IS_HAPTICS_SUPPORT = "IS_HAPTICS_SUPPORT";
export const IS_SOUND_ENABLED = "IS_SOUND_ENABLED";
export const IS_HAPTICS_EBABLED = "IS_HAPTICS_EBABLED";

//REBUY AND AUTO REBUYIN CONSTANTS:
export const REBUY_REBUY_IN_SUCCESS = 501;
export const REBUY_INSUFFICIENT_FUNDS = 502;
export const REBUY_OTHER = 503;

//AUTO USER ACTIONS CONSTANTS

export const AUTO_CALL_ACTION = "CALL";
export const AUTO_CHECK_ACTION = "CHECK";
export const AUTO_CHECK_OR_FOLD_ACTION = "FOLDCHECK";
export const AUTO_FOLD_ACTION = "FOLD";
export const AUTO_CALL_ANY_ACTION = "CALLANY";
export const JOIN_WAITLIST_ACTION = "joinWaitList";

//  AUTO ACTION KEY
export const AUTO_CHECK = "auto check";
export const AUTO_FOLD = "auto fold";

//TOAST MESSAGES
export const TOAST_SUCCESS = "TOAST_SUCCESS";
export const TOAST_WARNING = "TOAST_WARNING";
export const TOAST_INFO = "TOAST_INFO";
export const TOAST_ERROR = "TOAST_ERROR";

// MAX CARD WIDTH
export const MAX_CARD_WIDTH = 40;

//TOURNEY INFO TABS
export const TOURNEY_INFO_DETAILS_TAB = "TOURNEY_INFO_DETAILS_TAB";
export const TOURNEY_INFO_ENTRIES_TAB = "TOURNEY_INFO_ENTRIES_TAB";
export const TOURNEY_INFO_TABLES_TAB = "TOURNEY_INFO_TABLES_TAB";

//TOURNEY RANKING LEADERBOARD TABS
export const TOURNEY_RANKING_TAB = "TOURNEY_RANKING_TAB";
export const TOURNEY_PRIZE_TAB = "TOURNEY_PRIZE_TAB";
export const TOURNEY_TABLES_TAB = "TOURNEY_TABLES_TAB";
export const TOURNEY_BLINDS_TAB = "TOURNEY_BLINDS_TAB";

//TOURNEY REGISTRATION STATUS
//ANNOUNCED, REGISTRATION_STARTED, REGISTRATION_ENDED, LIVE, COMPLETED, CANCELLED

export const TOURNEY_ANNOUNCED = "ANNOUNCED";
export const TOURNEY_REGISTRATION_STARTED = "REGISTRATION_STARTED";
export const TOURNEY_REGISTERED = "Registered";

export const TOURNEY_REGISTRATION_ENDED = "REGISTRATION_ENDED";
export const TOURNEY_STATUS_LIVE = "LIVE";
export const TOURNEY_STATUS_COMPLETED = "COMPLETED";
export const TOURNEY_STATUS_CANCELLED = "CANCELLED";
export const TOURNEY_STATUS_WON = "Won";
export const TOURNEY_STATUS_MISSED = "Missed";
export const TOURNEY_STATUS_LATE_REGISTRATION = "LATE REG";

//TOURNEY BACKEND CONSTANTS FOR TOURNEY_PLAYER_STATUS
export const TOURNEY_PLAYER_STATUS_WON = "Won";
export const TOURNEY_PLAYER_STATUS_COMPLETED = "Completed";
export const TOURNEY_PLAYER_STATUS_CANCELLED = "Cancelled";
export const TOURNEY_PLAYER_STATUS_MISSED = "Missed";
export const TOURNEY_PLAYER_STATUS_REGISTERED = "Registered";
export const TOURNEY_PLAYER_STATUS_RE_ENTRY = "ReEntry";

//NUMBER OF COMMUNITY CARDS BASED ON GAME TYPE
export const TEXAS_COMMUNITY_CARDS_NUMBER = 5;

// CARD SIZE
export const CARD_SIZE_SMALL = "Small";
export const CARD_SIZE_MEDIUM = "Medium";
export const CARD_SIZE_LARGE = "Large";

// flip Card types
export const CARD_TYPE_PLAYER_CARDS = "playerCards";
export const CARD_TYPE_COMMUNITY_CARDS = "communityCards";

// Maximum length of username

export const MAX_USERNAME_LENGTH = 10;

//Tourney register failure message codes
export const TOURNEY_REGISTRATION_FAILED_DUE_TO_FAIR_PLAY_POLICY = 122;
export const REGISTER_SUCCESS = 600;
export const REGISTER_ACK_NULL = 601;
export const TOURNEY_CHECK_FAILED = 602;
export const A23_TOKEN_DECRYPTION_FAILED_IN_REGISTER = 603;
export const ERROR_IN_REGISTER_CONTROLLER = 604;
export const LOW_BALANCE = 605;
export const TOURNEY_REGISTRATIONS_FULL = 606;
export const TOURNEY_IN_THE_BUBBLE = 607;
export const TOURNEY_DIRECT_ENTRIES_FULL = 608;
export const TOURNEY_TICKET_ENTRIES_FULL = 609;

export const TOURNEY_RE_ENTRY_SUCCESS = 800;
export const TOURNEY_RE_ENTRY_COUNT_EXCEEDED = 801;
export const TOURNEY_RE_ENTRY_LEVEL_EXCEEDED = 802;
export const TOURNEY_RE_ENTRY_LOW_BALANCE = 803;
export const TOURNEY_RE_ENTRY_PLAYER_NOT_REGISTERED = 804;
export const TOURNEY_RE_ENTRY_PLAYER_NOT_BUSTED_OUT = 805;
export const TOURNEY_RE_ENTRY_IN_THE_MONEY = 806;
export const TOURNEY_RE_ENTRY_TOURNEY_NOT_FOUND = 807;
export const TOURNEY_RE_ENTRY_PROCESSING_FAILED = 809;

export const GENERAL_FAILURE = 400;
export const GENERAL_FAILURE_MESSAGE = 401;

//Tourney de-register failure message codes
export const DE_REGISTER_SUCCESS = 700;
export const DE_REGISTER_ACK_NULL = 701;
export const PLAYER_NOT_FOUND_IN_TOURNEY = 702;
export const A23_TOKEN_DECRYPTION_FAILED_IN_DE_REGISTER = 703;
export const ERROR_IN_DE_REGISTER_CONTROLLER = 704;
export const TOURNEY_STARTED = 705;

//TOURNEY BANNER CONSTANTS
export const TOURNEY_BANNER_BLINDS_UP = "TOURNEY_BANNER_BLINDS_UP";
export const TOURNEY_BANNER_BREAK_WAITING_FOR_OTHER_TABLES =
  "TOURNEY_BANNER_BREAK_WAITING_FOR_OTHER_TABLES";
export const TOURNEY_BANNER_ADDON_BREAK_WAITING_FOR_OTHER_TABLES =
  "TOURNEY_BANNER_ADDON_BREAK_WAITING_FOR_OTHER_TABLES";
export const TOURNEY_BANNER_BREAK_TIME = "TOURNEY_BANNER_BREAK_TIME";
export const TOURNEY_BANNER_FINAL_TABLE_BUBBLE =
  "TOURNEY_BANNER_FINAL_TABLE_BUBBLE";
export const TOURNEY_BANNER_MOVED_TO_ANOTHER_TABLE =
  "TOURNEY_BANNER_MOVED_TO_ANOTHER_TABLE";
export const TOURNEY_BANNER_MERGED_WITH_ANOTHER_TABLE =
  "TOURNEY_BANNER_MERGED_WITH_ANOTHER_TABLE";
export const TOURNEY_BANNER_IN_WINNING_POSITIONS =
  "TOURNEY_BANNER_IN_WINNING_POSITIONS";
export const TOURNEY_WAIT_UNTIL_HAND_COMPLETES_ON_OTHER_TABLE =
  "TOURNEY_WAIT_UNTIL_HAND_COMPLETES_ON_OTHER_TABLE";
export const TOURNEY_BANNER_WAITING_FOR_REBUYS =
  "TOURNEY_BANNER_WAITING_FOR_REBUYS";
export const TOURNEY_BANNER_NODE_SWITCH = "TOURNEY_BANNER_NODE_SWITCH";
export const TOURNEY_BANNER_MERGE_WAIT_MESSAGE =
  "TOURNEY_BANNER_MERGE_WAIT_MESSAGE";
//LEADERBOARD
export const MONTHS = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};
export const FLAVOUR_TYPES = {
  real: 1,
  tourney: 2,
  play: 4,
  private: 7,
  mega: 11,
};

export const GAME_TYPES = {
  TEXAS_HOLDEM: 1,
  OMAHA: 2,
  OMAHA5: 17,
};
export const TOURNEY_MAP = {
  GTD: 1,
  "Free Roll": 2,
  Timer: 3,
  Satellite: 5,
  "Sit And Go": 6,
  "Ticket Based": 7,
};
export const TOURNEY_TYPES = {
  GTD: "GTD",
  "FREE ROLLS": "Free Roll",
  "TIMER TOURNAMENT": "Timer",
  SATELLITE: "Satellite",
  SIT_AND_GO: "Sit And Go",
  TICKET: "Ticket Based",
};

//lobby
export const PSEUDO_LOCKED_PLAYER = "plocked";
export const PSEUDO_EXPIRED_PLAYER = "pexpired";
export const DELETED_PLAYER = "deleted";
export const TO_BE_DELETED_PLAYER = "tobedeleted";
export const TEST_ACCOUNT_PLAYER = "testaccount";
export const PLAYER_STATUS_NOT_AVAILABLE = "NA";

//fun games
export const MIN_RELOAD_CHIPS_LIMIT = 1000;

//  total number of maximum players
export const TWO_PLAYERS_TABLE = 2;
export const SIX_PLAYERS_TABLE = 6;
export const NINE_PLAYERS_TABLE = 9;

export const CASH_LOBBY = "CashLobby";
export const TOURNEY_LOBBY = "TourneyLobby";
export const GAME_TABLE = "GameTable";
export const TOURNEY_REGISTRATION = "TourneyReg";
export const HOME_LOBBY = "OpenHomeLobby";
export const CASH_GRID = "CashGrid";
export const POKER_LEADERBOARD = "openPokerLeaderboard";
export const RAKEBACK_PAGE = "openRakeBack";
export const MISSIONS_PAGE = "openPokerMission";
export const MY_TOURNEY_TAB = "MyTourney";
export const ACTIVE_TAB = "Active";
export const COMPLETED_TAB = "Completed";
export const ALL_TAB = "All";
export const GTD = "GTD";
export const TICKETS = "Ticket";
export const TICKET_BASED = "Ticket Based";
export const SIT_N_GO = "Sit&Go";
export const FREEROLL = "FreeRoll";
export const SATELLITE = "Satellite";
export const TEXAS = "Texas";
export const PLO = "PLO";
export const PLO5 = "PLO5";
export const LEARN_POKER = "LearnPoker";
export const MY_WALLET = "MyWallet";
export const ADD_CASH = "AddCash";
export const MY_PROFILE_DETAILS = "MyProfileDetails";
export const REFER_AND_EARN = "Refer&Earn";
export const GIFT_VOUCHER = "GiftVoucher";
export const REDEEM_ACE_POINTS = "RedeemAcePoints";
export const LANGUAGE_PREFERENCES = "LanguagePreferences";
export const REDEEM_OPTIONS = "RedeemOptions";
export const BONUS_SUMMARY = "BonusSummary";
export const PURCHASE_LIMITS = "PurchaseLimits";
export const CHANGE_PASSWORD = "ChangePassword";

export const RUPEE_SYMBOL = "₹";
export const MYTICKET = "MyTicket";

export const FUN_CHANNELS = ["HPS", "PHPS"];
export const REAL_MONEY_CHANNELS = [
  "A23APS",
  "A23IPS",
  "PAPS",
  "PIPS",
  "IPAPS",
  "IPIPS",
];
export const PLATFORM_CHANNELS = ["A23APS", "A23IPS", "PAPS", "PIPS"];
export const STANDALONE = ["IPAPS", "IPIPS"];

export const LOGOUT_WARNING = "LOGOUT_WARNING";

export const REDIRECTION_FAIL = "This redirection is not available anymore";

export const CARDS_PREFIX = {
  SPADE: "s",
  CLUB: "c",
  DIAMOND: "d",
  HEART: "h",
};

export const POKER_ASSET_LOAD_FAIL = "POKER_ASSET_LOAD_FAIL";

export const TOURNEY_TABLE_MERGE_BANNER_DELAY = 1500;

export const TOURNEY_REGISTRATION_CLOSE_BEFORE_START_IN_SECONDS = 15;

export const NEXT_GAME_CANCEL_DELAY = 3000;

//RSA ENCRYPTION FAILURE CODES
export const RSA_INVALID_VENDOR = 5033;
export const RSA_DYCRYPTION_FAILED = 5034;
export const RSA_EMPTY_DATA_ENCRYPTION_FAILURE = "SyntaxError";

export const RSA_FAILURE_STATUS_ERROR_CODES = [
  RSA_INVALID_VENDOR,
  RSA_DYCRYPTION_FAILED,
  RSA_EMPTY_DATA_ENCRYPTION_FAILURE,
];

export const ACKNOWLEDGE_HIGH_STRENGTH_WIN = "ACKNOWLEDGE_HIGH_STRENGTH_WIN";
export const DEEPLINK_TOURNEY_VARIANT_MAPS = {
  All: "All",
  GTD: "GTD",
  "Free Roll": "Free Roll",
  FreeRoll: "Free Roll",
  Timer: "Timer",
  Satellite: "Satellite",
  "Sit & Go": "Sit And Go",
  "Sit&Go": "Sit And Go",
  Ticket: "Ticket Based",
};

export const DEEPLINK_TOURNEY_TAB_MAPS = {
  active: ACTIVE_TOURNEYS,
  completed: COMPLETED_TOURNEYS,
  mytourney: MY_TOURNEYS,
};
