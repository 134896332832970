import React, {
  useState,
  useEffect,
  useRef,
  memo,
  // lazy,
  Suspense,
} from "react";
import cashRefresh from "../../../assets/player/cashRefresh.svg";
import {
  ButtonWrapper,
  CenterSpacing,
  Container,
  GameTableBackgroundImage,
  GameTableImageWrapper,
  LandscapeButtonWrapper,
  LeftActions,
  MaskingLayer,
  RelativeWrapper,
  RightActions,
  LoadingWrapper,
  LoadingText,
  DisconnectionResult,
  CloseGameButton,
  PlayNextWrapper,
  JoinBackButton,
  JoinBackWrapper,
  JoinBackText,
  ReleaseToFoldAlertContainer,
  ReleaseToFoldText,
  CommonButtons,
  HandHistoryButton,
  ButtonImage,
  GameButton,
  TopupButton,
  AddCashButton,
  GoToLobbyButton,
  WaitListPlayerCount,
  IconsColumn,
  HappyHoursTimerText,
} from "./gameTableStyles";
import {
  GamePlayButton,
  StandByGamePlayButton,
} from "../../../Common/components/Buttons/GamePlay/GamePlayButton";
import colors from "../../../Common/styleGuide/Colors";
import * as gameAssets from "./gameAssets";
import { useDispatch, useSelector } from "react-redux";
import maskingLayerImg from "../../../assets/NewPokerTables/Masking Layer.svg";
import { motion } from "framer-motion";
import {
  updateGameTableBoundingRect,
  updatePositionsArranged,
  updateDisableGameActions,
  toggleSitOutAction,
  updateInSufficientFundsModalOpenStatus,
  updateTopupModalOpenStatus,
  setToastMessage,
  openLocalGameModal,
  closeLocalGameModal,
  hidePlayerPositions,
  startGameListenerN,
  updateUserAutoActionPreference,
  clearHandHistoryIndex,
  enableMultitableSwipe,
  cleanUpTableObservers,
  updateAmountToAdd,
  setHappyHoursInfoLoading,
  setPlayerAvatar,
} from "../../redux/slices/gameTableSlice";
import {
  GAME_CONNECTION_PROTOCOL_SUCCESS,
  GAME_JOIN_API_RESPONSE_FAILURE,
  BUY_IN,
  FOLD_CONFIRM,
  RAISE_BAR_SOUND,
  MATCH_MAKING_API_REQUEST_REJECTED,
  CHECK_GAME_API_REQUEST_REJECTED,
  INSUFFICIENT_BALANCE,
  NODE_MISMATCH_ERROR,
  AUTO_CHECK_OR_FOLD_ACTION,
  AUTO_CALL_ACTION,
  AUTO_FOLD_ACTION,
  AUTO_CHECK_ACTION,
  AUTO_CALL_ANY_ACTION,
  INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT,
  REBUY_IN,
  TOAST_INFO,
  DISCONNECTION,
  MATCH_MAKING_API_INSUFFICIENT_BALANCE,
  MATCH_MAKING_BET_DISABLED,
  RUPEE_SYMBOL,
  JOIN_WAITLIST_ACTION,
  NEW_TABLE,
  HAND_HISTORY,
  GAME_SOCKET_REMOVED,
  CONNECTION_REQUESTS_LIMIT_REACHED,
  MATCH_MAKING_ACCOUNT_ISSUE,
  HAPPY_HOURS,
} from "../../data/Constants";
import { GameDetails } from "../../components/GameDetails/GameDetails";
import { Toast } from "../../../Common/components/Toast/Toast";
import { Loader } from "../../components/Loader/Loader";
import { NetworkLottie } from "../../components/NetworkLottie/NetworkLottie";
import { SitOutTimer } from "../../../Common/utils/SitOutTimer";
import GameAudio from "../../audio/GameAudio";
import TakeSeatAlert from "../../../Common/components/Alert/TakeSeatAlert";
import RaiseActionRevamp from "../../../Common/components/Raise/RaiseAction";
import Modal from "../../../Common/components/Modals/Modal";
import {
  removeFromWaitListProtocol,
  sendAutoCallAnyProtocol,
  sendAutoCallProtocol,
  sendAutoCheck,
  sendAutoCheckFold,
  sendAutoFold,
  sendCallProtocol,
  sendCheckProtocol,
  sendFoldProtocol,
  sendHandHistoryProtocol,
  sendHappyHoursTableInfoRequest,
  sendJoinWaitlistProtocol,
  sendLobbyDebugProtocol,
  sendRaiseProtocol,
  sendTableStatsProtocol,
  sendWaitForBB,
  switchOffTourneyAutoPlay,
} from "../../utils/ProtocolUtils";
import { CardsLoader } from "../../components/Loader/CardsLoader";
import TableUtils from "../../utils/TableUtils";
import TourneyCommonButtons from "../../components/TourneyCommonButtons/TourneyCommonButtons";
import TourneyGameBanners from "../../components/TourneyGameBanners/TourneyGameBanners";
import { isIOS } from "react-device-detect";

import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  getISTDateTimeFormat,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import { Leaderboard } from "../../../Common/components/Buttons/Leaderboard/Leaderboard";
import { useSwiper, useSwiperSlide } from "swiper/react";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import { AddCashLottie } from "../../components/AddCashLottie/AddCashLottie";
import Maintenance from "../../../Common/components/Modals/MaintenancePopup/Maintenance";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import { IP_Client_GameTable_View } from "../../data/ClevertapConstants";
import { openSlider } from "../../redux/slices/sliderSlice";

import PlayerView from "../../components/Player/PlayerView";
import WinnerHandType from "../../components/WinnerHandType/WinnerHandType";
import CommunityCards from "../../components/Card/CommunityCards";
import { sendFaroLog } from "../../../Common/utils/FaroUtil.js";
import FramerPotAmount from "../../components/PotAmount/FramerPotAmount";
import { returnGameTypeText } from "../../../Common/utils/PokerUtils.js";
import GameButtonWithTimer from "../../components/HappyHoursGameButton/HappyHoursGameButton.jsx";
import defaultAvatar from "../../../assets/gameTable/defaultAvatar.png";

const Game = (props) => {
  const {
    tempTableId,
    activeGame,
    canUserSwipe,
    token,
    numberOfGames,
    activeGames,
  } = props;
  const applicationVisibility = useSelector(
    (state) => state.lobby.applicationVisibility
  );
  // const allGames = useSelector((state) => state.gameTable.games);
  const currentGameData = useSelector(
    (state) => state.gameTable.games[tempTableId]
  );
  const gameDefinition = useSelector(
    (state) => state.gameTable.games[tempTableId].gameDefinition
  );
  const isReset = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isReset
  );

  const isTourneyNodeSwitch = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isTourneyNodeSwitch
  );

  const showCheck = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showCheck
  );
  const showFold = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showFold
  );
  const showAllIn = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showAllIn
  );
  const showRaise = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showRaise
  );
  const showDefaultActions = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showDefaultActions
  );
  const showAutoActions = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showAutoActions
  );
  const showCall = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showCall
  );
  // const callAmount = useSelector(
  //   (state) => state.gameTable.games[tempTableId]?.callAmount
  // );
  const fold = useSelector((state) => state.gameTable.games[tempTableId]?.fold);
  const autoCheckOrFold = useSelector(
    (state) => state.gameTable.games[tempTableId]?.autoCheckOrFold
  );
  const autoCall = useSelector(
    (state) => state.gameTable.games[tempTableId]?.autoCall
  );
  const autoCheck = useSelector(
    (state) => state.gameTable.games[tempTableId]?.autoCheck
  );
  const autoCallAny = useSelector(
    (state) => state.gameTable.games[tempTableId]?.autoCallAny
  );
  const tourneyAutoPlay = useSelector(
    (state) => state.gameTable.games[tempTableId]?.tourneyAutoPlay
  );
  const [gameTableLoaded, setGameTableLoaded] = useState(false);
  const [gameConnection, setGameConnection] = useState(false);
  const dispatch = useDispatch();
  const gameTableImageRef = useRef(null);
  const gameOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  // const backgroundTheme = useSelector(
  //   (state) => state.gameTable.games[tempTableId]?.themeData?.background
  // );
  // const tableTheme = useSelector(
  //   (state) => state.gameTable.games[tempTableId]?.themeData?.table
  // );
  const backgroundTheme = useSelector(
    (state) => state.gameTable.themeData?.background
  );
  const tableTheme = useSelector((state) => state.gameTable.themeData?.table);
  const swipeDirection = useSelector((state) => state.gameTable.swipeDirection);
  const playerSeat = useSelector(
    (state) => state.gameTable.games[tempTableId].playerSeat
  );
  const autoAction = useSelector(
    (state) => state.gameTable.games[tempTableId].autoAction
  );
  const playersOnTable = useSelector(
    (state) => state.gameTable.games[tempTableId].playerData
  );
  const isPlaySoundEnabled = useSelector(
    (state) => state.gameTable.tableSettings?.sound
  );
  const { smartGestureOptions } = useSelector(
    (state) => state.gameTable.tableSettings
  );
  const playerData = useSelector((state) => state.lobby.player);
  const userName = useSelector((state) => state.lobby.player?.userName);
  // const userId = useSelector((state) => state.lobby.player?.userId);
  const [showHandHistoryMenu, setShowHandHistoryMenu] = useState(false);
  const [showExitTableOptionMenu, setShowExitTableOptionMenu] = useState(false);
  const [showHamburgerOptionMenu, setShowHamburgerOptionMenu] = useState(false);
  const [showRaiseAmountOptionMenu, setShowRaiseAmountOptionMenu] =
    useState(false);
  const [isRaiseActionKeyboardOpened, setIsRaiseActionKeyboardOpened] =
    useState(false);
  const channel = useSelector((state) => state.lobby.channel);
  const gameConnectionStatus = useSelector(
    (state) => state.gameTable.games[tempTableId].gameConnectionStatus
  );
  const sitOutStatus = useSelector(
    (state) => state.gameTable.games[tempTableId].sitOutOption
  );
  const playerSitOutTimer = useSelector(
    (state) => state.gameTable.games[tempTableId].playerSitOutTimer
  );
  // const joinFailure = useSelector(
  //   (state) => state.gameTable.games[tempTableId].joinFailureFlag
  // );
  const isFunGame = useSelector(
    (state) => state.gameTable.games[tempTableId].isFunGame
  );
  const disableAutoActions = useSelector(
    (state) => state.gameTable.games[tempTableId].disableAutoActions
  );
  const disableDefaultActions = useSelector(
    (state) => state.gameTable.games[tempTableId].disableDefaultActions
  );
  // const playerPositions = useSelector(
  //   (state) => state.gameTable.games[tempTableId]?.playerPositions
  // );
  const [raiseBetAmount, setRaiseBetAmount] = useState(0);
  const isGameStarted = useSelector(
    (state) => state.gameTable.games[tempTableId].isGameStarted
  );
  const modalType = useSelector(
    (state) => state.gameTable.games[tempTableId].gameModal.type
  );
  const modalStatus = useSelector(
    (state) => state.gameTable.games[tempTableId].gameModal.visible
  );
  const isWaitingForBigBlind = useSelector(
    (state) => state.gameTable.games[tempTableId].isWaitingForBigBlind
  );
  const playerTurn = useSelector(
    (state) => state.gameTable.games[tempTableId].playerTurn
  );
  const mySeat = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerSeat
  );
  // const gameStage = useSelector(
  //   (state) => state.gameTable.games[tempTableId]?.gameStage
  // );
  const showStartGameTimer = useSelector(
    (state) =>
      state.gameTable.games[tempTableId].playerData.find(
        (player) => player.position === mySeat
      )?.showStartGameTimer
  );
  const userCallAmount = useSelector(
    (state) => state.gameTable.games[tempTableId]?.callAmount
  );
  const isActive = useSelector(
    (state) =>
      state.gameTable.games[tempTableId].playerData.find(
        (player) => player.position === mySeat
      )?.isActive
  );
  const isFolded = useSelector(
    (state) =>
      state.gameTable.games[tempTableId].playerData.find(
        (player) => player.position === mySeat
      )?.isFolded
  );
  const atTableAmount = useSelector(
    (state) =>
      state.gameTable.games[tempTableId].playerData.find(
        (player) => player.position === mySeat
      )?.atTableAmount
  );
  const openInSufficientFundsModal = useSelector(
    (state) => state.gameTable.games[tempTableId]?.openInSufficientFundsModal
  );
  const openTopupModal = useSelector(
    (state) => state.gameTable.games[tempTableId]?.openTopupModal
  );
  const currentGameId = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameId
  );
  const showGameTable = useSelector((state) => state.gameTable.showGameTable);
  const [showSitOutTimer, setShowSitOutTimer] = useState(false);
  const showReleaseToFold = useSelector(
    (state) => state.gameTable.games[tempTableId].showReleaseToFoldAlert
  );
  const playerBalance = useSelector((state) => state.lobby.balance);
  const buttonStatus = useSelector((state) => state.button.value);
  const maximumRaiseValue = useSelector(
    (state) => state.gameTable.games[tempTableId]?.maxRaiseAmount
  );
  const openBuyInModal = useSelector(
    (state) => state.gameTable.games[tempTableId]?.openBuyInModal
  );
  const openRebuyInModal = useSelector(
    (state) => state.gameTable.games[tempTableId]?.openRebuyInModal
  );
  const minimumRaiseValue = useSelector(
    (state) => state.gameTable.games[tempTableId]?.minRaiseAmount
  );
  const restrictSitInSitOutActions = useSelector(
    (state) => state.gameTable.games[tempTableId]?.restrictUserFromSitOut
  );
  const positionsArranged = useSelector(
    (state) => state.gameTable.games[tempTableId]?.positionsArranged
  );
  const sitOutButtonDisabled = useSelector(
    (state) => state.gameTable.games[tempTableId]?.sitOutButtonDisabled
  );
  const isTourneyGame = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isTourneyGame
  );
  const BBDisplay = useSelector(
    (state) => state.gameTable?.tableSettings?.BBDisplay
  );
  const amountToAdd = useSelector(
    (state) => state.gameTable?.games[tempTableId]?.amountToAdd
  );
  const showMaintenancePopup = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showMaintenancePopup
  );

  const isWatchPlayer = useSelector(
    (state) => state.gameTable?.games[tempTableId]?.watchPlayer
  );

  const userBalance = useSelector((state) => state.lobby.balance);

  const playerWaitingPosition = useSelector(
    (state) => state.gameTable?.games[tempTableId]?.playerWaitingPosition
  );
  // const totalWaitListPlayerCount = useSelector(
  //   (state) => state.gameTable?.games[tempTableId]?.totalWaitListPlayerCount
  // );
  const topUpPending = useSelector(
    (state) => state.gameTable?.games[tempTableId]?.topUpPending
  );

  const totalPlayersOnTable = useSelector(
    (state) => state.gameTable.games[tempTableId]?.totalPlayersConfig
  );
  const totalPotAmount = useSelector(
    (state) => state.gameTable.games[tempTableId]?.totalPotAmt
  );
  // const multiPotAmounts = useSelector(
  //   (state) => state.gameTable.games[tempTableId]?.multiPotAmounts
  // );
  // const communityCards = useSelector(
  //   (state) => state.gameTable.games[tempTableId]?.communityCards
  // );

  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();
  const [lastClickTime, setLastClickTime] = useState(0);
  const [tourneyAutoPlayOptOutLoader, setTourneyAutoPlayOptOutLoader] =
    useState(false);
  const gameTableRenderTimeout = useRef(null);

  let myLatestTap = 0;

  useEffect(() => {
    if (!tourneyAutoPlay) {
      setTourneyAutoPlayOptOutLoader(false);
    }
  }, [tourneyAutoPlay]);

  useEffect(() => {
    let timeStamp = getISTDateTimeFormat();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_GameTable_View",
      eventProperties: {
        userName: userName,
        channel,
        currentCashBalance: playerBalance.totalBalance,
        timeStamp,
        bigBlind: gameDefinition.bigBlind,
        smallBlind: gameDefinition.smallBlind,
        noOfPlayers: gameDefinition.maxPlayers,
        gameType: gameDefinition.gameType,
      },
    });
    CleverTapUtil.getInstance().logEvent(IP_Client_GameTable_View, {
      Username: userName,
      Channel: channel,
      "Wallet balance": playerBalance?.totalBalance,
      Timestamp: timeStamp,
      "Big blind": gameDefinition?.bigBlind,
      "No. of players": gameDefinition?.maxPlayers,
      "Game type": gameDefinition?.gameType,
      "Table name": gameDefinition?.tableName,
      "Game mode": gameDefinition?.gameMode,
    });

    sendFaroLog({
      type: `USER SCREEN CHANGE TO GAME_TABLE TEMP_TABLE_ID -  ${tempTableId}`,
    });

    dispatch(cleanUpTableObservers());

    // setTimeout(testMethod, 8000);
    // testMethod();
  }, []);

  // const testMethod = () => {
  //   TableUtils.getInstance().removeTableObserver(tempTableId);
  //   console.log("props:", props);
  //   props.gameToLobbyCallBack({ type: "testCall" });
  // };

  // useEffect(() => {
  //   // Request for balance update everytime the seat changes. This indicates joins and leaves.
  //   messageToNativeClient({
  //     type: "getBalance",
  //     token: token,
  //   });

  //   return () => {
  //     // Clearing game table dimensions update timeout on removal of game revamp.
  //     clearTimeout(gameTableRenderTimeout);
  //     // Requesting Balance on deletion of game
  //     messageToNativeClient({
  //       type: "getBalance",
  //       token: token,
  //     });
  //   };
  // }, [playerSeat]);

  useEffect(() => {
    if (canUserSwipe) {
      swiper.enable();
    } else {
      swiper.disable();
    }
  }, [canUserSwipe]);

  useEffect(() => {
    if (isIOS) {
      //NOTE: when we change game table and raise popup is open we need to close that in current active table before swiping only for IOS devices.
      // Refer ticket IM-272 to know more about issue
      setShowRaiseAmountOptionMenu(false);
      setRaiseBetAmount(0);
    }
    if (swiperSlide.isActive && tempTableId !== activeGame) {
      let indexOfGame = activeGames.indexOf(activeGame);
      swiper.slideToLoop(indexOfGame);
    }
  }, [activeGame, swiperSlide.isActive]);

  useEffect(() => {
    if (applicationVisibility) {
      if (modalType === INSUFFICIENT_BALANCE && modalStatus) {
        dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
      }
    }
  }, [applicationVisibility]);

  useEffect(() => {
    if (playerSitOutTimer) {
      setShowSitOutTimer(true);
    }
  }, [playerSitOutTimer]);

  useEffect(() => {
    if (!showRaiseAmountOptionMenu) {
      dispatch(enableMultitableSwipe());
    }
  }, [showRaiseAmountOptionMenu]);

  useEffect(() => {
    setShowRaiseAmountOptionMenu(false);
    setRaiseBetAmount(0);
  }, [playerTurn, showStartGameTimer]);

  useEffect(() => {
    if (!showDefaultActions && showRaiseAmountOptionMenu) {
      setShowRaiseAmountOptionMenu(false);
    }
  }, [showDefaultActions]);

  // Unmount code to set Game Connection to False
  useEffect(() => {
    createGameCommunicationRequirements();

    // console.log("Hitting start game listener N");
    // dispatch(
    //   startGameListenerN({
    //     tempTableId: tempTableId,
    //     dispatchFunction: dispatch,
    //   })
    // );
    // console.log("Finished start game listener N");

    // let isTableObserverCreated = TableUtils.getInstance().addTableObserver(
    //   tempTableId,
    //   gameDefinition.gameDefId,
    //   dispatch
    // );

    // if (isTableObserverCreated) {
    //   //this check is added to avoid the game connections trigger when user goes back to lobby and opens up the game window from active games option
    //   console.log("Hitting pass to Sequential:", tempTableId);
    //   TableUtils.getInstance().updateGameConnectionStatus(
    //     tempTableId,
    //     gameConnectionStatus,
    //     false,
    //     true
    //   );
    //   TableUtils.getInstance().passGameToSequential(tempTableId, "add", true);
    //   console.log("Finished pass to Sequential: ", tempTableId);
    // } else {
    //   console.log("Not adding AGAINA AGAINA AGAINA AGAINA");
    // }

    // TableUtils.getInstance().addTableObserver(tempTableId);

    return () => {
      //TODO: Need to do this change before production release
      //NOTE: NOT TO BE ENABLED AS THIS IS CREATING ISSUE OF GAME WINDOW CLOSURE WHILE GOING TO LOBBY
      // console.log("Calling cleaning up of game window socket:", tempTableId);
      // console.log("game unmount process.env.NODE_ENV:", process.env.NODE_ENV);
      // if (process.env.NODE_ENV === APP_RUNNING_PRODUCTION_MODE) {
      //   console.log("At clean up, removing the game connection tempTableId:", tempTableId);
      //   TableUtils.getInstance().removeTableObserver(tempTableId); //deleteGameTable reducer method will be invoked from table observer 'cleanUp' method
      // } else {
      //   console.log("At clean up, removing the game connection tempTableId:", tempTableId);
      // }
      clearTimeout(gameTableRenderTimeout.current);
    };
  }, []);

  useEffect(() => {
    //this useEffect will be invoked only of 'resetTableData' is triggered in redux
    if (isReset || isTourneyNodeSwitch) {
      createGameCommunicationRequirements();
    }
  }, [isReset, isTourneyNodeSwitch]);

  useEffect(() => {
    // console.log("Game Connection status in game: ", gameConnectionStatus);
    if (gameConnectionStatus === GAME_CONNECTION_PROTOCOL_SUCCESS) {
      setGameConnection(true);

      //leaving this commented code for reference
      //
      // if (!isReset) {
      //   setTimeout(() => {
      //     console.log("Yashwanth temp table id: ", tempTableId);
      //     console.log("Yashwanth removing table observer");
      //     TableUtils.getInstance().removeTableObserver(tempTableId, false);

      //     dispatch(resetTableData({ tempTableId: tempTableId }));
      //   }, 2000);
      // }
    }
  }, [gameConnectionStatus]);

  useEffect(() => {
    // Temporarily added resize listener here to handle iOS unstable webview size changes
    window.addEventListener("resize", updateBoundingClientRect);

    if (gameConnection) {
      updateBoundingClientRect();
    }

    // Temporarily added resize listener removal here to handle iOS unstable webview size changes
    return () => window.removeEventListener("resize", updateBoundingClientRect);
  }, [gameConnection, gameOrientation]);

  useEffect(() => {
    if (!gameTableLoaded && gameConnection) {
      updateBoundingClientRect();
    }
  }, [applicationVisibility]);

  useEffect(() => {
    if (playerBalance.totalBalance >= gameDefinition.minBuyInAmount) {
      dispatch(
        updateInSufficientFundsModalOpenStatus({
          tempTableId: tempTableId,
          isOpen: false,
        })
      );
    }
  }, [playerBalance.totalBalance]);

  // useEffect(() => {
  //   if (openInSufficientFundsModal) {
  //     dispatch(
  //       openLocalGameModal({
  //         type: INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT,
  //         tempTableId: tempTableId,
  //       })
  //     );
  //   } else {
  //     if (modalType !== DISCONNECTION) {
  //       dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
  //     }
  //   }
  // }, [openInSufficientFundsModal]);

  useEffect(() => {
    if (openInSufficientFundsModal) {
      dispatch(
        openLocalGameModal({
          type: INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT,
          tempTableId: tempTableId,
          config: {
            amountToAdd:
              gameDefinition.minBuyInAmount - userBalance.totalBalance,
            totalBalance: userBalance?.totalBalance,
            depositAndWinnings:
              Number(userBalance?.depositBalance) +
              Number(userBalance?.redeemableBalance),
            usableBonus: userBalance?.bonus,
            buyInAmount: gameDefinition.minBuyInAmount,
            isTourneyGame: false,
            blindsText: `(${returnGameTypeText(gameDefinition.gameType)} ${
              gameDefinition.smallBlind
            }/${gameDefinition.bigBlind})`,
          },
        })
      );
    } else {
      if (modalType === INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT) {
        dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
      }
    }
  }, [openInSufficientFundsModal]);

  // useEffect(() => {
  //   if (openTopupModal) {
  //     dispatch(openLocalGameModal({ type: BUY_IN, tempTableId: tempTableId }));
  //   } else {
  //     if (modalType !== DISCONNECTION) {
  //       dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
  //     }
  //   }
  // }, [openTopupModal]);

  useEffect(() => {
    if (openTopupModal) {
      dispatch(openLocalGameModal({ type: BUY_IN, tempTableId: tempTableId }));
    } else {
      if (modalType === BUY_IN) {
        dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
      }
    }
  }, [openTopupModal]);

  // useEffect(() => {
  //   if (openBuyInModal) {
  //     dispatch(openLocalGameModal({ type: BUY_IN, tempTableId: tempTableId }));
  //   } else if (!openBuyInModal && modalType === BUY_IN) {
  //     if (modalType !== DISCONNECTION) {
  //       dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
  //     }
  //   }
  // }, [openBuyInModal]);

  useEffect(() => {
    if (openBuyInModal) {
      dispatch(openLocalGameModal({ type: BUY_IN, tempTableId: tempTableId }));
    } else if (modalType === BUY_IN) {
      dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
    }
  }, [openBuyInModal]);

  // useEffect(() => {
  //   if (openRebuyInModal) {
  //     dispatch(
  //       openLocalGameModal({ type: REBUY_IN, tempTableId: tempTableId })
  //     );
  //   } else if (!openRebuyInModal && modalType === REBUY_IN) {
  //     if (modalType !== DISCONNECTION) {
  //       dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
  //     }
  //   }
  // }, [openRebuyInModal]);

  useEffect(() => {
    if (openRebuyInModal) {
      dispatch(
        openLocalGameModal({ type: REBUY_IN, tempTableId: tempTableId })
      );
    } else if (modalType === REBUY_IN) {
      dispatch(closeLocalGameModal({ tempTableId: tempTableId }));
    }
  }, [openRebuyInModal]);

  useEffect(()=>{
    loadPlayerAvatar()
  },[playerData.avatar])


  const loadPlayerAvatar = async () => {
    let requiredImageUrl;
    if(process.env.REACT_APP_ENVIRONMENT === "qa"){
      let imageUrl = new URL(playerData.avatar);
      let urlOrigin = imageUrl.origin;
      let urlPathName = imageUrl.pathname; 
      let modifiedUrlOrgin;
      
      if(urlOrigin === "https://fanfightappqa.s3.ap-south-1.amazonaws.com"){
        modifiedUrlOrgin = "https://images.qapfgames.com";
        requiredImageUrl = modifiedUrlOrgin + urlPathName;
        console.log(requiredImageUrl,"Naveen Modified Url");
        // setUserAvatar(requiredImageUrl);
      }else{
        // setUserAvatar(playerData.avatar);
        requiredImageUrl =  playerData.avatar;
      }
    }
    else {
      requiredImageUrl = playerData.avatar;
    }
    try {
      const response = await fetch(requiredImageUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Image not found or failed to load.');
      }
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(setPlayerAvatar(reader.result));
      };
      reader.onerror = function() {
        dispatch(setPlayerAvatar(defaultAvatar));
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      dispatch(setPlayerAvatar(defaultAvatar));
      console.error('Failed to load image:', error);
    }
  };


  const createGameCommunicationRequirements = () => {
    console.log("Hitting start game listener N temptableid:", tempTableId);
    dispatch(
      startGameListenerN({
        tempTableId: tempTableId,
        dispatchFunction: dispatch,
      })
    );
    console.log("Finished start game listener N temptableid:", tempTableId);

    let isTableObserverCreated = TableUtils.getInstance().addTableObserver(
      tempTableId,
      gameDefinition.gameDefId,
      dispatch
    );
    console.log(
      "at create game communication gameConnectionStatus:",
      gameConnectionStatus + " temptableid:",
      tempTableId
    );

    if (isTableObserverCreated) {
      //this check is added to avoid the game connections trigger when user goes back to lobby and opens up the game window from active games option
      console.log("Hitting pass to Sequential:", tempTableId);
      TableUtils.getInstance().updateGameConnectionStatus(
        tempTableId,
        gameConnectionStatus,
        false,
        true
      );
      TableUtils.getInstance().passGameToSequential(tempTableId, "add", true);
      console.log("Finished pass to Sequential: ", tempTableId);

      // testMethod();
    } else {
      console.log("Not adding AGAINA AGAINA AGAINA AGAINA");
    }
  };

  const updateBoundingClientRect = () => {
    setGameTableLoaded(false);
    dispatch(hidePlayerPositions({ tempTableId: tempTableId }));
    try {
      let boundingClientRectangle =
        gameTableImageRef?.current?.getBoundingClientRect();
      if (
        Math.abs(boundingClientRectangle.top - boundingClientRectangle.bottom) >
          100 &&
        boundingClientRectangle
      ) {
        clearTimeout(gameTableRenderTimeout.current);

        gameTableRenderTimeout.current = setTimeout(() => {
          dispatch(
            updateGameTableBoundingRect({
              tempTableId: tempTableId,
              positions: JSON.stringify(
                gameTableImageRef?.current?.getBoundingClientRect()
              ),
              orientation: gameOrientation,
              horizontalOffset:
                (window.innerWidth -
                  gameTableImageRef?.current?.getBoundingClientRect().width) /
                2,
              verticalOffset:
                (window.innerHeight -
                  gameTableImageRef?.current?.getBoundingClientRect().height) /
                2,
            })
          );
          dispatch(updatePositionsArranged({ tempTableId: tempTableId }));
          setGameTableLoaded(true);
        }, 750);
      }
    } catch (err) {
      console.log("Error in catching bounding client rect", err);
    }
  };

  const closeAllOptionMenu = () => {
    if (
      showHamburgerOptionMenu ||
      showExitTableOptionMenu ||
      showHandHistoryMenu ||
      showRaiseAmountOptionMenu
    ) {
      setShowHandHistoryMenu(false);
      setShowExitTableOptionMenu(false);
      setShowHamburgerOptionMenu(false);
      if (!isRaiseActionKeyboardOpened) {
        setShowRaiseAmountOptionMenu(false);
        setRaiseBetAmount(0);
      }
    }

    handleGameTableDoubleTap();
  };

  const handleGameTableDoubleTap = () => {
    // console.log("Double Tap is working 1");
    let now = new Date().getTime();
    if (smartGestureOptions.smartCheck && showCheck && showDefaultActions) {
      let timesince = now - myLatestTap;
      if (timesince < 1000 && timesince > 0) {
        // console.log("Double Tap is working! 2");
        actionButtonToggleHandler("check", {
          playerName: userName,
          tempTableId: tempTableId,
        });
      } else {
      }
      myLatestTap = new Date().getTime();
    }
    return;
  };

  const createGameTable = () => {
    return (
      <GameTableImageWrapper
        id="gametable-image-wrapper"
        onClick={() => closeAllOptionMenu()}
      >
        <RelativeWrapper
        // onClick={() => handleGameTableDoubleTap()}
        >
          {/* game table asset */}
          <GameTableBackgroundImage
            key={"gametable-bg-img-" + gameOrientation + tempTableId}
            id="gametable-bg-img"
            ref={gameTableImageRef}
            orientation={gameOrientation}
            onLoadStart={() => setGameTableLoaded(false)}
            onLoad={() => updateBoundingClientRect()}
            swipeDirection={swipeDirection}
            // isBlurred={tableBlur}
            src={getTableThemeImage()}
          />
          <Suspense fallback={null}>
            {/* {gameConnection &&
              gameTableLoaded &&
              playerPositions &&
              playerPositions.length > 0 &&
              createPlayerCardsOnTable()} */}

            {gameConnection && gameTableLoaded && createCommunityCardsOnTable()}
            {gameConnection && gameTableLoaded && createPlayersOnTable()}
            {gameConnection && gameTableLoaded && showGameDetailsOnTable()}
            {/* {gameConnection &&
              gameTableLoaded &&
              playerPositions &&
              playerPositions.length > 0 &&
              showGameStartTimer()} */}
            {/* {gameConnection && gameTableLoaded && showTotalPotAmountOnTable()} */}
            {/* {gameConnection && gameTableLoaded && showWinnerHandTypeOnTable()} */}
          </Suspense>
        </RelativeWrapper>
        <MaskingLayer orientation={gameOrientation} src={maskingLayerImg} />
      </GameTableImageWrapper>
    );
  };

  const getTableThemeImage = () => {
    switch (tableTheme) {
      case "Table1":
        return gameOrientation === "PORTRAIT"
          ? gameAssets.gameTableOne
          : gameAssets.LandscapeTableOne;
      case "Table2":
        return gameOrientation === "PORTRAIT"
          ? gameAssets.gameTableTwo
          : gameAssets.LandscapeTableTwo;
      case "Table3":
        return gameOrientation === "PORTRAIT"
          ? gameAssets.gameTableThree
          : gameAssets.LandscapeTableThree;
      case "Table4":
        return gameOrientation === "PORTRAIT"
          ? gameAssets.gameTableFour
          : gameAssets.LandscapeTableFour;
      case "Table5":
        return gameOrientation === "PORTRAIT"
          ? gameAssets.gameTableFive
          : gameAssets.LandscapeTableFive;
      // case "Table6":
      //   return gameOrientation === "PORTRAIT" ? gameAssets.gameTableSix : gameAssets.LandscapeTableFour;
    }
  };

  const showGameDetailsOnTable = () => {
    // if (gameOrientation === "PORTRAIT") {
    return (
      <GameDetails
        orientation={gameOrientation}
        tempTableId={tempTableId}
      ></GameDetails>
    );
    // } else {
    // return null;
    // }
  };

  // const showGameStartTimer = () => {
  //   return (
  //     <CenterDetails
  //       orientation={gameOrientation}
  //       tempTableId={tempTableId}
  //     ></CenterDetails>
  //   );
  // };

  // const showTotalPotAmountOnTable = () => {
  //   return (
  //     <PotAmount tempTableId={tempTableId} orientation={gameOrientation} />
  //   );
  // };

  const showWinnerHandTypeOnTable = () => {
    return (
      <WinnerHandType tempTableId={tempTableId} orientation={gameOrientation} />
    );
  };

  const handleRaiseButton = (currentPlayer) => {
    if (showRaiseAmountOptionMenu && raiseBetAmount > 0) {
      actionButtonToggleHandler("raise", {
        playerName: userName,
        betAmount: raiseBetAmount,
        tempTableId: tempTableId,
      });
      setShowRaiseAmountOptionMenu(false);
      setRaiseBetAmount(0);
    } else {
      setRaiseBetAmount(0);
      // setRaiseBetAmount(currentPlayer.atTableAmount);
      setIsRaiseActionKeyboardOpened(false);
      setShowRaiseAmountOptionMenu(true);
    }
  };

  const handleRaiseAllInButton = (betAmount) => {
    actionButtonToggleHandler("raise", {
      playerName: userName,
      betAmount: betAmount,
      tempTableId: tempTableId,
    });
  };

  const handleIsKeyboardOpened = (isOpened) => {
    console.log("TRIGERRED ME", isOpened);
    setIsRaiseActionKeyboardOpened(isOpened);
  };

  const handleRaiseActionBet = (betAmount) => {
    GameAudio.getInstance().playAudio(
      !isPlaySoundEnabled,
      RAISE_BAR_SOUND,
      true,
      false
    );
    setRaiseBetAmount(betAmount);
  };

  const createPlayersOnTable = () => {
    return positionsArranged ? (
      <PlayerView
        activeGameTable={tempTableId}
        orientation={gameOrientation}
        tableTheme={tableTheme}
        gameTableLoaded={gameTableLoaded}
      />
    ) : null;
  };

  const createRaiseAmountSlider = () => {
    // console.log("came inside raise");

    return (
      showRaiseAmountOptionMenu && (
        <RaiseActionRevamp
          tempTableId={tempTableId}
          handleRaiseActionBet={handleRaiseActionBet}
          handleIsKeyboardOpened={handleIsKeyboardOpened}
          isRaiseActionKeyboardOpened={isRaiseActionKeyboardOpened}
        />
      )
    );
  };

  const actionButtonToggleHandler = (type, payload) => {
    switch (type) {
      case "call":
        setShowRaiseAmountOptionMenu(false);
        setRaiseBetAmount(0);
        sendCallProtocol(payload);
        // dispatch(updateAutoTableSwitchQueue({ tempTableId: tempTableId }));
        break;
      case "check":
        setShowRaiseAmountOptionMenu(false);
        setRaiseBetAmount(0);
        sendCheckProtocol(payload);
        // dispatch(updateAutoTableSwitchQueue({ tempTableId: tempTableId }));
        break;
      case "fold":
        if (showCheck) {
          dispatch(
            openLocalGameModal({ type: FOLD_CONFIRM, tempTableId: tempTableId })
          );
          setShowRaiseAmountOptionMenu(false);
          setRaiseBetAmount(0);
          return;
        } else {
          setShowRaiseAmountOptionMenu(false);
          setRaiseBetAmount(0);
          sendFoldProtocol(payload);
          // dispatch(updateAutoTableSwitchQueue({ tempTableId: tempTableId }));
        }
        break;
      case "raise":
        sendRaiseProtocol(payload);
        // dispatch(updateAutoTableSwitchQueue({ tempTableId: tempTableId }));
        break;
      case "autoCall":
        dispatch(
          updateUserAutoActionPreference({
            tempTableId: tempTableId,
            autoUserAction:
              autoAction !== AUTO_CALL_ACTION ? AUTO_CALL_ACTION : "",
          })
        );
        sendAutoCallProtocol(payload);
        break;
      case "autoCallAny":
        dispatch(
          updateUserAutoActionPreference({
            tempTableId: tempTableId,
            autoUserAction:
              autoAction !== AUTO_CALL_ANY_ACTION ? AUTO_CALL_ANY_ACTION : "",
          })
        );
        sendAutoCallAnyProtocol(payload);
        break;
      case "autoCheckFold":
        dispatch(
          updateUserAutoActionPreference({
            tempTableId: tempTableId,
            autoUserAction:
              autoAction !== AUTO_CHECK_OR_FOLD_ACTION
                ? AUTO_CHECK_OR_FOLD_ACTION
                : "",
          })
        );
        sendAutoCheckFold(payload);
        break;
      case "autoCheck":
        dispatch(
          updateUserAutoActionPreference({
            tempTableId: tempTableId,
            autoUserAction:
              autoAction !== AUTO_CHECK_ACTION ? AUTO_CHECK_ACTION : "",
          })
        );
        sendAutoCheck(payload);
        break;
      case "autoFold":
        dispatch(
          updateUserAutoActionPreference({
            tempTableId: tempTableId,
            autoUserAction:
              autoAction === AUTO_FOLD_ACTION ? AUTO_FOLD_ACTION : "",
          })
        );
        sendAutoFold(payload);
        break;
      case "joinWaitlist":
        if (autoAction === JOIN_WAITLIST_ACTION) {
          removeFromWaitListProtocol(payload);
        } else {
          sendJoinWaitlistProtocol(payload);
        }

        dispatch(
          updateUserAutoActionPreference({
            tempTableId: tempTableId,
            autoUserAction:
              autoAction !== JOIN_WAITLIST_ACTION ? JOIN_WAITLIST_ACTION : "",
          })
        );

        break;
      default:
        break;
    }
    dispatch(
      updateDisableGameActions({ tempTableId: tempTableId, disabled: true })
    );
    return;
  };

  const handleActionWithInterval = (type, payload) => {
    // You can adjust the time gap (500ms in this example)
    const timeGap = 250;
    const currentTime = new Date().getTime();

    // Check if the time gap is met
    if (currentTime - lastClickTime >= timeGap) {
      // Perform your button click logic here
      actionButtonToggleHandler(type, payload);
    }
    setLastClickTime(currentTime);
  };

  const createGameActionButtons = (currentPlayer) => {
    const showRaiseAllInButton = minimumRaiseValue >= maximumRaiseValue;
    let callLabel;
    // Added this to debug the callAmount value and monitor if it ever reaches undefined
    if (showCall) {
      callLabel = BBDisplay
        ? parseDecimalValuesOfNumber(
            userCallAmount / gameDefinition?.bigBlind,
            1
          ) + " BB"
        : isFunGame || isTourneyGame
        ? parseDecimalValuesOfNumber(
            userCallAmount,
            2,
            checkToDisplayValueInK(userCallAmount),
            checkToDisplayValueInL(userCallAmount)
          )
        : "₹ " +
          parseDecimalValuesOfNumber(
            userCallAmount,
            2,
            checkToDisplayValueInK(userCallAmount),
            checkToDisplayValueInL(userCallAmount)
          );
      if (!callLabel) {
        sendLobbyDebugProtocol({
          message: "Call amount is undefined",
          callAmount: userCallAmount,
          displayInBB: BBDisplay,
          isTourneyGame: isTourneyGame,
          isFunGame: isFunGame,
        });
      }
    }

    let foldButton = (
      <GamePlayButton
        label="Fold"
        backgroundColor={colors.foldAction}
        topBorder={colors.foldBorder}
        clickHandler={() =>
          handleActionWithInterval("fold", {
            playerName: currentPlayer.userName,
            tempTableId: tempTableId,
          })
        }
        disabled={disableDefaultActions}
      ></GamePlayButton>
    );
    let callButton = (
      <GamePlayButton
        label={`Call ${callLabel ? callLabel : null}`}
        backgroundColor={colors.callAction}
        topBorder={colors.callBorder}
        disabled={disableDefaultActions}
        clickHandler={() =>
          handleActionWithInterval("call", {
            playerName: currentPlayer.userName,
            betAmount: userCallAmount,
            tempTableId: tempTableId,
          })
        }
      ></GamePlayButton>
    );
    let allInButton = (
      <GamePlayButton
        label={`All In`}
        backgroundColor={colors.allInAction}
        topBorder={colors.allInBorder}
        disabled={disableDefaultActions}
        clickHandler={() =>
          handleActionWithInterval("call", {
            playerName: currentPlayer.userName,
            betAmount: userCallAmount,
            tempTableId: tempTableId,
          })
        }
      ></GamePlayButton>
    );
    let checkButton = (
      <GamePlayButton
        label="Check"
        backgroundColor={colors.checkAction}
        topBorder={colors.checkBorder}
        disabled={disableDefaultActions}
        clickHandler={() =>
          handleActionWithInterval("check", {
            playerName: currentPlayer.userName,
            tempTableId: tempTableId,
          })
        }
      ></GamePlayButton>
    );
    let raiseButton = (
      <GamePlayButton
        label={`Raise ${
          raiseBetAmount > 0
            ? BBDisplay
              ? parseDecimalValuesOfNumber(
                  raiseBetAmount / gameDefinition.bigBlind,
                  1
                ) + " BB"
              : isFunGame || isTourneyGame
              ? "" +
                parseDecimalValuesOfNumber(
                  Number(raiseBetAmount),
                  2,
                  checkToDisplayValueInK(
                    raiseBetAmount,
                    checkToDisplayValueInL(raiseBetAmount)
                  )
                )
              : RUPEE_SYMBOL +
                parseDecimalValuesOfNumber(
                  Number(raiseBetAmount),
                  2,
                  checkToDisplayValueInK(
                    raiseBetAmount,
                    checkToDisplayValueInL(raiseBetAmount)
                  )
                )
            : ""
        }`}
        backgroundColor={colors.raiseAction}
        topBorder={colors.raiseBorder}
        disabled={disableDefaultActions}
        clickHandler={() => {
          handleRaiseButton(currentPlayer);
        }}
      ></GamePlayButton>
    );

    //NOTE: We will display this all in button instead of raise on UI because minimum raise value is greaterthan or equal to maximum raise value
    let raiseAllInButton = (
      <GamePlayButton
        label={`All In`}
        backgroundColor={colors.allInAction}
        topBorder={colors.allInBorder}
        disabled={disableDefaultActions}
        clickHandler={() => {
          handleRaiseAllInButton(maximumRaiseValue);
        }}
      ></GamePlayButton>
    );
    return gameOrientation === "PORTRAIT" ? (
      <>
        <ButtonWrapper
          id="game-actions-wrapper"
          showReleaseToFold={showReleaseToFold}
        >
          {showFold && foldButton}
          {!showAllIn && showCall && callButton}
          {showAllIn && allInButton}
          {showCheck && checkButton}
          {showRaise
            ? showRaiseAllInButton
              ? raiseAllInButton
              : raiseButton
            : null}
        </ButtonWrapper>
        {showReleaseToFold ? (
          <ReleaseToFoldAlertContainer>
            <ReleaseToFoldText>Release to fold</ReleaseToFoldText>
          </ReleaseToFoldAlertContainer>
        ) : null}
      </>
    ) : (
      <>
        <LandscapeButtonWrapper
          orientation={gameOrientation}
          id="game-actions-wrapper"
          showReleaseToFold={showReleaseToFold}
        >
          <LeftActions>{showFold && foldButton}</LeftActions>
          <CenterSpacing />
          <RightActions>
            {!showAllIn && showCall && callButton}
            {showAllIn && allInButton}
            {showCheck && checkButton}
            {showRaise
              ? showRaiseAllInButton
                ? raiseAllInButton
                : raiseButton
              : null}
          </RightActions>
        </LandscapeButtonWrapper>
        {showReleaseToFold ? (
          <ReleaseToFoldAlertContainer>
            <ReleaseToFoldText>Release to fold</ReleaseToFoldText>
          </ReleaseToFoldAlertContainer>
        ) : null}
      </>
    );
  };

  const createWaitListButtons = () => {
    let playersWhoTookSeat = [];
    if (playersOnTable.length > 0) {
      playersOnTable.map((player) => {
        if (player.isTakenSeat) {
          playersWhoTookSeat.push(player);
        }
      });
    }

    let flag =
      !isFunGame &&
      !isTourneyGame &&
      isWatchPlayer &&
      playersWhoTookSeat &&
      playersWhoTookSeat.length === totalPlayersOnTable &&
      (mySeat === undefined || mySeat === null) &&
      isGameStarted &&
      !openBuyInModal;

    if (flag) {
      return (
        <>
          <ButtonWrapper>
            {playerWaitingPosition ? (
              <WaitListPlayerCount>
                {`You are ${playerWaitingPosition}${
                  playerWaitingPosition.toString()[0] === "1"
                    ? "st"
                    : playerWaitingPosition.toString()[0] === "2"
                    ? "nd"
                    : playerWaitingPosition.toString()[0] === "3"
                    ? "rd"
                    : "th"
                } in the line`}
              </WaitListPlayerCount>
            ) : null}

            <StandByGamePlayButton
              label="Join Waitlist"
              clickHandler={() => {
                handleActionWithInterval("joinWaitlist", {
                  tempTableId: tempTableId,
                  playerName: userName,
                  tableId: currentGameData.tableId,
                  gameDefId: gameDefinition.gameDefId,
                });
              }}
              active={autoAction === JOIN_WAITLIST_ACTION}
            ></StandByGamePlayButton>
            <GamePlayButton
              label="Find New Table"
              backgroundColor={colors.callAction}
              topBorder={colors.callBorder}
              clickHandler={() => {
                dispatch(
                  openLocalGameModal({
                    type: NEW_TABLE,
                    tempTableId: activeGame,
                  })
                );
              }}
            ></GamePlayButton>
          </ButtonWrapper>
        </>
      );
    }
  };

  const createStandByActionButtons = (currentPlayer) => {
    let autoCheckOrFoldButton = (
      <StandByGamePlayButton
        label="Check/Fold"
        backgroundColor={colors.redBtn}
        disabled={disableAutoActions}
        clickHandler={() =>
          handleActionWithInterval("autoCheckFold", {
            playerName: currentPlayer.userName,
            tempTableId: tempTableId,
            autoActionEnable:
              autoAction === AUTO_CHECK_OR_FOLD_ACTION ? false : true,
          })
        }
        active={autoAction === AUTO_CHECK_OR_FOLD_ACTION}
      ></StandByGamePlayButton>
    );

    let autoCallButton = (
      <StandByGamePlayButton
        label={`Call ${
          BBDisplay
            ? parseDecimalValuesOfNumber(
                userCallAmount / gameDefinition.bigBlind,
                1
              ) + " BB"
            : parseDecimalValuesOfNumber(
                userCallAmount,
                2,
                checkToDisplayValueInK(userCallAmount),
                checkToDisplayValueInL(userCallAmount)
              )
        }`}
        backgroundColor={colors.redBtn}
        disabled={disableAutoActions}
        clickHandler={() =>
          handleActionWithInterval("autoCall", {
            playerName: currentPlayer.userName,
            betAmount: userCallAmount,
            tempTableId: tempTableId,
            autoActionEnable: autoAction === AUTO_CALL_ACTION ? false : true,
          })
        }
        active={autoAction === AUTO_CALL_ACTION}
      ></StandByGamePlayButton>
    );

    let autoFoldButton = (
      <StandByGamePlayButton
        label="Fold"
        backgroundColor={colors.redBtn}
        disabled={disableAutoActions}
        clickHandler={() =>
          handleActionWithInterval("autoFold", {
            playerName: currentPlayer.userName,
            tempTableId: tempTableId,
            autoActionEnable: autoAction === AUTO_FOLD_ACTION ? false : true,
          })
        }
        active={autoAction === AUTO_FOLD_ACTION}
      ></StandByGamePlayButton>
    );

    let autoCheckButton = (
      <StandByGamePlayButton
        label="Check"
        backgroundColor={colors.greenBtn}
        disabled={disableAutoActions}
        clickHandler={() =>
          handleActionWithInterval("autoCheck", {
            playerName: currentPlayer.userName,
            tempTableId: tempTableId,
            autoActionEnable: autoAction === AUTO_CHECK_ACTION ? false : true,
          })
        }
        active={autoAction === AUTO_CHECK_ACTION}
      ></StandByGamePlayButton>
    );

    let autoCallAnyButton = (
      <StandByGamePlayButton
        label="Call Any"
        backgroundColor={colors.blueBtn}
        clickHandler={() =>
          handleActionWithInterval("autoCallAny", {
            playerName: currentPlayer.userName,
            tempTableId: tempTableId,
            autoActionEnable:
              autoAction === AUTO_CALL_ANY_ACTION ? false : true,
          })
        }
        active={autoAction === AUTO_CALL_ANY_ACTION}
      ></StandByGamePlayButton>
    );
    return gameOrientation === "PORTRAIT" ? (
      <ButtonWrapper id="game-actions-wrapper">
        {fold && autoFoldButton}
        {autoCheckOrFold && autoCheckOrFoldButton}
        {autoCall && autoCallButton}
        {autoCheck && autoCheckButton}
        {autoCallAny && autoCallAnyButton}
      </ButtonWrapper>
    ) : (
      <LandscapeButtonWrapper
        orientation={gameOrientation}
        id="game-actions-wrapper"
      >
        <LeftActions>
          {fold && autoFoldButton}
          {autoCheckOrFold && autoCheckOrFoldButton}
          {autoCall && autoCallButton}
        </LeftActions>
        <CenterSpacing />
        <RightActions>
          {autoCheck && autoCheckButton}
          {autoCallAny && autoCallAnyButton}
        </RightActions>
      </LandscapeButtonWrapper>
    );
  };

  // const showTotalPotAmount = () => {
  //     return (
  //       totalPotAmount !== undefined &&
  //       gameStage !== "" && (
  //         <TotalPotAmountWrapper isLandscape={orientation === LANDSCAPE}>
  //           Total Pot:
  //           <Amount
  //             BBDisplay={BBDisplay}
  //             id={`total-pot-${tempTableId}`}
  //           ></Amount>
  //         </TotalPotAmountWrapper>
  //       )
  //     );
  // };

  const createCommunityCardsOnTable = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "12px",
          minHeight: "50%",
        }}
      >
        {totalPotAmount
          ? // &&
            // multiPotAmounts.length > 1 &&
            // communityCards.length > 0
            createTotalPot()
          : null}
        <CommunityCards
          tempTableId={tempTableId}
          currentGameId={currentGameId}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showWinnerHandTypeOnTable()}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            minHeight: "25px",
          }}
        >
          {/* {showTotalPotAmountOnTable()} */}
          <FramerPotAmount tempTableId={tempTableId} />
        </div>
      </div>
    );
  };

  const createTotalPot = () => {
    return (
      <motion.div
        initial={{
          opacity: 0,
          scale: 0,
        }}
        animate={{
          opacity: 1,
          scale: 1,
          y: -10,
        }}
        exit={{
          opacity: 0,
          scale: 0,
        }}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            padding: "2px 6px",
            minWidth: "50px",
            borderRadius: "24px",
            textAlign: "center",
            width: "fit-content",
            color: colors.primaryGold,
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: "6px" }}>Pot: </span>
          <span style={{ fontWeight: "bold", color: "white" }}>
            {!BBDisplay
              ? parseDecimalValuesOfNumber(
                  totalPotAmount,
                  2,
                  checkToDisplayValueInK(totalPotAmount),
                  checkToDisplayValueInL(totalPotAmount)
                )
              : parseDecimalValuesOfNumber(
                  totalPotAmount / gameDefinition?.bigBlind,
                  1
                ) + " BB"}
          </span>
        </div>
      </motion.div>
    );
  };

  const playerGameButtonsLogic = () => {
    // console.log("Player Seat is: >>> ", playerSeat);

    const currentPlayer = playersOnTable.find(
      (eachPlayer) => eachPlayer.position === playerSeat
    );

    const activeGame = currentGameData;
    // console.log("activeGame>>>", activeGame);
    if (currentPlayer && currentPlayer.atTableAmount !== 0) {
      if (showDefaultActions) {
        return createGameActionButtons(currentPlayer);
      } else if (showAutoActions) {
        return createStandByActionButtons(currentPlayer);
      } else {
        return null;
      }
    }
    return null;
  };

  const createToastMessages = () => {
    return <Toast isLobbyToasts={false} tempTableId={tempTableId}></Toast>;
  };

  const createPlayNextButtonOnTable = () => {
    // game should have started, player should not have cards, and playerSeat should exist.
    // alert(playerCards.length)
    if (
      !isActive &&
      !playerSitOutTimer &&
      !restrictSitInSitOutActions &&
      !isFolded
    ) {
      if (
        isGameStarted &&
        playerSeat !== undefined &&
        playerSeat !== null &&
        atTableAmount > 0
      ) {
        return (
          <PlayNextWrapper orientation={gameOrientation}>
            <StandByGamePlayButton
              id="play-next-button"
              label={"Play next"}
              active={!isWaitingForBigBlind}
              clickHandler={() =>
                handleActionWithIntervalCallback(handlePlayNext)
              }
              disabled={false}
            />
          </PlayNextWrapper>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handlePlayNext = () => {
    // setPlayNext(!playNext);
    sendWaitForBB({
      tempTableId: tempTableId,
      playerName: userName,
      isWaitForBB: isWaitingForBigBlind,
    });
  };

  const handleActionWithIntervalCallback = (callback) => {
    // You can adjust the time gap (500ms in this example)
    const timeGap = 500;
    const currentTime = new Date().getTime();

    // Check if the time gap is met
    if (currentTime - lastClickTime >= timeGap) {
      // Perform your button click logic here
      callback();
    }
    setLastClickTime(currentTime);
  };

  const closeGameOnConFHandler = () => {
    // dispatch(removeGameConnection({ tempTableId: tempTableId }));
    // dispatch(closeFailedGameTable({ tempTableId: tempTableId }));
    TableUtils.getInstance().removeTableObserver(tempTableId);
  };

  const handleAddCashOnMatchMakingFailure = (e) => {
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_CashFunToggle_Click",
      eventProperties: {
        userName: userName,
        channel: channel,
        currentCashBalance: playerBalance.totalBalance,
        timeStamp: timeStamp,
        clickLocation: "InsufficeintBalance",
      },
    });
    // e.stopPropagation();
    // closeGameOnConFHandler();
    closeGameOnConFHandler();
    messageToNativeClient({ type: "openaddcash" });
  };

  const createGameTableLoader = () => {
    // console.log("CAME INSIDE FAILURE ", gameConnectionStatus);
    let failureReasons = [
      GAME_JOIN_API_RESPONSE_FAILURE,
      MATCH_MAKING_API_REQUEST_REJECTED,
      CHECK_GAME_API_REQUEST_REJECTED,
      NODE_MISMATCH_ERROR,
      // RELOGIN_FAILURE,
    ];

    let insufficientBalanceFailure =
      gameConnectionStatus === MATCH_MAKING_API_INSUFFICIENT_BALANCE;
    let gameBetDisabled = gameConnectionStatus === MATCH_MAKING_BET_DISABLED;
    // let failure = failureReasons.includes(game_API_Status);
    let failure = failureReasons.includes(gameConnectionStatus);

    return (
      <LoadingWrapper id="loading-wrapper">
        {gameBetDisabled ? null : insufficientBalanceFailure ? (
          <AddCashLottie size={100} />
        ) : failure ? (
          <NetworkLottie size={70} />
        ) : (
          <CardsLoader size={100} />
        )}
        <DisconnectionResult>
          {gameBetDisabled ? (
            <>
              <Maintenance betLevel={true} />
              <CloseGameButton
                onClick={(e) => {
                  // e.preventDefault();
                  e.stopPropagation();
                  closeGameOnConFHandler();
                }}
              >
                {numberOfGames > 1 ? "Close" : "Go to Lobby"}
              </CloseGameButton>
            </>
          ) : insufficientBalanceFailure ? (
            <>
              <LoadingText>You have insufficient funds.</LoadingText>
              {amountToAdd ? (
                <>
                  <LoadingText style={{ marginBottom: "1rem" }}>
                    Add{" "}
                    <span style={{ color: colors.primaryGold }}>
                      ₹{parseDecimalValuesOfNumber(amountToAdd, 2)}
                    </span>{" "}
                    cash to start playing!
                  </LoadingText>
                  <AddCashButton
                    onClick={(e) => handleAddCashOnMatchMakingFailure(e)}
                  >
                    Add cash
                  </AddCashButton>
                  <GoToLobbyButton onClick={(e) => closeGameOnConFHandler(e)}>
                    {numberOfGames > 1 ? "Close" : "Go to lobby"}
                  </GoToLobbyButton>
                </>
              ) : null}
            </>
          ) : failure ? (
            <>
              {process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                <>
                  <LoadingText>Connection failure</LoadingText>
                  <LoadingText>Reason: {gameConnectionStatus}</LoadingText>
                </>
              ) : (
                <>
                  <LoadingText>Oops!</LoadingText>
                  <LoadingText>
                    Something went wrong. Please try again later.
                  </LoadingText>
                </>
              )}
              {gameConnectionStatus !== NODE_MISMATCH_ERROR ? (
                <CloseGameButton
                  onClick={(e) => {
                    // e.preventDefault();
                    e.stopPropagation();
                    closeGameOnConFHandler();
                  }}
                >
                  {numberOfGames > 1 ? "Close" : "Go to Lobby"}
                </CloseGameButton>
              ) : (
                // <LoadingText>
                //   Retrying different node... Please wait
                // </LoadingText>
                <LoadingText>
                  {process.env.REACT_APP_ENVIRONMENT === "dev"
                    ? "Retrying different node... Please wait"
                    : "Please exit the table and try again, something has gone wrong."}
                </LoadingText>
              )}
            </>
          ) : (
            <>
              {process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                <>
                  <LoadingText>Establishing game connection..</LoadingText>
                  <LoadingText>
                    Game Connection Status: {gameConnectionStatus}
                  </LoadingText>
                </>
              ) : (
                <>
                  <LoadingText bold={true}>Hold your cards..</LoadingText>
                  {/* <LoadingText>
                    {console.log("isWatchPlayer: ",isWatchPlayer)}
                    {isWatchPlayer ? "Hang Tight! Your game is loading..." :
                      "Your match is just a shuffle away"}
                  </LoadingText> */}
                  <LoadingText>Your match is just a shuffle away</LoadingText>
                </>
              )}
            </>
          )}
        </DisconnectionResult>
      </LoadingWrapper>
    );
  };

  const SitOutTimerEnd = () => {
    setShowSitOutTimer(false);
  };

  const createImBackButton = () => {
    if (tourneyAutoPlay) {
      return (
        <JoinBackWrapper orientation={gameOrientation}>
          <JoinBackButton
            style={{ marginBottom: "6px" }}
            id="join-back-button"
            onClick={() =>
              !tourneyAutoPlayOptOutLoader ? optOutOfTourneyAutoPlay() : null
            }
            disabled={tourneyAutoPlayOptOutLoader}
          >
            {!tourneyAutoPlayOptOutLoader ? "I'm back" : <Loader size={15} />}
          </JoinBackButton>
        </JoinBackWrapper>
      );
    }

    if (playerSitOutTimer) {
      return (
        showSitOutTimer && (
          <JoinBackWrapper orientation={gameOrientation}>
            <JoinBackText>
              <SitOutTimer
                deadline={playerSitOutTimer}
                showTimer={showSitOutTimer}
                timerEndCallback={() => SitOutTimerEnd()}
              />
            </JoinBackText>
            <JoinBackButton
              id="join-back-button"
              onClick={() => !restrictSitInSitOutActions && handleSitIn()}
              disabled={restrictSitInSitOutActions}
            >
              {!sitOutButtonDisabled ? "I'm back" : <Loader size={15} />}
            </JoinBackButton>
          </JoinBackWrapper>
        )
      );
    }
    return null;
  };

  const handleSitIn = () => {
    if (!sitOutButtonDisabled) {
      dispatch(toggleSitOutAction());
    }
    return;
  };

  const optOutOfTourneyAutoPlay = () => {
    setTourneyAutoPlayOptOutLoader(true);
    switchOffTourneyAutoPlay({
      playerName: userName,
      tempTableId: tempTableId,
    });
    return;
  };

  const handleHandHistory = () => {
    //hitting protocol directly @yashwanth
    // if (buttonStatus) dispatch(openHandHistoryRequest());
    if (gameConnectionStatus === GAME_CONNECTION_PROTOCOL_SUCCESS) {
      dispatch(clearHandHistoryIndex());
      sendHandHistoryProtocol({
        tempTableId: tempTableId,
        playerName: userName,
      });
      dispatch(openSlider({ type: HAND_HISTORY }));
    }
  };

  const handleTableStatsClick = () => {
    //commented protocol sending from redux @yashwanth
    if (gameConnectionStatus === GAME_CONNECTION_PROTOCOL_SUCCESS) {
      sendTableStatsProtocol({
        tempTableId: tempTableId,
        playerName: userName,
      });
    }
  };

  const handleHappyHoursClick = () => {
    if (gameConnectionStatus === GAME_CONNECTION_PROTOCOL_SUCCESS) {
      dispatch(setHappyHoursInfoLoading({ loading: true }));
      sendHappyHoursTableInfoRequest({
        tempTableId: tempTableId,
        gameId: currentGameData.gameId,
        tableId: currentGameData.tableId,
        playerName: userName,
      });
      dispatch(openSlider({ type: HAPPY_HOURS }));
    }
  };

  const handleTopupClick = () => {
    // if (topUpPending) {
    //   return dispatch(
    //     setToastMessage({
    //       tempTableId: tempTableId,
    //       type: TOAST_INFO,
    //       message: "Your top up is pending",
    //     })
    //   );
    // }

    // Handle Insufficient Funds Case here.
    if (playerBalance.totalBalance < gameDefinition.minBuyInAmount) {
      dispatch(
        updateAmountToAdd({
          tempTableId: tempTableId,
          amountToAdd:
            gameDefinition.minBuyInAmount +
            gameDefinition.bigBlind -
            playerBalance.totalBalance,
          blindsText: `(${returnGameTypeText(gameDefinition.gameType)} ${
            gameDefinition.smallBlind
          }/${gameDefinition.bigBlind})`,
        })
      );
      return dispatch(
        openLocalGameModal({
          type: INSUFFICIENT_BALANCE,
          tempTableId: tempTableId,
        })
      );
    }

    if (atTableAmount < gameDefinition.maxbuyInAmount) {
      dispatch(
        updateTopupModalOpenStatus({ tempTableId: tempTableId, isOpen: true })
      );
    } else if (atTableAmount >= gameDefinition.maxbuyInAmount) {
      dispatch(
        setToastMessage({
          tempTableId: tempTableId,
          type: TOAST_INFO,
          message: "Your balance is greater than max buy-in",
        })
      );
    }
  };

  return (
    //RENDER METHOD
    <RelativeWrapper>
      {(!gameConnection &&
        gameConnectionStatus !== GAME_SOCKET_REMOVED &&
        gameConnectionStatus !== GAME_CONNECTION_PROTOCOL_SUCCESS &&
        gameConnectionStatus !== MATCH_MAKING_ACCOUNT_ISSUE &&
        gameConnectionStatus !== CONNECTION_REQUESTS_LIMIT_REACHED &&
        modalType !== DISCONNECTION) ||
      showMaintenancePopup === true
        ? createGameTableLoader()
        : null}
      <Container
        orientation={gameOrientation}
        id="gametable"
        bg={backgroundTheme}
        gameConnection={gameConnection}
        gameConnectionStatus={gameConnectionStatus}
        showGameTable={showGameTable}
      >
        <RelativeWrapper id="relative-wrapper">
          {!isTourneyGame && createPlayNextButtonOnTable()}
          {createGameTable()}
          {!playerSitOutTimer && !tourneyAutoPlay
            ? playerGameButtonsLogic()
            : null}
          {createRaiseAmountSlider()}
          {createToastMessages()}
          {createWaitListButtons()}
          {sitOutStatus || tourneyAutoPlay ? createImBackButton() : null}
          {
            <>
              <CommonButtons orientation={gameOrientation} id="common-buttons">
                <IconsColumn>
                {!isTourneyGame && !isFunGame ? (
                    <GameButtonWithTimer
                      onClick={() => handleHappyHoursClick()}
                      tempTableId={tempTableId}
                    />
                  ) : null}

                  {!isFunGame &&
                  !isTourneyGame &&
                  // !topUpPending &&
                  atTableAmount + gameDefinition.bigBlind <
                    gameDefinition.maxbuyInAmount &&
                  !topUpPending &&
                  mySeat !== undefined &&
                  mySeat !== null ? (
                    <TopupButton
                      orientation={gameOrientation}
                      onClick={() => handleTopupClick()}
                    >
                      <ButtonImage src={cashRefresh}></ButtonImage>
                    </TopupButton>
                  ) : null}

                  {/* hand history button should not be shown for watch players in the tournament game */}
                  {((isTourneyGame && !currentGameData.watchPlayer) ||
                    !isTourneyGame) && (
                    <HandHistoryButton
                      onClick={() => handleHandHistory()}
                      id="hand-history-button"
                    >
                      <ButtonImage
                        src={gameAssets.handHistoryIcon}
                      ></ButtonImage>
                    </HandHistoryButton>
                  )}
                </IconsColumn>
                <IconsColumn>
                  {!isFunGame && !isTourneyGame && (
                    <GameButton onClick={() => handleTableStatsClick()}>
                      <ButtonImage src={gameAssets.stats}></ButtonImage>
                    </GameButton>
                  )}
                </IconsColumn>
              </CommonButtons>
              <Leaderboard
                orientation={gameOrientation}
                gameDefinition={gameDefinition}
                isTourneyGame={isTourneyGame}
              />
            </>
          }
        </RelativeWrapper>
        {isTourneyGame && (
          <TourneyCommonButtons
            tempTableId={tempTableId}
            gameDefinition={gameDefinition}
          />
        )}
        {isTourneyGame && <TourneyGameBanners tempTableId={tempTableId} />}
        {/* <TourneyRankingLeaderboard /> */}
      </Container>
      {/* <Leaderboard orientation={gameOrientation} /> */}
      {(mySeat === null || mySeat === undefined) && gameConnection ? (
        <TakeSeatAlert />
      ) : null}
      <Modal id="game-modal" tempTableId={tempTableId} />
    </RelativeWrapper>
  );
};

export default memo(Game);
