import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { OMAHA5, TEXAS_HOLDEM } from '../../data/Constants';
import { closeSlider, updateSliderAnimatingFlag } from '../../redux/slices/sliderSlice';
import { GameInfo, GameInfoContainer, GameInfoHeader, GameInfoRow, GameInfoRowTitle, GameInfoRowValue } from './GameInfoStyles'
import info from "../../../assets/gameTable/info.svg";

const GameInfoSlider = ({ sliderDirection }) => {
    const gameDefinition = useSelector(
        (state) => state.gameTable.games[state.gameTable.activeGame].gameDefinition
    );
    const { happyHoursEnabled, happyHoursRake, happyHoursHeadsUpRake } = gameDefinition;
    const dispatch = useDispatch()
    const currentTimeStamp = useSelector((state) => state.lobby.currentTimeStamp);

    console.log('GAME DEF IS >>> ', gameDefinition);

    const gameInfoConfig = [
        {
            title: 'Type',
            value: gameDefinition.gameType === TEXAS_HOLDEM
                ? "Texas Hold'em"
                : gameDefinition.gameType === OMAHA5
                    ? "Omaha 5"
                    : "Omaha"
        },
        {
            title: 'Limit',
            value: gameDefinition.limitType === "NL"
                ? "No Limit"
                : gameDefinition.limitType === "FL"
                    ? "Fixed Limit"
                    : gameDefinition.limitType === "PL"
                        ? "Pot Limit"
                        : null
        },
        {
            title: 'Stakes',
            value: `${gameDefinition.smallBlind}/${gameDefinition.bigBlind}`
        },
        {
            title: 'Turn Time',
            value: gameDefinition.tableTime + 's'
        },
        {
            title: 'Extra Time',
            value: gameDefinition.extraTimeBank + 's'
        },
        {
            title: happyHoursEnabled ? 'Rake (HH) ' : 'Rake ',
            value: happyHoursEnabled ? happyHoursRake + '%' : gameDefinition.rake + '%'
        },
        {
            title: happyHoursEnabled ? 'Rake (HU)(HH) ' : 'Rake (HU) ',
            value: happyHoursEnabled ? happyHoursHeadsUpRake + '%' : gameDefinition.headsUpRake + '%'
        },
        {
            title: 'Cap (<=3)',
            value: gameDefinition.rakeCapForStartPlayersLETo3
        },
        {
            title: 'Cap (>3)',
            value: gameDefinition.rakeCapForStartPlayersGT3
        }
    ]

    return (
        <GameInfoContainer
            onAnimationEnd={() => {
                if (sliderDirection === "close") {
                    dispatch(closeSlider());
                }
                dispatch(updateSliderAnimatingFlag({ value: false }));
            }}
            sliderDirection={sliderDirection}
        >
            <GameInfoHeader>
                <img style={{ width: '18px' }} src={info}></img>
                Information
            </GameInfoHeader>
            <GameInfo>
                {
                    gameInfoConfig.map((item, index) => {
                        return <GameInfoRow lastItem={index === gameInfoConfig.length - 1}>
                            <GameInfoRowTitle>
                                {item.title} :
                            </GameInfoRowTitle>
                            <GameInfoRowValue>
                                {item.value}
                            </GameInfoRowValue>
                        </GameInfoRow>
                    })
                }
            </GameInfo>
        </GameInfoContainer>
    )
}

export default GameInfoSlider