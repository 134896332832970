import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo10GrayContoRegularText,
  Typo12GrayContoRegularText,
  Typo12SecondaryRedContoMediumText,
  Typo12WhiteTwoContoMediumText,
} from "../../../Common/styleGuide/Typos";
import { OMAHA, OMAHA5 } from "../../data/Constants";

export const Container = styled.div`
  width: 40%;
  height: 100%;
  min-width: 300px;
  max-width: 500px;
  max-height: var(--app-height);
  background: #042c36;
  position: absolute;
  left: 0;
  overflow: scroll;
  border-top-right-radius: 8px;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "left-right 0.25s forwards"
      : "right-left 0.25s forwards"};
`;

export const HandHistoryTopSection = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 2.5% 5%;
  position: relative;
  background: linear-gradient(to right, #044859, #075063);
  border-bottom: 1px solid ${colors.primaryGold};
`;

export const HandHistoryTitle = styled.span`
  color: #eabf69;
  font-size: 14px;
  font-weight: 550;
  font-family: "Conto";
  text-shadow: 0px 2px 2px black;
`;

export const HandHistoryIcon = styled.img`
  width: 20px;
  height: auto;
  margin-right: 10px;
`;

export const HandHistoryClose = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
`;

// Game info
export const GameInfoContainer = styled.div`
  background: #032028;
`;

export const GameInfoWrapper = styled.div`
  padding: 5% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

export const GameInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TitleLabel = styled(Typo12GrayContoRegularText)`
  line-height: 8px;
  color: white;
  font-weight: 550;
  font-size: 10px;
`;

export const DetailLabel = styled(Typo12WhiteTwoContoMediumText)`
  line-height: 14px;
  font-size: 9px;
  color: #91cfde;
  white-space: nowrap;
  text-align: center;
  position: relative;
  top: 2px;
`;

export const HappyHoursText = styled.div`
  line-height: 6px;
  font-size: 6px;
  color: #91cfde;
  white-space: nowrap;
  text-align: center;
  position: relative;
`;

export const GameInfoDivider = styled.div`
  width: 1px;
  /* height: 10px; */
  margin: 3% 4%;
  background-color: #bababa;
`;

// Round info
export const SelectRoundWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.isFunGame ? "flex-end" : "space-between"};
  align-items: center;
  width: 100%;
  bottom: 0;
  /* transform: translateY(-100%); */
  /* background: ${colors.deepBlue}; */
  background: #042c36;
  box-shadow: 0px 0px 5px black;
  height: 10%;
  min-height: 50px;
`;

export const SelectRoundCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  /* width: 100%; */
  margin: 0 10%;
`;

export const SelectLeftRoundButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${(props) => (props.handIndex === 0 ? "hidden" : "visible")};
  border: 1px solid #105a6d;
  background-color: #33607080;
  /* background-color: red; */
`;

export const SelectRightRoundButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${(props) =>
    props.handIndex === props.gamesLength ? "hidden" : "visible"};
  border: 1px solid #105a6d;
  background-color: #33607080;
  /* background-color: red; */
`;

export const ReportAnIssueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: flex-start;
  margin-left: 3%;
`;

export const ReportIssueText = styled(Typo12SecondaryRedContoMediumText)`
  text-decoration: underline;
  margin-bottom: 4px;
`;

export const LastHandsText = styled(Typo10GrayContoRegularText)``;

export const SelectLeftTriangle = styled.div`
  border: solid #d9d9d9;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: relative;
  left: 2px;
`;

export const SelectRightTriangle = styled.div`
  border: solid #d9d9d9;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: relative;
  right: 2px;
`;

export const SelectRoundText = styled.span`
  color: white;
  font-family: "Conto";
  margin-bottom: 4%;
  font-weight: bold;
  font-size: 12px;
`;

export const RoundInfoBox = styled.div`
  /* min-width: 40px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* transform: translateY(25%); */
`;

export const RoundInfoDetails = styled.div`
  /* background: ${colors.primaryGold}; */
  white-space: nowrap;
  border-radius: 2px;
  padding: 2% 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin: 0 9%; */
`;

export const RoundIncrDecrButton = styled.button`
  outline: 0;
  border: 1.5px solid ${colors.white};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  color: ${colors.white};
  background: transparent;
  font-size: 12px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// hand summary
export const HandSummaryWrapper = styled.div`
  padding: 0% 1.5% 10% 1.5%;
  position: relative;
  /* top: 18%; */
`;

export const HandSummaryMiddleSection = styled.div`
  height: ${(props) =>
    props.tableOrientation === "LANDSCAPE" ? "50%" : "75%"};
  overflow: scroll;
  position: relative;
  top: ${(props) => (props.tableOrientation === "LANDSCAPE" ? "30%" : props.happyHoursEnabled ? "15%" : "13%")};
  /* background-color: yellow; */
  padding-bottom: 2.5%;

  /* &::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.primaryGold};
    border-radius: 10px;
  } */
`;

export const LoaderContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CommunityCardsContainer = styled.div`
  width: 96%;
  padding: 1% 2% 3% 2%;
  height: auto;
  /* background-color: rgb(8, 13, 26); */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
`;

export const CommunityCardsTitle = styled.span`
  color: ${colors.primaryGold};
  font-family: "Conto";
  font-weight: 550;
  font-size: 12px;
  margin: 0.75rem 0;
`;

export const CommunityCardsImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* margin: 5px 0px; */
`;

export const CommunityCardsAndPotWrapper = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PotSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PotAmountWrapper = styled.div`
  background-color: #053e4d;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  font-size: 10px;
  color: white;
  min-width: 50px;
  border-radius: 25px;
  /* padding: 2px; */
`;

export const ChipIcon = styled.img`
  width: 15px;
  height: auto;
`;

export const PotAmountText = styled.span`
  font-size: 8px;
  color: #9c9d9f;
  font-weight: 550;
  margin-bottom: 5px;
`;

export const PotAmountInWrapper = styled.span`
  font-size: 10px;
  font-weight: bold;
  margin: 0px 5px 0px 10px;
`;

export const CommunityCardImage = styled.img`
  width: 20%;
  height: auto;
  max-width: 30px;
  margin-right: 3px;
  box-shadow: 0px 2px 2px black;
`;

export const CollapseCardPlayingCard = styled.img`
  width: 20px;
  height: auto;
  margin: ${(props) =>
    props.cardNumber === 3 || props.cardNumber === 4
      ? "0px 5px 0px 5px"
      : "0px 3px 0px 0px"};
  /* box-shadow: 0px 2px 2px black; */
`;

export const SummaryDetails = styled.div`
  padding: 4% 2% 0% 2%;
  width: 98%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const PlayerSummary = styled.div`
  width: 98%;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin-bottom: 12px;
`;

export const PlayerPosition = styled.div`
  width: 32px;
  /* height: 15px; */
  /* padding: 0.5% 1%; */
  font-size: 10px;
  border-radius: 25px;
  background-color: #075063;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px black;
  margin-right: 10px;
`;

export const PlayerNameBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  height: 100%;
`;

export const PlayerName = styled.span`
  font-size: 12px;
  align-items: flex-start;
  color: ${(props) => (props.userName ? colors.primaryGold : "white")};
  /* font-weight: ${(props) => (props.userName ? "bold" : null)}; */
  font-family: "Conto";
`;

export const PlayerCards = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* width: 20%; */
`;

export const PlayerCard = styled.img`
  height: ${(props) =>
    props.gameType === OMAHA || props.gameType === OMAHA5 ? "20px" : "auto"};
  width: ${(props) =>
    props.gameType === OMAHA || props.gameType === OMAHA5 ? "15px" : "20px"};
  margin-right: ${(props) =>
    props.gameType === OMAHA || props.gameType === OMAHA5 ? "2px" : "3px"};
  /* box-shadow: 0px 0px 0px black; */
`;

export const PlayerAmountsAndStrength = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const PlayerActionAndAmount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  /* background-color: yellow; */
`;

export const PlayerAmount = styled.span`
  color: ${(props) => (props.amount > 0 ? "#08D421" : "#F0745C")};
  font-family: "Conto";
  font-size: 12px;
  font-weight: bold;
`;

export const PlayerActionBox = styled.div`
  background: ${(props) =>
    props.actionType === "call"
      ? colors.callAction
      : props.actionType === "check"
      ? colors.checkAction
      : props.actionType === "fold"
      ? colors.foldAction
      : props.actionType === "raise"
      ? colors.raiseAction
      : props.actionType === "all-in"
      ? colors.allInAction
      : null};
  border-top: 1px solid
    ${(props) =>
      props.actionType === "call"
        ? colors.callBorder
        : props.actionType === "check"
        ? colors.checkBorder
        : props.actionType === "fold"
        ? colors.foldBorder
        : props.actionType === "raise"
        ? colors.raiseBorder
        : props.actionType === "all-in"
        ? colors.allInBorder
        : null};
  height: 25px;
  min-width: 50px;
  /* border: 0.5px solid ${colors.primaryGold}; */
  /* min-width: 40px; */
  /* min-width: 70px; */
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 1px 2px black;
`;

export const PlayerAction = styled.span`
  color: white;
  font-size: 8px;
  text-transform: capitalize;
  font-weight: bold;
`;

export const PlayerActionAmount = styled.div`
  color: #f0745c;
  font-family: "Conto";
  font-size: 10px;
  /* text-align: center; */
  display: flex;
  justify-content: flex-end;
  width: 50%;
  font-weight: bold;
`;

export const ActionIcon = styled.img`
  width: 12px;
  margin: 0px 5px;
  height: auto;
`;

export const PlayerHandStrength = styled.span`
  color: white;
  font-family: "Conto";
  font-size: 9px;
  text-align: right;
  /* font-weight: bold; */
`;

export const EmptyHandHistoryText = styled.span`
  color: white;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  /* background-color: red; */
`;

export const HandRankingNumberText = styled.span`
  color: ${colors.primaryGold};
  font-size: 14px;
  font-weight: 500;
  font-family: "Conto";
`;

export const PaginationContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  margin-right: 3%;
`;

export const HandHistoryFooterText = styled.div`
  margin-left: 3%;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
