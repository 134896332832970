import React, { useEffect, useState } from 'react'
import { HappyHoursBody, HappyHoursContainer, HappyHoursGraphic, HappyHoursHeader, HappyHoursInfo, HappyHoursInfoAmount, HappyHoursIcon, HappyHoursInfoRow, HappyHoursInfoText, HappyHoursPlayerSavingsContainer, HappyHoursRule, HappyHoursRulesBody, HappyHoursRulesContainer, HappyHoursSliderHeader, HappyHoursTimerContainer, HappyHoursTimerText, HappyHoursTimerValue, LifeTimeSavingsAmount, LifeTimeSavingsInfo, LifeTimeSavingsInfoText, HappyHoursLoadingContainer, TakeSeatContainer, TakeSeatText, TakeSeatButton, HappyHoursFailureText, HappyHoursFailureContainer } from './HappyHoursStyle';
import { closeSlider, updateSliderAnimatingFlag } from '../../redux/slices/sliderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderText } from '../TableStatistics/TableStatsStyle';
import happyHoursImage from '../../../assets/lobby/happyHoursGraphic.png'
import happyHoursInfoIcon from '../../../assets/lobby/happyHoursInfo.png'
import happyHoursRulesIcon from '../../../assets/lobby/happy-hours-rules.png'
import rule1Icon from '../../../assets/lobby/happyHourRule1.png'
import rule2Icon from '../../../assets/lobby/happyHourRule2.png'
import rule3Icon from '../../../assets/lobby/happyHourRule3.png'
import happyHoursGlass from '../../../assets/lobby/happyHoursGoldIcon.png'
import { doesExist, parseDecimalValuesOfNumber } from '../../../Common/utils/GameUtils';
import { Tooltip } from 'react-tooltip';
import { Loader } from '../Loader/Loader';
import { useTimer } from '../../../hooks/useTimer';
import { sendHappyHoursTableInfoRequest } from '../../utils/ProtocolUtils';
import Button from '../../../Common/components/Button/Button';
import { setHappyHoursInfoLoading } from '../../redux/slices/gameTableSlice';
import { GAME_CONNECTION_PROTOCOL_SUCCESS } from '../../data/Constants';

const HappyHoursSlider = ({ sliderDirection, closeSliderProp }) => {
    const dispatch = useDispatch();
    const activeGame = useSelector((state) => state.gameTable.activeGame);
    const happyHoursLoading = useSelector((state) => state.gameTable.happyHoursInfoLoading);
    const happyHoursInfoFailure = useSelector((state) => state.gameTable.happyHoursInfoFailure);
    const {
        playerTotalSavings,
        playerTableSavings,
        totalTableSavings
    } = useSelector((state) => state.gameTable.happyHoursInfo);
    const { gameDefId } = useSelector(
        (state) => state.gameTable.games[activeGame]?.gameDefinition
    );
    const gameDefinition = useSelector((state) => state.lobby.masterGameDefinitions[gameDefId])
    const currentTimeStamp = useSelector((state) => state.lobby.currentTimeStamp);
    const gameConnectionStatus = useSelector(
        (state) => state.gameTable.games[activeGame].gameConnectionStatus
      );
    const [timerState, setTimerState] = useState({
        start: null, // "start", "end", or null
        endTime: null,
    });
    const timerValue = useTimer(timerState.endTime); // Timer hook to calculate the time left

    const userName = useSelector((state) => state.lobby.player?.userName);
    const currentGameData = useSelector((state) => state.gameTable.games[activeGame]);

    useEffect(() => {
        if (currentGameData.gameId && !happyHoursLoading) {
            sendHappyHoursTableInfoRequest({
                tempTableId: activeGame,
                gameId: currentGameData.gameId,
                tableId: currentGameData.tableId,
                playerName: userName,
            });
        }
    }, [currentGameData.gameId])

    useEffect(() => {
        // console.log('Spruha log >> Game Definitoon is ', gameDefinition)
        if (!gameDefinition || !currentTimeStamp) return;

        if (currentTimeStamp < gameDefinition?.happyHoursStartTime) {
            setTimerState({
                start: true,
                endTime: gameDefinition?.happyHoursStartTime,
            });
        } else if (
            gameDefinition?.happyHoursStartTime <= currentTimeStamp &&
            currentTimeStamp < gameDefinition?.happyHoursEndTime
        ) {
            setTimerState({
                start: false,
                endTime: gameDefinition?.happyHoursEndTime,
            });
        } else {
            setTimerState({
                start: null,
                endTime: null,
            });
        }
    }, [gameDefinition, currentTimeStamp]);

    const handleTryAgain = () =>{
        if (gameConnectionStatus === GAME_CONNECTION_PROTOCOL_SUCCESS) {
            dispatch(setHappyHoursInfoLoading({ loading: true }));
            sendHappyHoursTableInfoRequest({
              tempTableId: activeGame,
              gameId: currentGameData.gameId,
              tableId: currentGameData.tableId,
              playerName: userName,
            });
          }
    }

    return (
        <HappyHoursContainer
            onAnimationEnd={() => {
                if (sliderDirection === "close") {
                    dispatch(closeSlider());
                }
                dispatch(updateSliderAnimatingFlag({ value: false }));
            }}
            sliderDirection={sliderDirection}
            id="HappyHoursContainer"
        >
            <HappyHoursSliderHeader>
                <HappyHoursIcon size={16} src={happyHoursGlass} />
                Happy Hour
            </HappyHoursSliderHeader>
            {
                happyHoursLoading
                    ?
                    <HappyHoursLoadingContainer>
                        <Loader size={30} />
                    </HappyHoursLoadingContainer>
                    :
                    happyHoursInfoFailure 
                    ?
                    <>
                        <HappyHoursFailureContainer>
                            <HappyHoursFailureText> 
                                    We couldn't fetch the data at the moment. Please check your connection or try again.
                            </HappyHoursFailureText>  
                            <Button
                                buttonText='Try again'
                                showIcon={false}
                                clickHandler={() => handleTryAgain()}
                            ></Button>
                        </HappyHoursFailureContainer>
                    </>
                    :
                    <>
                        <HappyHoursBody>
                            <HappyHoursGraphic src={happyHoursImage} />
                            <HappyHoursInfoRow>
                                <HappyHoursPlayerSavingsContainer>
                                    <HappyHoursInfo>
                                        <HappyHoursInfoText>
                                            Your Savings
                                        </HappyHoursInfoText>
                                        <HappyHoursIcon size={12} className='happy-hours-player-savings' src={happyHoursInfoIcon} />
                                        <Tooltip
                                            opacity={1}
                                            style={{
                                                height: "auto",
                                                //   overflow: 'clip',
                                                //   maxWidth: '275px',
                                                whiteSpace: "nowrap",
                                                backgroundColor: 'white',
                                                color: 'black',
                                                fontSize: '10px',
                                                fontWeight: 500,
                                            }}
                                            variant='info'
                                            openOnClick={true}
                                            clickable
                                            anchorSelect={`.happy-hours-player-savings`}
                                            place='top'
                                        >
                                            The rake you've saved in the current happy hour session
                                        </Tooltip>
                                        <HappyHoursInfoAmount>
                                            ₹{playerTableSavings}
                                        </HappyHoursInfoAmount>
                                    </HappyHoursInfo>
                                    <LifeTimeSavingsInfo>
                                        <LifeTimeSavingsInfoText lifetime={true}>
                                            Lifetime Savings
                                        </LifeTimeSavingsInfoText>
                                        <HappyHoursIcon size={12} className='happy-hours-lifetime-savings' src={happyHoursInfoIcon} />
                                        <Tooltip
                                            opacity={1}
                                            style={{
                                                height: "auto",
                                                //   overflow: 'clip',
                                                //   maxWidth: '275px',
                                                whiteSpace: "nowrap",
                                                backgroundColor: 'white',
                                                color: 'black',
                                                fontSize: '10px',
                                                fontWeight: 500,
                                            }}
                                            variant='info'
                                            openOnClick={true}
                                            clickable
                                            anchorSelect={`.happy-hours-lifetime-savings`}
                                            place='bottom'
                                        >
                                            Your lifetime happy hour savings
                                        </Tooltip>
                                        <LifeTimeSavingsAmount lifetime={true}>
                                            ₹{playerTotalSavings ? playerTotalSavings : 0}
                                        </LifeTimeSavingsAmount>
                                    </LifeTimeSavingsInfo>
                                </HappyHoursPlayerSavingsContainer>
                                <HappyHoursInfo>
                                    <HappyHoursInfoText>
                                        Table Savings
                                    </HappyHoursInfoText>
                                    <HappyHoursIcon size={12} className='happy-hours-table-savings' src={happyHoursInfoIcon} />
                                    <Tooltip
                                        opacity={1}
                                        style={{
                                            height: "auto",
                                            //   overflow: 'clip',
                                            //   maxWidth: '275px',
                                            whiteSpace: "nowrap",
                                            backgroundColor: 'white',
                                            color: 'black',
                                            fontSize: '10px',
                                            fontWeight: 500,
                                        }}
                                        variant='info'
                                        openOnClick={true}
                                        clickable
                                        anchorSelect={`.happy-hours-table-savings`}
                                        place='top'
                                    >
                                        The rake saved on this table in current happy hour session
                                    </Tooltip>
                                    <HappyHoursInfoAmount>
                                        ₹{totalTableSavings}
                                    </HappyHoursInfoAmount>
                                </HappyHoursInfo>
                            </HappyHoursInfoRow>
                            {
                                timerValue
                                    ?
                                    <HappyHoursTimerContainer>
                                        <HappyHoursTimerText>
                                            Happy Hour {timerState.start ? 'starts in' : 'ends in'}
                                        </HappyHoursTimerText>
                                        <HappyHoursTimerValue start={timerState.start}>
                                            {timerValue}
                                        </HappyHoursTimerValue>
                                    </HappyHoursTimerContainer>
                                    : null
                            }
                            {
                                !currentGameData?.happyHoursEnabled && currentGameData?.showHappyHoursButton
                                    ?
                                    <TakeSeatText>
                                        Happy Hour has ended
                                    </TakeSeatText>
                                    :
                                    (currentGameData?.happyHoursEnabled || gameDefinition?.happyHoursEnabled) && !doesExist(currentGameData.playerSeat)
                                        ?
                                        <TakeSeatContainer>
                                            <TakeSeatButton onClick={() => closeSliderProp()}>
                                                Join Now
                                            </TakeSeatButton>
                                            <TakeSeatText>
                                                Join happy hours by taking a seat on this table
                                            </TakeSeatText>
                                        </TakeSeatContainer>
                                        : null
                            }
                        </HappyHoursBody>
                        <HappyHoursRulesContainer>
                            <HappyHoursSliderHeader style={{ color: 'white' }}>
                                <HappyHoursIcon src={happyHoursRulesIcon} />
                                Rules
                            </HappyHoursSliderHeader>
                            <HappyHoursRulesBody>
                                <HappyHoursRule>
                                    <HappyHoursIcon src={rule1Icon} />
                                    Enjoy zero rake on cash tables during Happy Hours.
                                </HappyHoursRule>
                                <HappyHoursRule>
                                    <HappyHoursIcon src={rule2Icon} />
                                    Gameplay during Happy Hours will not count towards your Leaderboard and Rakeback Rewards progress.</HappyHoursRule>
                                <HappyHoursRule>
                                    <HappyHoursIcon src={rule3Icon} />
                                    Happy hours apply to select bet tables and specific times only</HappyHoursRule>
                            </HappyHoursRulesBody>
                        </HappyHoursRulesContainer>
                    </>
            }
        </HappyHoursContainer>
    )
}

export default HappyHoursSlider