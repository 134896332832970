import styled from "styled-components";
import colors from "../../styleGuide/Colors";
import { Typo12OffWhiteContoBoldText } from "../../styleGuide/Typos";

export const TabbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 28px;
  min-height: 28px;
  /* margin: 12px 12px 0px 12px; */
  /* gap: 6px; */
  margin-bottom: 6px;
  box-sizing: border-box;
  background: transparent;
`;

export const DefaultTabItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 28px;
  min-height: 28px;
  padding: 6px 12px;
  border-radius: 2px;
  min-width: 62px;
  background-color: ${(props) => (props.isActive ? "#336070" : "transparent")};
  border: ${(props) =>
    props.isActive ? "1px solid transparent" : `1px solid #fffdea40`};
  box-sizing: border-box;
  position: relative;
  margin-right: 6px;
  width: 100%;
  border-radius: 6px;
`;

export const DefaultTabItemText = styled(Typo12OffWhiteContoBoldText)`
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  /* text-transform: uppercase; */
  /* font-weight: ${(props) => (props.isActive ? "700" : "400")}; */
  color: white;
  margin-top: ${(props) => (props.isActive ? "-2px" : "0px")};
  white-space: nowrap;
`;
export const InfiniteTabbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
  height: 43px;
  min-height: 43px;
  .swiper {
    width: 100%;
  }
  .sub-tab-wrapper {
    background-color: rgb(3, 32, 40);
  }
`;

export const InfiniteScrollTabItemText = styled(Typo12OffWhiteContoBoldText)`
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  font-weight: ${(props) => (props.isActive ? "700" : "400")};
  /* color: ${(props) =>
    props.isActive ? colors.primaryBlue : colors.offWhite}; */
  margin-top: ${(props) => (props.isActive ? "3px" : "0px")};
  white-space: nowrap;
`;

export const InfiniteTabbarItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 85px;
  height: 43px;
  min-height: 43px;
  padding: 12px 20px;
  background: rgb(3, 32, 40);

  /* background-color: ${(props) =>
    props.isActive ? colors.primaryGold : "transparent"}; */
  border-bottom: ${(props) =>
    props.isActive
      ? `4px solid ${colors.primaryGold}`
      : `1px solid ${colors.tempNavy2}`};
  box-sizing: border-box;
`;

export const RedDotIcon = styled.span`
  display: flex;
  height: 8px;
  width: 8px;
  border-radius: 5px;
  background-color: ${colors.primaryRed};
  position: absolute;
  z-index: 5;
  top: -4px;
  right: -4px;
`;
