import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import happyHours from './happy-hours-home-lottie.json';

export const HomeHappyHoursLottie = ({ size, margin, loop, onClick }) => {
    const lottieAnimationRef = useRef(null);
    const addCashTimeout = useRef(null);

    useEffect(() => {
        addCashTimeout.current = setTimeout(() => {
            lottieAnimationRef?.current?.play();
        }, 100);

        return () => clearTimeout(addCashTimeout.current);
    }, []);

    return (
        <Lottie
            onClick={onClick}
            style={{
                width: size ? size + 'px' : "100%",
                height: size ? size + 'px' : "100%",
                margin: margin ? margin + 'px 0px' : 0,
            }}
            animationData={happyHours}
            ref={lottieAnimationRef.current}
            autoPlay={true}
            loop={loop ? loop : false}
        />
    );
};
