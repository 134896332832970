import GameConnectionsN from "../io/GameConnectionsN";
import LobbyConnection from "../io/LobbyConnection";
import { parseDecimalValuesOfNumber } from "../../Common/utils/GameUtils";
import { getGameSlice, getLobbySlice, getPlayerObject } from "./ReduxUtils";
import { sendFaroLog } from "../../Common/utils/FaroUtil";

export function sendLobbyDebugProtocol(payload) {
  const playerName = getLobbySlice()?.player?.userName;
  const logPayload = {
    playerName: playerName ? playerName : "",
    logMessage: payload,
  };
  let protocol = "DB#" + JSON.stringify(logPayload);
  LobbyConnection.getInstance().writeMessage(protocol);
}

export function sendGameDebugProtocol(payload) {
  const { tempTableId, debuglog } = payload;

  const { tableId, gameId, gameDefinition } = getGameSlice().games[tempTableId];
  payload = {
    ...payload,
    playerName: getPlayerObject().userName,
    tableId: tableId,
    gameId: gameId,
    tourneyId: gameDefinition.tourneyId,
  };

  let protocol = "DB#" + JSON.stringify(payload);
  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendCallProtocol(payload) {
  const { tempTableId, betAmount, playerName } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
    gameId: tableData.gameId,
    betAmount: parseDecimalValuesOfNumber(betAmount),
  };
  console.log("Payload in sendCallProtocol: ", payload);
  let protocol = "Call#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendAutoCallProtocol(payload) {
  const { tempTableId, betAmount, playerName, autoActionEnable } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
    gameId: tableData.gameId,
    betAmount: parseDecimalValuesOfNumber(betAmount),
    autoActionEnable: autoActionEnable,
  };
  console.log("Payload in sendAutoCallProtocol: ", payload);
  let protocol = "AutoCall#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendAutoCallAnyProtocol(payload) {
  const { tempTableId, betAmount, playerName, autoActionEnable } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
    gameId: tableData.gameId,
    betAmount: parseDecimalValuesOfNumber(betAmount),
    autoActionEnable: autoActionEnable,
  };
  console.log("Payload in sendAutoCallAnyProtocol: ", payload);
  let protocol = "AutoCallAny#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendCheckProtocol(payload) {
  const { tempTableId, playerName } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
    gameId: tableData.gameId,
  };
  console.log("Payload in sendCheckProtocol: ", payload);
  let protocol = "Check#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendAutoCheck(payload) {
  const { tempTableId, playerName, autoActionEnable } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
    gameId: tableData.gameId,
    autoActionEnable: autoActionEnable,
  };
  console.log("Payload in sendAutoCheck: ", payload);
  let protocol = "AutoCheck#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendFoldProtocol(payload) {
  const { tempTableId, playerName } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
    gameId: tableData.gameId,
  };
  console.log("Payload in sendFoldProtocol: ", payload);
  let protocol = "Fold#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendAutoFold(payload) {
  const { tempTableId, playerName, autoActionEnable } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
    gameId: tableData.gameId,
    autoActionEnable: autoActionEnable,
  };
  console.log("Payload in sendAutoFold: ", payload);
  let protocol = "AutoFold#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendAutoCheckFold(payload) {
  const { tempTableId, playerName, autoActionEnable } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
    gameId: tableData.gameId,
    autoActionEnable: autoActionEnable,
  };
  console.log("Payload in sendAutoCheckFold: ", payload);
  let protocol = "AutoCheckFold#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendRaiseProtocol(payload) {
  const { tempTableId, betAmount, playerName } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
    gameId: tableData.gameId,
    betAmount: parseDecimalValuesOfNumber(betAmount),
  };
  console.log("Payload in sendRaiseProtocol: ", payload);
  let protocol = "Raise#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendNextGameProtocol(payload) {
  const { tempTableId } = payload;
  let protocol = "NG#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendLeaveSeatProtocol(payload) {
  const { tempTableId } = payload;
  console.log("Payload in sendLeaveSeatProtocol: ", payload);
  let protocol = "leave#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendSitOutProtocol(tempTableId, payload) {
  console.log("Payload in sendSitOutProtocol: ", payload);
  let protocol = "SitOut#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendCancelSitOutProtocol(tempTableId, payload) {
  console.log("Payload in sendCancelSitOutProtocol: ", payload);
  let protocol = "SitOutCancel#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendSitInProtocol(tempTableId, payload) {
  console.log("Payload in sendSitInProtocol: ", payload);
  let protocol = "SitIn#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendWaitForBB(payload) {
  const { tempTableId, playerName, isWaitForBB } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
    gameId: tableData.gameId,
    isWaitForBB: isWaitForBB,
  };
  console.log("Payload in sendWaitForBB: ", payload);
  let protocol = "wfbb#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendHandHistoryProtocol(payload) {
  const { tempTableId, playerName, gameId } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
  };
  if (tableData.isTourneyGame) {
    payload["tourneyId"] = tableData.gameDefinition.tourneyId;
  }
  if (gameId) {
    //this is not available while opening hand history request
    payload.gameId = gameId; //this is hand history related game id
  }
  console.log("Payload in sendHandHistoryProtocol: ", payload);
  let protocol = "HandHistory#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  if (gameConnection) {
    gameConnection?.listener?.getCommunicationChannel()?.sendMessage(protocol);
  }
}

export function sendHappyHoursTableInfoRequest(payload) {
  const { tempTableId, playerName } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
  };

  console.log("Payload in sendHandHistoryProtocol: ", payload);
  let protocol = "happyHoursGameInfo#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  if (gameConnection) {
    gameConnection?.listener?.getCommunicationChannel()?.sendMessage(protocol);
  }
}

export function switchOffTourneyAutoPlay(payload) {
  const { tempTableId, playerName } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tourneyId: tableData.gameDefinition.tourneyId,
    tableId: tableData?.tableId,
  };

  let protocol = "tourneyautoplaysitin#" + JSON.stringify(payload);
  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  if (gameConnection) {
    gameConnection?.listener?.getCommunicationChannel()?.sendMessage(protocol);
  }
}

export function sendTableStatsProtocol(payload) {
  const { tempTableId, playerName } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    playerName: playerName,
    tableId: tableData.tableId,
  };

  console.log("Payload in sendTableStatsProtocol: ", payload);
  let protocol = "tablestats#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendRebuyInProtocol(payload) {
  const { tempTableId, value } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    tableId: tableData.tableId,
    gameDefId: tableData.gameDefinition.gameDefId,
    position: tableData.setBuyInValueForPlayerPosition,
    buyInAmount: value,
    userId: getPlayerObject().userName,
    playerName: getPlayerObject().userName,
    isUserOptForAutoRebuy: tableData.isOptForAutoReBuy,
  };

  console.log("Payload in sendRebuyInProtocol: ", payload);
  let protocol = "ReBuy#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendDRebuyInProtocol(payload) {
  const { tempTableId } = payload;
  let tableData = getGameSlice().games[tempTableId];

  payload = {
    tableId: tableData.tableId,
    gameDefId: tableData.gameDefinition.gameDefId,
    position: tableData.setBuyInValueForPlayerPosition,
    userId: getPlayerObject().userName,
    playerName: getPlayerObject().userName,
    isUserOptForAutoRebuy: tableData.isOptForAutoReBuy,
  };

  console.log("Payload in sendRebuyInProtocol: ", payload);
  let protocol = "DRebuy#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendTopUpProtocol(payload) {
  const { tempTableId, topUpAmount, playerName } = payload;
  payload = {
    topUpAmount: topUpAmount,
    playerName: playerName,
  };

  console.log("Payload in sendTopUpProtocol: ", payload);
  let protocol = "TopUp#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function sendJoinWaitlistProtocol(payload) {
  const { tempTableId } = payload;
  let protocol = "Wait#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export function removeFromWaitListProtocol(payload) {
  const { tempTableId } = payload;
  let protocol = "UnWait#" + JSON.stringify(payload);

  let gameConnection =
    GameConnectionsN.getInstance().getGameConnection(tempTableId);
  gameConnection.listener.getCommunicationChannel().sendMessage(protocol);
}

export const sendRefreshProtocol = (payload) => {
  const { tempTableId, tableId, gameId, playerName, tourneyId } = payload;

  let currentGameConnection =
    GameConnectionsN.getInstance()?.getGameConnection(tempTableId);
  currentGameConnection.listener.requestRefreshData({
    playerName: playerName,
    tempTableId: tempTableId,
    tableId: tableId,
    gameId: gameId,
    tourneyId: tourneyId,
  });
};

export function getMLTLogFormat(paramsObject) {
  /**
   * type - as identifier.
   * stage - could be the function name or a specific scenario.
   * logLocation - lobby or game or any specific component.
   * tempTableId - only for game table related.
   * comments - data or any miscellaneous information(s).
   * (not used as MLT is appending) playerName - logged in player name.
   * timeStamp - device time and date.
   */

  const { type, stage, logLocation, playerName, tempTableId, comments } =
    paramsObject;

  let date = new Date();
  let timeStamp =
    `time ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}` +
    ` date ${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;

  let mltObject = {
    type: type,
    stage: stage,
    logLocation: logLocation,
    tempTableId: tempTableId,
    comments: comments,
    // playerName: getPlayerObject().userName,
    timeStamp: timeStamp,
  };
  return mltObject;
}
