/* ALL the handtype should have equal number of taglines as we are using a random number between  0 to 4 and an index to get a random tagline */

export const Taglines = {
    "FULL HOUSE": [
      "Full house, full hearts, and a pot that's all yours!",
  
      "Three of a kind and a pair to spare—you’re on fire!",
  
      "Packed to the brim, your hand steals the win!",
  
      "A cozy full house—welcome to the winner’s circle!",
  
      "When three’s company and two’s a crowd, the pot is yours!",
    ],
    "FOUR OF A KIND": [
      "Four of a kind—rare and divine!",
  
      "All four in sync, and the chips are yours in a blink!",
  
      "Quad power, turning the game into your hour!",
  
      "The fantastic four just sealed the score!",
  
      "Four strong, and you can’t go wrong!",
    ],
    "STRAIGHT FLUSH": [
      "A flawless run—flush with victory!",
  
      "A smooth straight flush leaves no room for debate!",
  
      "Straight, flush, and fabulous—your win is luxurious!",
  
      "A seamless streak to the sweetest win!",
  
      "Gliding through the cards with a straight flush triumph!",
    ],
    "ROYAL FLUSH": [
      "Bow down to the royal flush, the unbeatable crown!",
  
      "When royalty calls, the table answers!",
  
      "A regal victory—long live the poker king/queen!",
  
      "Royal flush: a hand so rare, it commands the table!",
  
      "The highest of highs, your royal flush reigns supreme!",
    ],
  };
  