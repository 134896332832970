import { useSelector } from "react-redux";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import {
  Details,
  GameDetailsWrapper,
  TourneyNameWrapper,
  TourneyNameText,
  HappyHoursGlass,
} from "./gameDetailsStyles";
import happyHoursGlass from "../../../assets/lobby/happy-hours-glass.png";

export const GameDetails = ({ tempTableId, orientation }) => {
  const gameId = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameId
  );
  const tableId = useSelector(
    (state) => state.gameTable.games[tempTableId].tableId
  );
  const { gameDefId } = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition
  );
  const gameLevelHHEnabled = useSelector((state) => state.gameTable.games[tempTableId]?.happyHoursEnabled);
  const gameDefinition = useSelector((state) => state.lobby.masterGameDefinitions[gameDefId])
  const smallBlindValue = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition.smallBlind
  );
  const currentLobbyTime = useSelector((state) => state.lobby.currentLobbyTime);
  const bigBlindValue = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition.bigBlind
  );
  const currentTimeStamp = useSelector((state) => state.lobby.currentTimeStamp);

  const isTourneyGame = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isTourneyGame
  );

  const ante = useSelector(
    (state) => state.gameTable.games[tempTableId]?.anteAmount
  );

  const tourneyName = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition.tourneyName
  );

  const returnBlindDetails = () => {
    return smallBlindValue && bigBlindValue ? (
      <Details>
        {" ( "}
        {parseDecimalValuesOfNumber(
          smallBlindValue,
          2,
          checkToDisplayValueInK(smallBlindValue),
          checkToDisplayValueInL(smallBlindValue)
        )}
        {" / "}
        {parseDecimalValuesOfNumber(
          bigBlindValue,
          2,
          checkToDisplayValueInK(bigBlindValue),
          checkToDisplayValueInL(bigBlindValue)
        )}
        {" ) "}{" "}
        {isTourneyGame && ante
          ? " ( " +
            parseDecimalValuesOfNumber(
              ante,
              2,
              checkToDisplayValueInK(ante),
              checkToDisplayValueInL(ante)
            ) +
            " ) "
          : ""}
      </Details>
    ) : null;
  };

  return (
    gameId &&
    tableId &&
    smallBlindValue &&
    bigBlindValue && (
      <GameDetailsWrapper
        orientation={orientation}
        id={"game-details-" + tempTableId}
      >
        {gameDefinition?.happyHoursEnabled || gameLevelHHEnabled ? (
          <Details>
            Happy Hour
            <HappyHoursGlass src={happyHoursGlass}></HappyHoursGlass>
          </Details>
        ) : null}
        {isTourneyGame ? (
          <></>
        ) : gameDefinition.turbo ? (
          <Details>{`${gameDefinition.tableName} (Turbo)`}</Details>
        ) : (
          <></>
        )}
        {isTourneyGame && (
          <TourneyNameWrapper>
            <TourneyNameText>{tourneyName}</TourneyNameText>
          </TourneyNameWrapper>
        )}
        <Details>{gameId}</Details>
        {/* {process.env.REACT_APP_ENVIRONMENT !== "production" && ( */}
        <Details>{tableId}</Details>
        {/* )} */}
        {returnBlindDetails()}
        {/* {process.env.REACT_APP_ENVIRONMENT !== "production" && ( */}
        <Details>
          {currentLobbyTime?.getHours()}:{currentLobbyTime?.getMinutes()}:
          {currentLobbyTime?.getSeconds()}
        </Details>
        {/* )} */}
      </GameDetailsWrapper>
    )
  );
};
