import Handler from "../Handler";
class HappyHoursInfoFailureHandler extends Handler {
  execute(data) {
    let message = JSON.parse(data);
    console.log("Sneha log >>>In hours info failure handler");
    this.service.processHappyHoursInfoFailure(message);
  }
}

// happyhoursgameinfof --- {"playerName":"legend904353","tableId":"THR160125-1318429bfz1f"}
export default HappyHoursInfoFailureHandler;
