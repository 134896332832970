import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTimer } from "../../../hooks/useTimer";
import {
  ButtonImage,
  GameButton,
  HappyHoursTimerText,
  WinningAmountText,
} from "../../routes/Game/gameTableStyles";
import * as gameAssets from "../../routes/Game/gameAssets";

const GameButtonWithTimer = ({ tempTableId, onClick }) => {
  const { gameDefId } = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition
  );
  const gameDefinition = useSelector(
    (state) => state.lobby.masterGameDefinitions[gameDefId]
  );
  const gameLevelHHEnabled = useSelector(
    (state) => state.gameTable.games[tempTableId].happyHoursEnabled
  );
  const currentTimeStamp = useSelector((state) => state.lobby.currentTimeStamp);
  const happyHoursSavingForGame = useSelector(
    (state) => state.gameTable.games[tempTableId].happyHoursSavingForGame
  );
  const showHappyHoursButton = useSelector((state) => state.gameTable.games[tempTableId].showHappyHoursButton);
  const [timerState, setTimerState] = useState({
    start: null, // "start", "end", or null
    endTime: null,
  });
  const [showWinningAmount, setShowWinningAmount] = useState(false); // State for displaying winning amount
  const timerValue = useTimer(timerState.endTime); // Timer hook to calculate the time left
  const winningAmountRef = useRef(null)

  useEffect(() => {
    if (!gameDefinition || !currentTimeStamp) return;

    if (currentTimeStamp < gameDefinition.happyHoursStartTime) {
      setTimerState({
        start: true,
        endTime: gameDefinition.happyHoursStartTime,
      });
    } else if (
      gameDefinition.happyHoursStartTime <= currentTimeStamp &&
      currentTimeStamp < gameDefinition.happyHoursEndTime
    ) {
      setTimerState({
        start: false,
        endTime: gameDefinition.happyHoursEndTime,
      });
    } else {
      setTimerState({
        start: null,
        endTime: null,
      });
    }
  }, [gameDefinition, currentTimeStamp]);

  useEffect(() => {
    // Show the winning amount for a short duration before reverting to the timer
    if (happyHoursSavingForGame) {
      setShowWinningAmount(true);
      winningAmountRef.current = setTimeout(() => {
        setShowWinningAmount(false);
      }, 3000); // Show winning amount for 3 seconds
      return () => clearTimeout(winningAmountRef.current);
    }

    return () => clearTimeout(winningAmountRef.current);
  }, [happyHoursSavingForGame]);

  if (!timerValue && !gameLevelHHEnabled && !gameDefinition?.happyHoursTimerStarted && !showHappyHoursButton) return null; // Return null if there's no active timer or winning amount

  return (
    <GameButton start={timerValue ? timerState.start : null} onClick={onClick}>
      <ButtonImage src={gameAssets.happyHoursGoldGlass} />
      {showWinningAmount && happyHoursSavingForGame ? (
        <WinningAmountText>
          +₹{happyHoursSavingForGame}
        </WinningAmountText>
      ) : timerValue ? (
        <HappyHoursTimerText start={timerState.start}>
          {timerValue}
        </HappyHoursTimerText>
      ) : null}
    </GameButton>
  );
};

export default GameButtonWithTimer;