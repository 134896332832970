import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CursiveHandType, HandType } from "./winnerHandTypeStyles";
import { AnimatePresence, motion } from "framer-motion";
import colors from "../../../Common/styleGuide/Colors";
import { ACKNOWLEDGE_HIGH_STRENGTH_WIN, FOUR_OF_A_KIND, FULL_HOUSE, ROYAL_FLUSH, STRAIGHT_FLUSH, TEXAS_HOLDEM } from "../../data/Constants";
import { closeLocalGameModal, openLocalGameModal } from "../../redux/slices/gameTableSlice";

const WinnerHandType = (props) => {
  const { tempTableId } = props;

  const isWinnerDeclared = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isWinnerDeclared
  );

  const playerData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerData
  );

  const winningPotData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.winningPotDisplay
  );

  const doCommunityCardsExist = useSelector(
    (state) => state.gameTable.games[tempTableId]?.doCommunityCardsExist
  );

  const multiPotAmounts = useSelector(
    (state) => state.gameTable.games[tempTableId]?.multiPotAmounts
  );
  const playerSeat = useSelector((state) => state.gameTable.games[tempTableId].playerSeat);
  const currentGameType = useSelector((state) => state.gameTable.games[tempTableId]?.gameDefinition?.gameType);
  const masterGameDefinitions = useSelector((state) => state.lobby.masterGameDefinitions);
  const currentGameDef = useSelector((state) => state.gameTable.games[tempTableId]?.gameDefinition);
  const isTourneyGame = useSelector(
      (state) => state.gameTable.games[tempTableId]?.isTourneyGame
    );


  const [handType, setHandType] = useState(null);
  const [splitPot, setSplitPot] = useState(false);
  const [multiPots, setMultiPots] = useState(false);
  const intervalRef = useRef(null);

  const dispatch = useDispatch();

  const { 
    cashGameTagLineEnabled, 
    cashGameTagLineRanks, 
    cashGameTagLineGdid, 
    tourneyTagLineEnabled, 
    tourneyTagLineRanks
  } = useSelector(
    (state) => state.lobby.handRankingTagLineData
  );


  useEffect(() => {
    if (isWinnerDeclared && winningPotData) {
      getPlayerHandType();
    } else {
      setHandType(null);
      setSplitPot(false);
      setMultiPots(false);
      clearTimeout(intervalRef?.current);
    }
    return () => {
      clearTimeout(intervalRef?.current);
      // dispatch(closeLocalGameModal({tempTableId: tempTableId }))
    };
  }, [isWinnerDeclared, winningPotData]);

  const getPlayerHandType = () => {
    let playerName = winningPotData?.winnerNames[0];
    let playerInformation = playerData.find(
      (player, index) => player.userName === playerName
    );
    if (
      playerInformation &&
      playerInformation.isWinner &&
      playerInformation.handType
    ) {
      setHandType(playerInformation?.handType);
    }
    setSplitPot(winningPotData?.winnerNames?.length > 1);
    if (multiPotAmounts?.length > 1) {
      setMultiPots(true);
    } else {
      setMultiPots(false);
    }
  };

  useEffect(() => {
    let timeout;
    if(handType !== null){
    if (currentGameType === TEXAS_HOLDEM) {
      let isPlayerWinner =
        playerData.find(
          (player) => player.position === playerSeat && player.isWinner
        ) || null;

      let player = playerData.find(
        (player) => player.position === playerSeat
      ) || null;


      let validHandTypes=[];
      let isTagLineEnabled = false;

      if(isTourneyGame && tourneyTagLineEnabled){
        validHandTypes = tourneyTagLineRanks;
        isTagLineEnabled = tourneyTagLineEnabled;
      }
      else{
        validHandTypes = cashGameTagLineRanks;
        isTagLineEnabled = masterGameDefinitions[currentGameDef.gameDefId]?.tagLineEnabled;
      }
      
      if (validHandTypes.includes(handType) && isPlayerWinner !== null && isTagLineEnabled) {
        timeout = setTimeout(() => {
          dispatch(
            openLocalGameModal({
              type: ACKNOWLEDGE_HIGH_STRENGTH_WIN,
              tempTableId: tempTableId,
              config: {
                handType: handType,
              },
            })
          );
        }, 1000);
      }
    }}
    return(()=>{
      clearTimeout(timeout);
    })
  }, [handType]);


  const handTypeAtShowDown = () => {
    return (
      <AnimatePresence key={"handTypeAtShowdown-" + tempTableId}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isWinnerDeclared && handType && doCommunityCardsExist && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{
                opacity: 0,
                scale: 0,
              }}
              style={{
                width: "100%",
                zIndex: 4,
                textAlign: "center",
                borderImage: `linear-gradient(to right, rgba(0,0,0,0), ${colors.primaryGold}, rgba(0,0,0,0)) 30`,
                borderWidth: "1px",
                borderStyle: "solid",
                background:
                  "linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.6), rgba(0,0,0,0))",
              }}
            >
              <HandType textSize={18}>{handType}</HandType>
            </motion.div>
          )}
          <AnimatePresence>
            {multiPots ? (
              <CursiveHandType
                initial={{
                  opacity: 0,
                  scale: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                }}
                exit={{
                  opacity: 0,
                  scale: 0,
                }}
              >
                {winningPotData?.potIndex === 0 ? 'Main Pot' : 'Side Pot'}
              </CursiveHandType>
            ) : null}
          </AnimatePresence>
        </div>
      </AnimatePresence>
    );
  };

  return <>{handTypeAtShowDown()}</>;
};

export default memo(WinnerHandType);
