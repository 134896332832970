import React from "react";
import {
  ButtonIcon,
  ButtonStyles,
  StandByButtonStyles,
  StandbyIcon,
  Wrapper,
  CheckBoxIcon,
  UnCheckedIcon,
  Label,
} from "./GamePlayButtonStyles";

import CheckedIcon from "../../../../assets/gameTable/checked.png";
import allInIcon from "../../../../assets/player/all-in.svg";
import { useSelector } from "react-redux";

export const GamePlayButton = (props) => {
  const { label, clickHandler, disabled } = props;
  const tableOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  return (
    <Wrapper>
      <ButtonStyles
        // onTouchEndCapture={() => clickHandler()}
        onClick={() => {
          clickHandler();
        }}
        orientation={tableOrientation}
        backgroundColor={props.backgroundColor}
        topBorder={props.topBorder}
        disabled={disabled}
      >
        {label === "All In" ? (
          <ButtonIcon
            label={label}
            src={label === "All In" ? allInIcon : null}
          />
        ) : null}
        {label}
      </ButtonStyles>
    </Wrapper>
  );
};

export const StandByGamePlayButton = (props) => {
  const { label, active, clickHandler, disabled } = props;
  const tableOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  return (
    <Wrapper>
      <StandByButtonStyles
        active={active}
        orientation={tableOrientation}
        onTouchStart={() => {
          clickHandler();
        }}
        label={label}
        disabled={disabled}
      >
        {label === "Play next" ? (
          active ? (
            <CheckBoxIcon src={CheckedIcon} />
          ) : (
            <UnCheckedIcon></UnCheckedIcon>
          )
        ) : (
          <StandbyIcon active={active} label={label} />
        )}

        {label === "Play next" ? <Label label={label}>{label}</Label> : label}
      </StandByButtonStyles>
    </Wrapper>
  );
};
