import Handler from "./../Handler";

class HandRankingTagLineHandler extends Handler {

    //   protocol data

//   {
//     "cashGameTagLineEnabled": true,
//     "cashGameTagLineRanks": [
//         "Royal Flush",
//         "Straight Flush",
//         "Four of a kind",
//         "Full House"
//     ],
//     "cashGameTagLineGdid": [
//         530,
//         506,
//         411,
//         607,
//         453
//     ],
//     "tourneyTagLineEnabled": false,
//     "tourneyTagLineRanks": []
//    }
  execute(data) {
    let parsedData = JSON.parse(data);
    this.service.processHandrankingTagLineData(parsedData);
  }
}

export default HandRankingTagLineHandler;